import { DatiCorrezioneStatoItemRfid, ListaDeiCapiData } from '@/api'

export const mapDatiCorrezioneStatoRfid = (
  products: Array<ListaDeiCapiData>
): DatiCorrezioneStatoItemRfid => {
  const ok = products
    .filter((product) => product.attributes?.stato === 'N')
    .map((product) => product.attributes?.rfid || '')
  const ko = products
    .filter((product) => product.attributes?.stato === 'E')
    .map((product) => product.attributes?.rfid || '')

  return {
    data: {
      type: 'manage_cart_items',
      attributes: {
        ok,
        ko,
      },
    },
  }
}
