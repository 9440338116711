import { GetterTree } from 'vuex'
import store, { RootState } from '@/store'
import { State } from './_state'
import {
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  ConsumerCardDataAttributes,
  GetConsumerAffiliationsData,
  GetPoswebPromotionTypesAttributes,
} from '@/api'
import { StatusType, Roles } from '@/addons/enums'
import {
  ColorConfD,
  CustomStepsD,
  LevelRangeStep,
  PointsBalanceData,
} from './index.d'
import { ConfigGetters } from '@/store/configs-store'
import { isConfigTruthy } from '@/addons/functions/configs'
import { isInRange } from '@/modules/loyalty/helpers'

export enum GetterNames {
  GET_LOYALTY_CARDS = 'get-loyalty-cards',
  GET_ENABLED_CARD = 'get-enabled-card',
  GET_DISABLED_CARDS = 'get-disabled-cards',
  GET_PROMOTION_TYPE = 'get-promotion-type',
  GET_CUSTOM_STEPS = 'get-custom-steps',
  GET_CURRENT_LEVEL = 'get-current-level',
  IS_LOYALTY_ACTIVE = 'is-loyalty-active',
  IS_ERROR_LOYALTY = 'is-error-cards',
  GET_SELECTED_DISCOUNT = 'get-selected-discount',
  GET_AFFILIATE_PROGRAMS = 'get-affiliate-program',
  GET_POINTS_BALANCE = 'get-points-balance',
  IS_DISABLE_CARDS_ACTIVE = 'is-disable-cards-active',
  HAS_CUSTOM_STEPS_LEVEL = 'has-custom-step-level',
  GET_CURRENT_PROGRAM = 'get-current-program',
  IS_PROGRAM_ACTIVE = 'is-program-active',
  GET_ALL_PROMOTION_TYPE = 'get-all-promotion-type',
}

export type Getters = {
  [GetterNames.GET_LOYALTY_CARDS](state: State): State['cards']
  [GetterNames.GET_ENABLED_CARD](state: State): ConsumerCardDataAttributes
  [GetterNames.GET_DISABLED_CARDS](
    state: State
  ): Array<ConsumerCardDataAttributes>
  [GetterNames.GET_PROMOTION_TYPE](state: State): State['promotionType']
  [GetterNames.GET_SELECTED_DISCOUNT](state: State): State['selectedDiscount']
  [GetterNames.GET_CUSTOM_STEPS](state: State): State['customSteps']
  [GetterNames.GET_CURRENT_LEVEL](state: State): string
  [GetterNames.IS_LOYALTY_ACTIVE](state: State): boolean
  [GetterNames.IS_ERROR_LOYALTY](state: State): boolean
  [GetterNames.GET_AFFILIATE_PROGRAMS](state: State): State['affiliatePrograms']
  [GetterNames.GET_POINTS_BALANCE](state: State): State['pointsBalance']
  [GetterNames.IS_DISABLE_CARDS_ACTIVE](state: State): boolean
  [GetterNames.HAS_CUSTOM_STEPS_LEVEL](state: State): boolean
  [GetterNames.GET_CURRENT_PROGRAM](state: State): State['currentProgram']
  [GetterNames.IS_PROGRAM_ACTIVE](state: State): boolean
  [GetterNames.GET_ALL_PROMOTION_TYPE](
    state: State
  ): Array<State['promotionType']>
}

export const getters: GetterTree<State, RootState> = {
  [GetterNames.GET_LOYALTY_CARDS]: (
    state
  ): Array<ConsumerCardDataAttributes> => {
    return state.cards
  },
  // this getter returns an object contains the card enabled for the customer as keys.
  // it returns an empty object when there are no cards enabled for the customer
  // [GetterNames.GET_ENABLED_CARD]: (state): ConsumerCardDataAttributes => {
  //   return (
  //     state.cards.find(
  //       (c: ConsumerCardDataAttributes) => c.status === StatusType.ENABLED
  //     ) || {}
  //   )
  // },
  [GetterNames.GET_ENABLED_CARD]: (
    state,
    context
  ): ConsumerCardDataAttributes => {
    // Ottieni il tipo di promozione dal getter GET_PROMOTION_TYPE
    const promoType = context[GetterNames.GET_PROMOTION_TYPE] || {}

    // Cerca una card abilitata che appartenga alla Promo ATTIVA
    const enabledCard =
      state.cards.find((c: ConsumerCardDataAttributes) => {
        return (
          c.status === StatusType.ENABLED &&
          promoType.codice_promozione === c.chiave &&
          promoType.status === StatusType.ENABLED &&
          isInRange(promoType)
        )
      }) || {}
    // console.log('Enabled Card:', enabledCard)
    // console.log('promoType:', promoType)
    return enabledCard || {}
  },
  [GetterNames.GET_SELECTED_DISCOUNT]: (
    state
  ):
    | ApiV1SalesDataAttributesPagamenti
    | ApiV1SalesSaleIdDataAttributesPagamenti => {
    return state.selectedDiscount
  },
  [GetterNames.GET_DISABLED_CARDS]: (
    state
  ): Array<ConsumerCardDataAttributes> => {
    return (
      state.cards.filter(
        (c: ConsumerCardDataAttributes) => c.status === StatusType.DISABLED
      ) || {}
    )
  },
  [GetterNames.GET_PROMOTION_TYPE]: (
    state
  ): GetPoswebPromotionTypesAttributes => {
    return state.promotionType
  },
  [GetterNames.GET_CUSTOM_STEPS]: (state): CustomStepsD => {
    return state.customSteps || {}
  },
  [GetterNames.HAS_CUSTOM_STEPS_LEVEL]: (state, context): boolean => {
    return !!context[GetterNames.GET_CUSTOM_STEPS]?.levelRange
  },
  [GetterNames.GET_CURRENT_LEVEL]: (state, context): LevelRangeStep => {
    const currentValue = context[GetterNames.GET_ENABLED_CARD].current_value
    const storeSign = store.getters[ConfigGetters.GET_STORE_CONFIGS]?.STORE_SIGN
    const cs = state.customSteps || {}

    let currentStep = {}

    const colorConf: ColorConfD = {
      IN: ['black', 'brown', 'gray', 'gold', 'light-cyan'],
      PB: ['white', 'lighter-gray', 'yellow', 'orange', 'darker-brown'],
      MC: ['peach-mc', 'pink-mc', 'purple-mc', 'red-mc', 'dark-red-mc'],
    }
    if ('levelRange' in cs) {
      currentStep =
        cs.levelRange?.find((el: LevelRangeStep) => {
          if (
            (el?.threshold_min as number) <= currentValue &&
            (el?.threshold_max as number) >= currentValue
          ) {
            return true
          }
          if (!('threshold_max' in el)) {
            return (el?.threshold_min as number) <= currentValue
          }
          return false
        }) || {}
      const index = cs.levelRange?.indexOf(currentStep) as number
      const cIndex = index !== -1 ? index : 0
      if (currentValue === undefined) {
        currentStep = {}
      } else if (currentValue === 0) {
        currentStep = cs?.levelRange?.length ? cs.levelRange[0] : {}
      }
      if ('range' in currentStep) {
        currentStep = Object.assign({}, currentStep, {
          color: colorConf[storeSign][cIndex],
        })
      }
    }
    return currentStep
  },
  [GetterNames.IS_LOYALTY_ACTIVE]: (state): boolean => {
    const hasCardEnabled: boolean = isConfigTruthy('CARD_ENABLED')
    return Object.keys(state?.promotionType)?.length > 0 && hasCardEnabled
  },
  [GetterNames.IS_ERROR_LOYALTY]: (state): boolean => state.errorLoyalty,
  [GetterNames.GET_AFFILIATE_PROGRAMS]: (
    state
  ): Array<GetConsumerAffiliationsData> => state.affiliatePrograms,
  [GetterNames.GET_POINTS_BALANCE]: (state): PointsBalanceData =>
    state.pointsBalance,
  [GetterNames.IS_DISABLE_CARDS_ACTIVE]: (): ((payload: Roles) => boolean) => {
    const rolesAllowed = [Roles.ASSISTENZA, Roles.CASSIERA, Roles.GERENTE]
    return (payload: Roles) => {
      return rolesAllowed.includes(payload)
    }
  },
  [GetterNames.GET_CURRENT_PROGRAM]: (state): string => state.currentProgram,
  [GetterNames.IS_PROGRAM_ACTIVE]: (state): boolean =>
    isInRange(state.currentProgram),
  [GetterNames.GET_ALL_PROMOTION_TYPE]: (
    state
  ): Array<State['promotionType']> => state.allPromotionType,
}
