export enum MenuPartsE {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
  OPTIONAL = 'optional',
}
export interface SidebarMenuItemD {
  component: string
  props?: {
    class: string
    icon: string
    action: {
      [actionKey: string]: () => unknown
    }
  }
}

export interface SidebarMenuD {
  [MenuPartsE.TOP]: Array<SidebarMenuItemD>
  [MenuPartsE.CENTER]: Array<SidebarMenuItemD>
  [MenuPartsE.BOTTOM]: Array<SidebarMenuItemD>
  [MenuPartsE.OPTIONAL]: Array<SidebarMenuItemD>
}
