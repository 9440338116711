<script lang="ts" setup>
import FeIcon from '@/components/global/icon.vue'
import FeButton from '@/components/global/button.vue'
import { IonItem, IonLabel, IonInput } from '@ionic/vue'
import { defineEmits, defineProps, ref, withDefaults } from 'vue'
import I18n from '@/addons/i18n'
const emit = defineEmits(['close'])

const props = withDefaults(
  defineProps<{
    confirmPicking: any
  }>(),
  {
    confirmPicking: () => {},
  }
)
const value = ref<string>('')

function confirm() {
  if (value.value) {
    props.confirmPicking(value.value)
  }
}
</script>

<template>
  <div class="picking-modal">
    <div class="picking-modal__content --left">
      <fe-icon icon="alert-circle" />
    </div>
    <div class="picking-modal__content --right">
      <div>
        <p class="--text">
          {{ I18n.global.t('pos_common.picking_in_warehouse') }}
        </p>
      </div>
      <ion-item class="--input-container">
        <ion-label position="floating">{{
          I18n.global.t('pos_common.n_bracelet')
        }}</ion-label>
        <ion-input v-model="value" type="number" :clear-input="true" />
      </ion-item>
      <div class="picking-modal__footer">
        <fe-button color="border" @click="() => emit('close')">
          <span>{{ I18n.global.t('pos_common.cancel') }}</span>
          <template #icon-right>
            <fe-icon icon="cancel_black" />
          </template>
        </fe-button>
        <fe-button class="--dark" :disabled="!value" @click="confirm">
          <span>{{ I18n.global.t('pos_common.confirm') }}</span>
          <template #icon-right>
            <fe-icon icon="checkmark" />
          </template>
        </fe-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'modal-picking';
</style>
