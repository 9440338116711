import { ActionContext } from 'vuex'
import store, { RootState } from './index'
import { laybyApi } from '@/addons/axios'
import { NotificationsActions } from '@/store/notifications-store'
import { TYPE } from 'vue-toastification'
import i18n from '@/addons/i18n'
import { GetConsumerAttributes } from '@/api'
import LoaderController from '@/components/loader/LoaderController'
import { PageContexts } from '@/addons/enums'
import PrintErrorHandler, {
  ErrorDisplayTypes,
} from '@/addons/functions/printErrorHandler'
import { ConsumersActions } from '@/store/consumer'
import { SalesActions } from '@/store/sales/sales-store'
export interface LaybyState {
  isLoading: boolean
  laybyList: Array<LaybyElement>
  filters: LaybyFilter
  status: Array<StatusType>
}

export interface LaybyElement {
  type?: string
  id?: string
  attributes?: LaybyElementAttr
}

export interface LaybyElementAttr {
  data_documento: string
  ora_documento: string
  numero_documento: number | ''
  stato: string
  data_scadenza: string
  consumatrice: string
  contatti: string
  importo_pagato: number
  data_prossimo_pagamento: string
  importo_finale: number
  id_transazione: number
  cod_negozio: string
  numero_capi: number
  layby_scaduto: boolean
}

export interface LaybyFilter {
  data_da: string
  data_a: string
  numero_documento: string
  stato: 'OPEN' | 'FINALIZED' | 'VOIDED' | 'EXPIRED' | 'CANCELLED' | ''
  data_scadenza_da: string
  data_scadenza_a: string
  consumatrice: GetConsumerAttributes | undefined
  prossimo_pagamento_da: string
  prossimo_pagamento_a: string
  index?: string
}

export interface StatusType {
  id?: string
  value?: string
  label?: string
}

const InitialStatus: Array<StatusType> = [
  {
    value: 'OPEN',
    label: 'pos_sale.layby_status_open',
  },
  {
    value: 'FINALIZED',
    label: 'pos_sale.layby_status_finalized',
  },
  {
    value: 'VOIDED',
    label: 'pos_sale.layby_status_voided',
  },
  {
    value: 'EXPIRED',
    label: 'pos_sale.layby_status_expired',
  },
  {
    value: 'CANCELLED',
    label: 'pos_sale.layby_status_cancelled',
  },
]

export interface TabsAccordion {
  tabId: string
  label: string
  component: string
}

const InitialFilters: LaybyFilter = {
  data_da: '',
  data_a: '',
  numero_documento: '',
  stato: '',
  data_scadenza_da: '',
  data_scadenza_a: '',
  consumatrice: undefined,
  prossimo_pagamento_da: '',
  prossimo_pagamento_a: '',
}

enum Mutations {
  RESET_STATE = 'reset-state',
  RESET_FILTERS = 'reset-filters',
  SET_FILTER_VALUE = 'set-filter-value',
  SET_LAYBY_LIST = 'set-layby-list',
  IS_LOADED = 'is-loaded',
  IS_LOADING = 'is-loading',
}

enum Actions {
  RESET_STATE = 'reset-state',
  RESET_FILTERS = 'reset-filters',
  SET_FILTER_VALUE = 'set-filter-value',
  SEARCH_LAYBY = 'search_layby',
  IS_LOADING = 'is-loading',
}

enum Getters {
  GET_LAYBY_LIST = 'get-layby-list',
  GET_FILTERS = 'get-filters',
  GET_STATUS = 'get-status',
  IS_LOADING_DATA = 'is-loading-data',
}

const initState = (): LaybyState => ({
  isLoading: true,
  laybyList: [],
  filters: InitialFilters,
  status: InitialStatus,
})

const laybyStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: LaybyState): void => {
      Object.assign(state, initState())
    },
    [Mutations.RESET_FILTERS]: (state: LaybyState): void => {
      state.filters = initState().filters
    },
    [Mutations.SET_FILTER_VALUE]: (
      state: LaybyState,
      payload: { name: string; value: string | undefined }
    ): void => {
      state.filters = Object.assign({}, state.filters, {
        [payload.name]: payload.value,
      })
    },
    [Mutations.SET_LAYBY_LIST]: (
      state: LaybyState,
      payload: LaybyElement[]
    ): void => {
      state.laybyList = payload
    },
    [Mutations.IS_LOADING]: (state: LaybyState): void => {
      state.isLoading = true
    },
    [Mutations.IS_LOADED]: (state: LaybyState): void => {
      state.isLoading = false
    },
  },
  actions: {
    [Actions.RESET_STATE]: (
      context: ActionContext<LaybyState, RootState>
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.RESET_FILTERS]: async (
      context: ActionContext<LaybyState, RootState>
    ): Promise<void> => {
      context.commit(Mutations.RESET_FILTERS)
      await store.dispatch(ConsumersActions.RESET_STATE)
      await store.dispatch(SalesActions.RESET_STATE)
    },
    [Actions.SET_FILTER_VALUE]: (
      context: ActionContext<LaybyState, RootState>,
      payload: { name: string; value: string | undefined }
    ): void => {
      context.commit(Mutations.SET_FILTER_VALUE, payload)
    },
    [Actions.SEARCH_LAYBY]: async (
      context: ActionContext<LaybyState, RootState>
    ): Promise<void> => {
      try {
        LoaderController.show({
          section: PageContexts.FRONTOFFICE,
        })
        if (isNaN(Number(context.state.filters.numero_documento))) {
          store.dispatch(NotificationsActions.NOTIFY, {
            type: TYPE.ERROR,
            messageKey: i18n.global.t('pos_layby.type_number_document'),
          })
          return
        }
        context.commit(Mutations.IS_LOADING)
        const getCurrentConsumer: GetConsumerAttributes =
          context.rootState.consumers.currentConsumer
        const consumerLabel = Object.keys(getCurrentConsumer).length
          ? `${getCurrentConsumer.cognome} ${getCurrentConsumer.nome}`
          : ''
        const r = await laybyApi.apiV1LaybyGet(
          context.state.filters.data_da,
          context.state.filters.data_a,
          context.state.filters.numero_documento,
          context.state.filters.stato || undefined,
          context.state.filters.data_scadenza_da,
          context.state.filters.data_scadenza_a,
          consumerLabel,
          context.state.filters.prossimo_pagamento_da,
          context.state.filters.prossimo_pagamento_a
        )
        context.commit(Mutations.IS_LOADED)
        context.commit(Mutations.SET_LAYBY_LIST, (r.data as any)?.data)
      } catch (error: any) {
        await PrintErrorHandler(
          error?.response?.data,
          false,
          ErrorDisplayTypes.TOAST
        )
      } finally {
        LoaderController.hide()
      }
    },
    [Actions.IS_LOADING]: (
      context: ActionContext<LaybyState, RootState>
    ): void => {
      context.commit(Mutations.IS_LOADING)
    },
  },
  getters: {
    [Getters.GET_LAYBY_LIST]: (state: LaybyState) => state.laybyList,
    [Getters.GET_FILTERS]: (state: LaybyState) => state.filters,
    [Getters.GET_STATUS]: (state: any, getters: any, context: any) =>
      context.configs.currentStore.LabelsStatoLayby,
    [Getters.IS_LOADING_DATA]: (state: LaybyState) => state.isLoading,
  },
}

export default laybyStore

export const LaybyActions = {
  RESET_STATE: `layby/${Actions.RESET_STATE}`,
  RESET_FILTERS: `layby/${Actions.RESET_FILTERS}`,
  SET_FILTER_VALUE: `layby/${Actions.SET_FILTER_VALUE}`,
  SEARCH_LAYBY: `layby/${Actions.SEARCH_LAYBY}`,
  IS_LOADING: `layby/${Actions.IS_LOADING}`,
}

export const LaybyGetters = {
  GET_LAYBY_LIST: `layby/${Getters.GET_LAYBY_LIST}`,
  GET_FILTERS: `layby/${Getters.GET_FILTERS}`,
  GET_STATUS: `layby/${Getters.GET_STATUS}`,
  IS_LOADING_DATA: `layby/${Getters.IS_LOADING_DATA}`,
}
