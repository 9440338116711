import { MutationTree } from 'vuex'
import { initState, State } from './_state'
import { AbortControllerHelper } from '@/addons/axios'
import { RfidState } from '@/store/rfid/rfid'

export enum MutationNames {
  UPDATE_RFID_STATUS = 'rfid-update-rfid-status',
  RESET_STATE = 'rfid-reset-state',
  IS_SEARCHING = 'rfid-set-is-searching',
}

export type Mutations = {
  [MutationNames.UPDATE_RFID_STATUS](state: State, rfid: RfidState): void
  [MutationNames.RESET_STATE](state: State): void
  [MutationNames.IS_SEARCHING](state: State, payload: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationNames.UPDATE_RFID_STATUS]: (state: RfidState, rfid: RfidState) => {
    if (typeof rfid.enabled !== 'undefined') {
      // If we are activating the RFID antenna, make sure to instance an
      // AbortController so that we can cancel requestes if needed.
      if (rfid.enabled) {
        rfid.abortController = AbortControllerHelper.getCombinedControllers()
      } else {
        // Since we have to disable the antenna, cancel any pending polling request.
        state.abortController?.abort()
        state.abortController = undefined
      }
    }

    Object.assign(state, rfid)
  },
  [MutationNames.RESET_STATE]: (state: RfidState): void => {
    Object.assign(state, initState())
  },
  [MutationNames.IS_SEARCHING]: (state: RfidState, payload: boolean): void => {
    state.is_searching = payload
  },
}
