/* tslint:disable */
/* eslint-disable */
/**
 * OM Stock to Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';


/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get inventory seasons filters.
         * @summary stock OM - inventory/filters/seasons
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omApiV2InventoryFiltersSeasonsGet: async (contentType?: string, accept?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/om/api/v2/inventory/filters/seasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get inventory types of filters.
         * @summary stock OM - inventory/filters/types
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omApiV2InventoryFiltersTypesGet: async (contentType?: string, accept?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/om/api/v2/inventory/filters/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get stock datas
         * @summary stock OM - inventory
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {string} [filterSeason] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for season type)
         * @param {string} [filterLanguage] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for seasontype)
         * @param {string} [filterStoreCode] Store code
         * @param {string} [filterFilterType] Item\&#39;s SKU
         * @param {string} [filterFilterValue] Item\&#39;s SKU value for filter operation
         * @param {boolean} [filterOnlyPositive] Only positive stock
         * @param {string} [filterSortAttribute] Sort filter
         * @param {number} [filterPage] pagination
         * @param {number} [filterSize] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omApiV2InventoryGet: async (contentType?: string, accept?: string, filterSeason?: string, filterLanguage?: string, filterStoreCode?: string, filterFilterType?: string, filterFilterValue?: string, filterOnlyPositive?: boolean, filterSortAttribute?: string, filterPage?: number, filterSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/om/api/v2/inventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterSeason !== undefined) {
                localVarQueryParameter['filter[season]'] = filterSeason;
            }

            if (filterLanguage !== undefined) {
                localVarQueryParameter['filter[language]'] = filterLanguage;
            }

            if (filterStoreCode !== undefined) {
                localVarQueryParameter['filter[storeCode]'] = filterStoreCode;
            }

            if (filterFilterType !== undefined) {
                localVarQueryParameter['filter[filterType]'] = filterFilterType;
            }

            if (filterFilterValue !== undefined) {
                localVarQueryParameter['filter[filterValue]'] = filterFilterValue;
            }

            if (filterOnlyPositive !== undefined) {
                localVarQueryParameter['filter[onlyPositive]'] = filterOnlyPositive;
            }

            if (filterSortAttribute !== undefined) {
                localVarQueryParameter['filter[sortAttribute]'] = filterSortAttribute;
            }

            if (filterPage !== undefined) {
                localVarQueryParameter['filter[page]'] = filterPage;
            }

            if (filterSize !== undefined) {
                localVarQueryParameter['filter[size]'] = filterSize;
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * Get inventory seasons filters.
         * @summary stock OM - inventory/filters/seasons
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async omApiV2InventoryFiltersSeasonsGet(contentType?: string, accept?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.omApiV2InventoryFiltersSeasonsGet(contentType, accept, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get inventory types of filters.
         * @summary stock OM - inventory/filters/types
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async omApiV2InventoryFiltersTypesGet(contentType?: string, accept?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.omApiV2InventoryFiltersTypesGet(contentType, accept, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get stock datas
         * @summary stock OM - inventory
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {string} [filterSeason] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for season type)
         * @param {string} [filterLanguage] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for seasontype)
         * @param {string} [filterStoreCode] Store code
         * @param {string} [filterFilterType] Item\&#39;s SKU
         * @param {string} [filterFilterValue] Item\&#39;s SKU value for filter operation
         * @param {boolean} [filterOnlyPositive] Only positive stock
         * @param {string} [filterSortAttribute] Sort filter
         * @param {number} [filterPage] pagination
         * @param {number} [filterSize] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async omApiV2InventoryGet(contentType?: string, accept?: string, filterSeason?: string, filterLanguage?: string, filterStoreCode?: string, filterFilterType?: string, filterFilterValue?: string, filterOnlyPositive?: boolean, filterSortAttribute?: string, filterPage?: number, filterSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.omApiV2InventoryGet(contentType, accept, filterSeason, filterLanguage, filterStoreCode, filterFilterType, filterFilterValue, filterOnlyPositive, filterSortAttribute, filterPage, filterSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * Get inventory seasons filters.
         * @summary stock OM - inventory/filters/seasons
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omApiV2InventoryFiltersSeasonsGet(contentType?: string, accept?: string, options?: any): AxiosPromise<object> {
            return localVarFp.omApiV2InventoryFiltersSeasonsGet(contentType, accept, options).then((request) => request(axios, basePath));
        },
        /**
         * Get inventory types of filters.
         * @summary stock OM - inventory/filters/types
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omApiV2InventoryFiltersTypesGet(contentType?: string, accept?: string, options?: any): AxiosPromise<object> {
            return localVarFp.omApiV2InventoryFiltersTypesGet(contentType, accept, options).then((request) => request(axios, basePath));
        },
        /**
         * Get stock datas
         * @summary stock OM - inventory
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {string} [filterSeason] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for season type)
         * @param {string} [filterLanguage] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for seasontype)
         * @param {string} [filterStoreCode] Store code
         * @param {string} [filterFilterType] Item\&#39;s SKU
         * @param {string} [filterFilterValue] Item\&#39;s SKU value for filter operation
         * @param {boolean} [filterOnlyPositive] Only positive stock
         * @param {string} [filterSortAttribute] Sort filter
         * @param {number} [filterPage] pagination
         * @param {number} [filterSize] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omApiV2InventoryGet(contentType?: string, accept?: string, filterSeason?: string, filterLanguage?: string, filterStoreCode?: string, filterFilterType?: string, filterFilterValue?: string, filterOnlyPositive?: boolean, filterSortAttribute?: string, filterPage?: number, filterSize?: number, options?: any): AxiosPromise<object> {
            return localVarFp.omApiV2InventoryGet(contentType, accept, filterSeason, filterLanguage, filterStoreCode, filterFilterType, filterFilterValue, filterOnlyPositive, filterSortAttribute, filterPage, filterSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * Get inventory seasons filters.
     * @summary stock OM - inventory/filters/seasons
     * @param {string} [contentType] 
     * @param {string} [accept] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public omApiV2InventoryFiltersSeasonsGet(contentType?: string, accept?: string, options?: AxiosRequestConfig) {
        return GeneralApiFp(this.configuration).omApiV2InventoryFiltersSeasonsGet(contentType, accept, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get inventory types of filters.
     * @summary stock OM - inventory/filters/types
     * @param {string} [contentType] 
     * @param {string} [accept] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public omApiV2InventoryFiltersTypesGet(contentType?: string, accept?: string, options?: AxiosRequestConfig) {
        return GeneralApiFp(this.configuration).omApiV2InventoryFiltersTypesGet(contentType, accept, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get stock datas
     * @summary stock OM - inventory
     * @param {string} [contentType] 
     * @param {string} [accept] 
     * @param {string} [filterSeason] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for season type)
     * @param {string} [filterLanguage] Item\&#39;s season (\&quot;yys\&quot;, last two year\&#39;s digits and 1 or 2 for seasontype)
     * @param {string} [filterStoreCode] Store code
     * @param {string} [filterFilterType] Item\&#39;s SKU
     * @param {string} [filterFilterValue] Item\&#39;s SKU value for filter operation
     * @param {boolean} [filterOnlyPositive] Only positive stock
     * @param {string} [filterSortAttribute] Sort filter
     * @param {number} [filterPage] pagination
     * @param {number} [filterSize] page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public omApiV2InventoryGet(contentType?: string, accept?: string, filterSeason?: string, filterLanguage?: string, filterStoreCode?: string, filterFilterType?: string, filterFilterValue?: string, filterOnlyPositive?: boolean, filterSortAttribute?: string, filterPage?: number, filterSize?: number, options?: AxiosRequestConfig) {
        return GeneralApiFp(this.configuration).omApiV2InventoryGet(contentType, accept, filterSeason, filterLanguage, filterStoreCode, filterFilterType, filterFilterValue, filterOnlyPositive, filterSortAttribute, filterPage, filterSize, options).then((request) => request(this.axios, this.basePath));
    }
}


