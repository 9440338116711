import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import { GetSessionItemD } from './index.d'
import { SessionKeys } from '@/addons/enums'
export enum GetterNames {
  SHOW_ENDLESS_AISLE = 'show-endless-aisle',
  OPENED_DRAWER_OR_MODAL = 'opened-drawer-or-modal',
  OPENED_DRESSING_ROOM = 'opened-dressing-room',
  GET_DRESSING_ROOM_ELEMENTS = 'get-dressing-room-elements',
  GET_SESSION_ITEM = 'get-session-item',
  GET_STEP_PAGE = 'get-step-page',
  GET_SHOW_LOADER_FULL = 'get-show-loader-full',
  GET_FILTERS = 'get-filters',
  GET_CATEGORIES = 'get-categories',
  OPENED_FILTERS_MODAL = 'opened-filters-modal',
  GET_SELECTED_FILTERS = 'get-selected-filters',
  GET_OPENED_PRODUCT_INFO_MODAL = 'get-opened-product-info-modal',
  GET_SEARCHED_SKU_DATA = 'get_searched_sku_data',
  GET_ACTIVE_FILTERS_MODAL = 'get-active-filters-modal',
  GET_ARE_APPLIED_FILTERS = 'get-are-applied-filters',
  GET_DRESSING_ROOM_INFO = 'get-dressing-room-info',
  GET_IS_WISHLIST_ACTIVE = 'get-is-wishlist-active',
  GET_IS_IMAGE_SEARCH_ACTIVE = 'get-is-image-search-active',
  GET_ELEMENT_TO_SELL = 'get-element-to-sell',
  GET_CURRENT_PRODUCT = 'get-current-product',
  GET_PREV_PRODUCT = 'get-prev-product',
  GET_TEMP_PRODUCTS = 'get-temp-products',
  GET_LEDWALL_INFO = 'get-ledwall-info',
}

export type Getters = {
  [GetterNames.SHOW_ENDLESS_AISLE](state: State): State['showEndlessAisle']
  [GetterNames.OPENED_DRAWER_OR_MODAL](
    state: State
  ): State['openedDrawerOrModal']
  [GetterNames.OPENED_DRESSING_ROOM](state: State): State['openedDressingRoom']
  [GetterNames.GET_DRESSING_ROOM_ELEMENTS](
    state: State
  ): State['dressingRoomElements']
  [GetterNames.GET_SESSION_ITEM](state: State): Promise<GetSessionItemD>
  [GetterNames.GET_STEP_PAGE](state: State): State['stepPage']
  [GetterNames.GET_SHOW_LOADER_FULL](state: State): State['showLoaderFull']
  [GetterNames.GET_FILTERS](state: State): State['filters']
  [GetterNames.GET_CATEGORIES](state: State): State['categories']
  [GetterNames.OPENED_FILTERS_MODAL](state: State): State['openedFiltersModal']
  [GetterNames.GET_SELECTED_FILTERS](state: State): State['selectedFilters']
  [GetterNames.GET_OPENED_PRODUCT_INFO_MODAL](
    state: State
  ): State['openedProductInfoModal']
  [GetterNames.GET_SEARCHED_SKU_DATA](state: State): State['searchedSkuPLP']
  [GetterNames.GET_ACTIVE_FILTERS_MODAL](
    state: State
  ): State['activeFiltersModal']
  [GetterNames.GET_ARE_APPLIED_FILTERS](
    state: State
  ): State['areAppliedFilters']
  [GetterNames.GET_IS_WISHLIST_ACTIVE](state: State): State['isWishlistActive']
  [GetterNames.GET_IS_IMAGE_SEARCH_ACTIVE](
    state: State
  ): State['isImageSearchActive']
  [GetterNames.GET_ELEMENT_TO_SELL](state: State): State['elementToSell']
  [GetterNames.GET_CURRENT_PRODUCT](state: State): State['currentProduct']
  [GetterNames.GET_TEMP_PRODUCTS](state: State): State['prevProduct']
  [GetterNames.GET_LEDWALL_INFO](state: State): State['ledWallInfo']
}

export const getters: GetterTree<State, RootState> = {
  /**
   * @Getters
   * @description returns a boolean indicating whether endless-aisle is visible or not
   * @type boolean
   * @return showEndlessAisle
   * */
  [GetterNames.SHOW_ENDLESS_AISLE]: (state): State['showEndlessAisle'] =>
    state.showEndlessAisle,
  /**
   * @Getters
   * @description returns a boolean indicating a modal or drawer are open in endless aisle, UI use
   * @type boolean
   * @return openedDrawerOrModal
   * */
  [GetterNames.OPENED_DRAWER_OR_MODAL]: (state): State['openedDrawerOrModal'] =>
    state.openedDrawerOrModal,
  /**
   * @Getters
   * @description use to indicate if dressing room is opened
   * @type boolean
   * @return openedDressingRoom
   * */
  [GetterNames.OPENED_DRESSING_ROOM]: (state): State['openedDressingRoom'] =>
    state.openedDressingRoom,
  /**
   * @Getters
   * @description return element in dressing room
   * @type Array<GetModelsAttributes>
   * @return dressingRoomElements
   * */
  [GetterNames.GET_DRESSING_ROOM_ELEMENTS]: (
    state
  ): State['dressingRoomElements'] => state.dressingRoomElements,
  /**
   * @Getters
   * @description return session item saved by pk_consumer
   * @type Promise<GetSessionItemD>
   * @return session item
   * */
  [GetterNames.GET_SESSION_ITEM]: async (): Promise<GetSessionItemD> => {
    try {
      const dr = (await sessionStorage.getItem(
        SessionKeys.DRESSING_ROOM
      )) as string
      return dr ? JSON.parse(dr) : {}
    } catch {
      return {}
    }
  },
  /**
   * @Getters
   * @description return current page in endless aisle
   * @type StepPageE
   * @return stepPage
   * */
  [GetterNames.GET_STEP_PAGE]: (state): State['stepPage'] => state.stepPage,
  /**
   * @Getters
   * @description return boolean to indicate if the full loader is showed
   * @type boolean
   * @return showLoaderFull
   * */
  [GetterNames.GET_SHOW_LOADER_FULL]: (state): State['showLoaderFull'] =>
    state.showLoaderFull,
  /**
   * @Getters
   * @description return all filters from catalogApi.apiV1PoswebAdvancedFiltersGet filtered by store_sign
   * @type Array<GetAdvancedFiltersAttributes>
   * @return filters
   * */
  [GetterNames.GET_FILTERS]: (state): State['filters'] => state.filters,
  /**
   * @Getters
   * @description return all categories from catalogApi.apiV1PoswebCategoriesGet filtered by store_sign
   * @page StepPageE.CATEGORY_SEARCH | StepPageE.GUIDED_SEARCH
   * @type Array<GetBrandCategoriesAttributes>
   * @return categories
   * */
  [GetterNames.GET_CATEGORIES]: (state): State['categories'] =>
    state.categories,
  /**
   * @Getters
   * @description use to indicate if filter modal is opened
   * @page StepPageE.CATEGORY_SEARCH | StepPageE.GUIDED_SEARCH
   * @type boolean
   * @return openedFiltersModal
   * */
  [GetterNames.OPENED_FILTERS_MODAL]: (state): State['openedFiltersModal'] =>
    state.openedFiltersModal,
  /**
   * @Getters
   * @description return all selected filters by category and sub category in modal filters
   * @page StepPageE.CATEGORY_SEARCH
   * @type SelectedFiltersE
   * @return selectedFilters
   * */
  [GetterNames.GET_SELECTED_FILTERS]: (state): State['selectedFilters'] =>
    state.selectedFilters,
  /**
   * @Getters
   * @description use to indicate if product modal is opened
   * @type boolean
   * @return openedProductInfoModal
   * */
  [GetterNames.GET_OPENED_PRODUCT_INFO_MODAL]: (
    state
  ): State['openedProductInfoModal'] => state.openedProductInfoModal,
  /**
   * @Getters
   * @description return sku data from search
   * @type GetSkuDataAttributes
   * @return searchedSkuPLP
   * */
  [GetterNames.GET_SEARCHED_SKU_DATA]: (state): State['searchedSkuPLP'] =>
    state.searchedSkuPLP,
  /**
   * @Getters
   * @description return all active filters from modal filters
   * @page StepPageE.CATEGORY_SEARCH
   * @type ActiveFilterModalE
   * @return activeFiltersModal
   * */
  [GetterNames.GET_ACTIVE_FILTERS_MODAL]: (
    state
  ): State['activeFiltersModal'] => state.activeFiltersModal,
  /**
   * @Getters
   * @description use to indicate if filters are applied or not
   * @type boolean
   * @return areAppliedFilters
   * */
  [GetterNames.GET_ARE_APPLIED_FILTERS]: (state): State['areAppliedFilters'] =>
    state.areAppliedFilters,
  /**
   * @Getters
   * @description get dressing room info like quantity and skus list
   * @type DressingRoomInfoE
   * @return dressingRoomInfo
   * */
  [GetterNames.GET_DRESSING_ROOM_INFO]: (state): State['dressingRoomInfo'] =>
    state.dressingRoomInfo,
  /**
   * @Getters
   * @description get wishlist flag
   * @type isWishlistActive
   * @return isWishlistActive
   * */
  [GetterNames.GET_IS_WISHLIST_ACTIVE]: (state): State['isWishlistActive'] =>
    state.isWishlistActive,
  /**
   * @Getters
   * @description get image search flag
   * @type isWishlistActive
   * @return isWishlistActive
   * */
  [GetterNames.GET_IS_IMAGE_SEARCH_ACTIVE]: (
    state
  ): State['isImageSearchActive'] => state.isImageSearchActive,
  /**
   * @Getters
   * @description get element in sell page
   * @type elementToSell
   * @return elementToSell
   * */
  [GetterNames.GET_ELEMENT_TO_SELL]: (state): State['elementToSell'] =>
    state.elementToSell,
  /**
   * @Getters
   * @description get current product in modal
   * @type currentProduct
   * @return currentProduct
   * */
  [GetterNames.GET_CURRENT_PRODUCT]: (state): State['currentProduct'] =>
    state.currentProduct,
  /**
   * @Getters
   * @description get current prev product in modal
   * @type prevProduct
   * @return prevProduct
   * */
  [GetterNames.GET_PREV_PRODUCT]: (state): State['prevProduct'] =>
    state.prevProduct,
  /**
   * @Getters
   * @description get temporary products in filters
   * @type tempProducts
   * @return tempProducts
   * */
  [GetterNames.GET_TEMP_PRODUCTS]: (state): State['tempProducts'] =>
    state.tempProducts,
  [GetterNames.GET_LEDWALL_INFO]: (state): State['ledWallInfo'] =>
    state.ledWallInfo,
}
