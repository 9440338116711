/* eslint-disable no-console */
import type { AxiosError } from 'axios'
import store from '@/store'
import { ConfigGetters } from '@/store/configs-store'
export function logError(error: AxiosError) {
  // we should log error info to an error tracking application here (e.g Datadog, Sentry, others)

  if (!store.getters[ConfigGetters.IS_LIVE_ENVIRONMENT]) {
    console.log('error', error)
    console.log('response', error.response)
  }
}
