import { ActionTree } from 'vuex'
import store, { RootState } from '@/store'
import { State } from './_state'
import {
  GetPoswebPromotionTypesAttributes,
  DataSale,
  PatchSaleFidelity,
  SaleDataAttributes,
  ApiV1SalesFidelityPointsDataAttributes,
} from '@/api'
import { MutationsNames } from '@/modules/loyalty/store/_mutations'
import { GetterNames } from '@/modules/loyalty/store/_getters'
import { consumerDataApi, fidelityApi } from '@/addons/axios'
import {
  ApiV1SalesFidelityPointsPostDataD,
  CommonApiCardD,
  ErrorD,
  FidelityPointCapiD,
  FidelityPointsType,
  SaleDataAttributesFidelity,
} from './index.d'
import { extractErrorMessage } from '@/addons/functions'
import { NotificationsActions } from '@/store/notifications-store'
import LoaderController from '@/components/loader/LoaderController'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import { PageContexts, TipiMovimenti } from '@/addons/enums'
import i18n from '@/addons/i18n'
import { SalesGetters, SalesMutations } from '@/store/sales/sales-store'
import { PaymentsMutations } from '@/store/payments-store'
import { isConfigTruthy } from '@/addons/functions/configs'
import { isInRange } from '@/modules/loyalty/helpers'
export enum ActionsNames {
  RESET_STATE = 'reset-loyalty-store',
  SET_LOYALTY_CARDS = 'set-loyalty-cards',
  SET_LOYALTY_ERROR = 'set-loyalty-error',
  SET_PROMOTION_TYPE = 'set-promotion-type',
  ACTIVATE_CARD = 'activate-card',
  DISABLE_CARD = 'disable-card',
  FORCING_POINTS = 'forcing-points',
  SET_SELECTED_DISCOUNT = 'set-selected-discount',
  RESET_DISCOUNTS = 'reset-discounts',
  SET_AFFILIATE_PROGRAMS = 'set-affiliate-programs',
  RESET_AFFILIATE_PROGRAMS = 'reset-affiliate-programs',
  SET_CURRENT_SALE_FIDELITY_POINTS = 'set-current-sale-fidelity-points',
  UPDATE_CURRENT_SALE_FIDELITY_POINTS = 'update-current-sale-fidelity-points',
  RESET_CURRENT_SALE_FIDELITY_POINTS = 'reset-current-sale-fidelity-points',
  TRANSFER_POINTS = 'transfer-points',
  CONFIRM_PROMOTION_LOYALTY = 'confirm-promotion-loyalty',
  SET_CURRENT_PROGRAM = 'set-current-program',
}

export interface Actions {
  [ActionsNames.RESET_STATE](): void
  [ActionsNames.SET_LOYALTY_CARDS](payload: string): Promise<void>
  [ActionsNames.SET_PROMOTION_TYPE](): Promise<void>
  [ActionsNames.SET_SELECTED_DISCOUNT](): void
  [ActionsNames.RESET_DISCOUNTS](): void
  [ActionsNames.ACTIVATE_CARD](payload: CommonApiCardD): Promise<void>
  [ActionsNames.DISABLE_CARD](payload: CommonApiCardD): Promise<void>
  [ActionsNames.FORCING_POINTS](payload: CommonApiCardD): Promise<void>
  [ActionsNames.SET_AFFILIATE_PROGRAMS](payload: string): Promise<void>
  [ActionsNames.RESET_AFFILIATE_PROGRAMS](): void
  [ActionsNames.TRANSFER_POINTS](): Promise<void>
  [ActionsNames.SET_LOYALTY_ERROR](payload: boolean): Promise<void>
  [ActionsNames.SET_CURRENT_SALE_FIDELITY_POINTS](
    payload: DataSale
  ): Promise<ApiV1SalesFidelityPointsPostDataD | undefined>
  [ActionsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS](
    saleId: string,
    payload: {
      saleData: PatchSaleFidelity
      id_transazione: string | undefined
    }
  ): Promise<ApiV1SalesFidelityPointsPostDataD | undefined>
  [ActionsNames.RESET_CURRENT_SALE_FIDELITY_POINTS](): void
  [ActionsNames.CONFIRM_PROMOTION_LOYALTY](payload: boolean): Promise<void>
  [ActionsNames.SET_CURRENT_PROGRAM](payload: string): void
}

export const actions: ActionTree<State, RootState> = {
  async [ActionsNames.RESET_STATE](context) {
    context.commit(MutationsNames.RESET_STATE)
    if (isConfigTruthy('CARD_ENABLED')) {
      await context.dispatch(ActionsNames.SET_PROMOTION_TYPE)
    }
  },
  async [ActionsNames.SET_LOYALTY_CARDS](
    { commit, getters },
    payload: string
  ): Promise<void> {
    try {
      const data = await consumerDataApi.apiV1ConsumersPkConsumerCardsGet(
        payload
      )
      await commit(MutationsNames.SET_LOYALTY_CARDS, data.data?.data)
      const enabledCard = getters[GetterNames.GET_ENABLED_CARD]
      const fidelityPointsPayload = {
        transactionId: null,
        current: enabledCard.current_value,
        used: 0,
        earned: 0,
        new: 0,
      }
      commit(
        MutationsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE,
        fidelityPointsPayload
      )
    } catch (e) {
      commit(MutationsNames.SET_LOYALTY_CARDS, undefined)
    }
  },
  [ActionsNames.SET_SELECTED_DISCOUNT](
    context,
    payload: SaleDataAttributes
  ): void {
    context.commit(MutationsNames.SET_SELECTED_DISCOUNT, {
      ...context.state.selectedDiscount,
      ...payload,
    })
  },
  [ActionsNames.RESET_DISCOUNTS](context) {
    context.commit(MutationsNames.RESET_DISCOUNTS)
  },
  async [ActionsNames.SET_PROMOTION_TYPE]({ commit }): Promise<void> {
    try {
      const req = await fidelityApi.apiV1PoswebPromotionTypeGet()
      let attributes: GetPoswebPromotionTypesAttributes | undefined
      if (Array.isArray(req.data?.data)) {
        attributes = req.data?.data.find((data) =>
          isInRange(data.attributes)
        )?.attributes
        commit(
          MutationsNames.SET_ALL_PROMOTION_TYPE,
          req.data?.data.map((d) => d.attributes)
        )
      } else {
        attributes = req?.data?.data?.attributes
      }
      const customData = JSON.parse(attributes?.custom_data || '')
      const customDataObj = Object.assign({}, customData, {
        sconti_fidelity:
          !('sconti_fidelity' in customData) || !!customData.sconti_fidelity,
      })
      commit(MutationsNames.SET_PROMOTION_TYPE, attributes)
      commit(MutationsNames.SET_CURRENT_PROGRAM, attributes)
      commit(MutationsNames.SET_CUSTOM_STEPS, customDataObj)
    } catch (e) {
      commit(MutationsNames.SET_PROMOTION_TYPE, {})
      commit(MutationsNames.SET_CUSTOM_STEPS, {})
    }
  },
  async [ActionsNames.ACTIVATE_CARD](
    context,
    payload: CommonApiCardD
  ): Promise<void> {
    try {
      LoaderController.show({
        section: PageContexts.FRONTOFFICE,
      })
      const data = {
        data: {
          chiave: context.state.promotionType?.codice_promozione,
          valore: payload.code,
        },
      }
      await consumerDataApi.apiV1ConsumersPkConsumerCardsActivatePost(
        payload.pk_consumer,
        data
      )
      await store.dispatch(
        NotificationsActions.NOTIFY_SUCCESS,
        i18n.global.t('pos_loyalty.card_enable_ok')
      )
      await context.dispatch(
        ActionsNames.SET_LOYALTY_CARDS,
        payload.pk_consumer
      )
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error.response.data.errors[0].detail
      )
    } finally {
      LoaderController.hide()
    }
  },
  async [ActionsNames.DISABLE_CARD](
    context,
    payload: CommonApiCardD
  ): Promise<void> {
    try {
      LoaderController.show({
        section: PageContexts.FRONTOFFICE,
      })
      const data = {
        data: {
          chiave: context.state.promotionType?.codice_promozione,
          valore: payload.code,
        },
      }
      await consumerDataApi.apiV1ConsumersPkConsumerCardsDisablePost(
        payload.pk_consumer,
        data
      )
      await store.dispatch(
        NotificationsActions.NOTIFY_SUCCESS,
        i18n.global.t('pos_loyalty.card_disable_ok')
      )
      await context.dispatch(
        ActionsNames.SET_LOYALTY_CARDS,
        payload.pk_consumer
      )
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error.response.data.errors[0].detail
      )
    } finally {
      LoaderController.hide()
    }
  },
  async [ActionsNames.TRANSFER_POINTS](
    context,
    valore_from: string
  ): Promise<void> {
    try {
      const valore = context.getters[GetterNames.GET_ENABLED_CARD].valore
      const pkConsumer = context.rootGetters[SalesGetters.GET_CONSUMER_ID]
      const data = {
        data: {
          chiave: context.state.promotionType.codice_promozione,
          valore: valore,
          valore_from: valore_from,
        },
      }
      const r = await fidelityApi.apiV1ConsumersPkConsumerCardsMovePost(
        pkConsumer,
        data
      )
      context.commit(MutationsNames.SET_LOYALTY_CARDS, r.data?.data)
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error?.response.data.errors[0].detail
      )
    }
  },
  async [ActionsNames.FORCING_POINTS](
    context,
    payload: CommonApiCardD
  ): Promise<void> {
    try {
      LoaderController.show({
        section: PageContexts.FRONTOFFICE,
      })
      const data = {
        data: {
          chiave: payload.promotion_code,
          valore: payload.code,
          amount: payload.amount,
        },
      }
      await consumerDataApi.apiV1ConsumersPkConsumerCardsForcePost(
        payload.pk_consumer,
        data
      )
      await store.dispatch(
        NotificationsActions.NOTIFY_SUCCESS,
        i18n.global.t('pos_loyalty.card_points_update_ok')
      )
      await context.dispatch(
        ActionsNames.SET_LOYALTY_CARDS,
        payload.pk_consumer
      )
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error.response.data.errors[0].detail
      )
    } finally {
      LoaderController.hide()
    }
  },
  async [ActionsNames.SET_AFFILIATE_PROGRAMS](
    context,
    payload: string
  ): Promise<void> {
    try {
      const r = await fidelityApi.apiV1ConsumersPkConsumerCardsRemoteGet(
        payload
      )
      context.commit(MutationsNames.SET_AFFILIATE_PROGRAMS, r?.data?.data)
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error?.response.data.errors[0].detail
      )
    }
  },
  [ActionsNames.RESET_AFFILIATE_PROGRAMS](context) {
    context.commit(MutationsNames.RESET_AFFILIATE_PROGRAMS)
  },
  [ActionsNames.SET_LOYALTY_ERROR](context, payload: boolean) {
    context.commit(MutationsNames.UPDATE_LOYALTY_ERROR, payload)
  },
  [ActionsNames.SET_CURRENT_SALE_FIDELITY_POINTS]: async (
    context,
    payload: ApiV1SalesFidelityPointsDataAttributes
  ): Promise<ApiV1SalesFidelityPointsPostDataD | void> => {
    try {
      const FidelityPointsPostPayload = payload
      FidelityPointsPostPayload?.capi?.map((d) => {
        const c = d as FidelityPointCapiD
        if ('error_code' in c) {
          delete c?.error_code
        }
        if ('layby_delete' in c) {
          delete c?.layby_delete
        }
        return c
      })
      const enabledCard = context.getters[GetterNames.GET_ENABLED_CARD]
      if (Object.keys(enabledCard).length > 0) {
        const r = await fidelityApi.apiV1SalesFidelityPointsPost({
          data: {
            type: 'sale',
            attributes: FidelityPointsPostPayload,
          },
          preserve_decimals: true,
        })
        const apiV1SalesFidelityPointsPostData: ApiV1SalesFidelityPointsPostDataD =
          r.data?.data || []
        const fidelityPointsTransaction: string =
          apiV1SalesFidelityPointsPostData?.filter((r) => {
            return r.type === FidelityPointsType.SALES
          })[0].id || ''

        const fidelityPointsData: SaleDataAttributesFidelity =
          apiV1SalesFidelityPointsPostData?.filter((r) => {
            return r.type === FidelityPointsType.FIDELITY_POINTS
          })[0].attributes || {}
        const fidelityPointsPayload = {
          transactionId: fidelityPointsTransaction,
          current: enabledCard.current_value,
          used: fidelityPointsData.utilizzati,
          earned: fidelityPointsData.guadagnati,
          new: fidelityPointsData.nuovo_saldo,
        }
        context.commit(
          MutationsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE,
          fidelityPointsPayload
        )
        return apiV1SalesFidelityPointsPostData
      }
    } catch (err: unknown) {
      let errorMessage

      if (err?.response?.data?.errors.length) {
        const details = extractErrorMessage(
          err?.response?.data?.errors[0]?.detail
        )
        if (details) {
          errorMessage = details
        }
      } else {
        errorMessage = i18n.global.t('pos_loyalty.points_calc_error')
      }
      modalController.open({
        type: ModalTypes.ERROR,
        title: i18n.global.t('pos_common.attention'),
        message: errorMessage ?? '',
        confirmLabel: '',
        confirmActionButton: true,
        noCancelButton: true,
        buttonAlign: 'center',
        customButtons: {
          confirm: {
            label: 'ok',
            icons: 'checkmark',
          },
        },
        confirmAction: () => {
          LoaderController.hide()
          return Promise.resolve(undefined)
        },
      })
    }
  },

  async [ActionsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS](
    { commit, getters, state },
    payload: {
      saleData: ApiV1SalesFidelityPointsDataAttributes
      id_transazione: string | undefined
    }
  ): Promise<ApiV1SalesFidelityPointsPostDataD | undefined> {
    try {
      const FidelityPointsPostPayload = payload.saleData
      const transactionId =
        payload?.id_transazione || state.pointsBalance?.transactionId || ''
      if (FidelityPointsPostPayload.capi?.length > 0) {
        FidelityPointsPostPayload.capi?.map((d) => {
          const c = d as FidelityPointCapiD
          if ('error_code' in c) {
            delete c.error_code
          }
          c.id_transazione = Number(transactionId.split(',')[1])
          return d
        })
      }
      FidelityPointsPostPayload.id_transazione = Number(
        Number(transactionId.split(',')[1])
      )
      const r = await fidelityApi.apiV1SalesSaleIdFidelityPointsPatch(
        transactionId,
        {
          data: {
            id: transactionId,
            type: 'sale',
            attributes: FidelityPointsPostPayload,
          },
          preserve_decimals: true,
        }
      )
      const apiV1SalesFidelityPointsPatchData: ApiV1SalesFidelityPointsPostDataD =
        r.data?.data || []

      const fidelityPointsTransaction: string =
        apiV1SalesFidelityPointsPatchData.filter((r) => {
          return r.type === FidelityPointsType.SALES
        })[0].id || ''

      const fidelityPointsData: SaleDataAttributesFidelity =
        apiV1SalesFidelityPointsPatchData.filter((r) => {
          return r.type === FidelityPointsType.FIDELITY_POINTS
        })[0].attributes || {}

      const enabledCard = getters[GetterNames.GET_ENABLED_CARD]

      const fidelityPointsPayload = {
        transactionId: fidelityPointsTransaction,
        current: enabledCard.current_value,
        used: fidelityPointsData.utilizzati,
        earned: fidelityPointsData.guadagnati,
        new: fidelityPointsData.nuovo_saldo,
      }
      commit(
        MutationsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE,
        fidelityPointsPayload
      )
      return apiV1SalesFidelityPointsPatchData
    } catch (err: unknown) {
      let errorMessage = i18n.global.t('pos_loyalty.points_calc_error')

      if (err?.response?.data?.errors.length) {
        const details = extractErrorMessage(
          err?.response?.data?.errors[0]?.detail
        )
        if (details) {
          errorMessage += '<br>(Errore; ' + details + ')'
        }
      }
      modalController.open({
        type: ModalTypes.ERROR,
        title: i18n.global.t('pos_common.attention'),
        message: errorMessage,
        confirmLabel: '',
        confirmActionButton: true,
        noCancelButton: true,
        buttonAlign: 'center',
        customButtons: {
          confirm: {
            label: 'ok',
            icons: 'checkmark',
          },
        },
        confirmAction: () => {
          return Promise.resolve(undefined)
        },
      })
    }
  },
  [ActionsNames.RESET_CURRENT_SALE_FIDELITY_POINTS]({ commit }): void {
    const fidelityPointsPayload = {
      transactionId: null,
      current: null,
      used: null,
      earned: null,
      new: null,
    }
    commit(
      MutationsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE,
      fidelityPointsPayload
    )
  },
  [ActionsNames.CONFIRM_PROMOTION_LOYALTY]: (
    context,
    deletion = false
  ): void => {
    if (context.rootState.sales.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...context.rootState.payments.payments_validator,
      }

      const tmpPagamenti = context.rootState.sales.currentSale.pagamenti.filter(
        (p) => {
          if (p.codice_movimento === TipiMovimenti.CONTABILITA_PROMOZIONE) {
            if (
              tmpPayments_validator[String(p.progressivo)] === null ||
              deletion
            ) {
              delete tmpPayments_validator[String(p.progressivo)]
              return false
            }

            tmpPayments_validator[String(p.progressivo)] = true
          }
          return true
        }
      )
      store.commit(SalesMutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator
      )
    }
  },
  [ActionsNames.SET_CURRENT_PROGRAM]: (context, program): void => {
    context.commit(MutationsNames.SET_CURRENT_PROGRAM, program)
  },
}
