import i18n from '@/addons/i18n'
import { TYPE, useToast } from 'vue-toastification'
import { ActionContext } from 'vuex'
import { RootState } from '.'
import modalController, { ModalTypes } from '@/components/modal/ModalController'

const toast = useToast()

function getLabel(textOrLabelId: string) {
  const matches = /\w+\.\w+/.test(textOrLabelId)
  if (matches) {
    return i18n.global.t(textOrLabelId)
  }

  return textOrLabelId
}

export interface NotificationsState {
  notifications: string[]
  wrongSignAlertOpen: boolean
}

export enum NotificationsActions {
  NOTIFY = 'notify',
  NOTIFY_ERROR = 'notify_error',
  NOTIFY_SUCCESS = 'notify_success',
  NOTIFY_INFO = 'notify_info',
  NOTIFY_WARN = 'notify_warn',
  NOTIFY_WRONG_SIGN = 'notify_wrong_sign',
}

export enum NotificationsMutations {
  STORE_WRONG_SIGN_ALERT_OPEN = 'notifications-store-wrong-sign-alert-open',
}

const notificationStore = {
  namespaced: false,
  state: {
    notifications: [],
    wrongSignAlertOpen: false,
  },
  mutations: {
    [NotificationsMutations.STORE_WRONG_SIGN_ALERT_OPEN]: (
      state: NotificationsState,
      flag: boolean
    ): void => {
      state.wrongSignAlertOpen = flag
    },
  },
  actions: {
    [NotificationsActions.NOTIFY]: (
      _context: ActionContext<NotificationsState, RootState>,
      payload: { messageKey: string; type: TYPE; timeout: number }
    ) => {
      toast(getLabel(payload.messageKey), {
        hideProgressBar: true,
        toastClassName: 'fe-notification',
        icon: '',
        type: payload.type,
        timeout: payload.timeout,
      })
    },
    [NotificationsActions.NOTIFY_ERROR]: (
      context: ActionContext<NotificationsState, RootState>,
      messageKey: string
    ) => {
      context.dispatch(NotificationsActions.NOTIFY, {
        type: TYPE.ERROR,
        messageKey,
      })
    },
    [NotificationsActions.NOTIFY_SUCCESS]: (
      context: ActionContext<NotificationsState, RootState>,
      messageKey: string
    ) => {
      context.dispatch(NotificationsActions.NOTIFY, {
        type: TYPE.SUCCESS,
        messageKey,
      })
    },
    [NotificationsActions.NOTIFY_INFO]: (
      context: ActionContext<NotificationsState, RootState>,
      messageKey: string
    ) => {
      context.dispatch(NotificationsActions.NOTIFY, {
        type: TYPE.INFO,
        messageKey,
      })
    },
    [NotificationsActions.NOTIFY_WARN]: (
      context: ActionContext<NotificationsState, RootState>,
      messageKey: string
    ) => {
      context.dispatch(NotificationsActions.NOTIFY, {
        type: TYPE.WARNING,
        messageKey,
      })
    },
    [NotificationsActions.NOTIFY_WRONG_SIGN]: (
      context: ActionContext<NotificationsState, RootState>,
      payload: {
        title: string
        message: string
      }
    ) => {
      if (!context.state.wrongSignAlertOpen) {
        context.commit(NotificationsMutations.STORE_WRONG_SIGN_ALERT_OPEN, true)
        modalController.open({
          type: ModalTypes.INFO,
          component: '',
          title: payload?.title ?? i18n.global.t('pos_common.attention'),
          message:
            payload?.message ?? i18n.global.t('pos_sale.same_sign_payment'),
          confirmActionButton: false,
          closedAction: () =>
            context.commit(
              NotificationsMutations.STORE_WRONG_SIGN_ALERT_OPEN,
              false
            ),
        })
      }
    },
  },
}

export default notificationStore
