import modalController, { ModalTypes } from '@/components/modal/ModalController'
import i18n from '@/addons/i18n'

interface configD {
  closedAction?: () => void
  confirmAction?: () => void
  confirmButton?: {
    label: string
    icons: string
  }
}
export function openModalTailoringAlert(config: configD) {
  modalController.open({
    type: ModalTypes.WARNING,
    component: '',
    closeIcon: false,
    title: i18n.global.t('pos_common.attention'),
    message: i18n.global.t(
      'pos_common.sale_textile_only_with_extra_alterations_message'
    ),
    confirmActionButton: true,
    buttonAlign: 'center',
    textAlign: 'center',
    customButtons: {
      close: {
        icons: 'delete',
        label: i18n.global.t('pos_sale.remove_all_extra_alterations'),
        color: 'warn',
      },
      confirm: config.confirmButton,
    },
    closedAction: config.closedAction,
    confirmAction: config.confirmAction,
  })
}
