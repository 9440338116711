import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import { RfidState } from '@/store/rfid/rfid'

export enum GetterNames {
  IS_ENABLED = 'get-rfid-is-enabled',
  POPOVER_IS_VISIBLE = 'get-popover-is-visible',
  IS_SEARCHING = 'get-is-searching',
}

export type Getters = {
  [GetterNames.IS_ENABLED](state: State): State['enabled']
  [GetterNames.POPOVER_IS_VISIBLE](state: State): State['popoverIsVisible']
  [GetterNames.IS_SEARCHING](state: State): State['is_searching']
}
export const getters: GetterTree<State, RootState> = {
  [GetterNames.IS_ENABLED]: (state: RfidState): boolean => {
    return state.enabled
  },
  [GetterNames.POPOVER_IS_VISIBLE]: (state: RfidState): boolean => {
    return state.popoverIsVisible
  },
  [GetterNames.IS_SEARCHING]: (state: RfidState): boolean => state.is_searching,
}
