<script lang="ts">
import { getBaseURL } from '@/addons/functions'
import { IonIcon } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeIcon',

  components: { IonIcon },

  props: {
    icon: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
  },

  computed: {
    iconPath(): string {
      return `${getBaseURL()}/assets/icons/${this.icon}.svg`
    },
  },
})
</script>

<template>
  <span class="fe-icon">
    <ion-icon :icon="iconPath" :class="className" />
  </span>
</template>

<style lang="scss" scoped>
.fe-icon {
  min-width: 1rem;
  min-height: 1rem;
}
</style>
