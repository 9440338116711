import {
  GetConsumerAttributes,
  GetConsumerAttributesUltimeModifiche,
} from '@/api'
import { AnonymousConsumer } from '@/interfaces/anonymous-consumer'
import { selectedConsumerStatus, Statistics } from './index.d'

export interface State {
  consumers: GetConsumerAttributes[]
  anonymousConsumer: AnonymousConsumer[]
  abortController: AbortController | undefined
  lastChangeConsumer: GetConsumerAttributesUltimeModifiche[]
  currentConsumer: GetConsumerAttributes
  consumerStatistics: Statistics | undefined
  selectedConsumerStatus: selectedConsumerStatus | undefined
  isJustCreated: boolean
}
export const initState = (): State => ({
  consumers: [],
  anonymousConsumer: [],
  abortController: undefined,
  lastChangeConsumer: [],
  currentConsumer: {},
  consumerStatistics: undefined,
  selectedConsumerStatus: undefined,
  isJustCreated: false,
})
