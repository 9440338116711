import { Capacitor } from '@capacitor/core'
import { KeyboardInfo } from '@capacitor/keyboard'
import { getPlatforms } from '@ionic/vue'

export type DeviceType = 'pc' | 'tablet' | 'phone'
export type DeviceTypeMediaQueryNames =
  | 'queryDesktop'
  | 'queryIpadProLandscape'
  | 'queryTabletLandscape'
  | 'queryTabletPortrait'
  | 'queryWideDesktop'

export default function checkDevice(): DeviceType {
  const platforms = getPlatforms()
  if (platforms.includes('tablet')) {
    return 'tablet'
  }
  if (
    platforms.includes('mobile') ||
    platforms.includes('mobileweb') ||
    platforms.includes('phablet')
  ) {
    return 'phone'
  }
  return 'pc'
}

/**
 * Returns whether the current device is an iOS device, be it a:
 *  - emulated device (using devtools from a modern browser, emulating user agent)
 *  - simulated device (using XCode built-in simulator)
 *  - an actual device (such as a physical iPad)
 */
export function isNativeOrWebIos() {
  return getPlatforms().includes('ios')
}

/**
 * Returns whether the current device is a running this app as a native application.
 */
export function isNativeMobileApp() {
  return Capacitor.isNativePlatform()
}

/**
 * Returns `true` if current device is running this app as a native
 * application and it's on an iOS device.
 */
export function isNativeIos() {
  return isNativeMobileApp() && isNativeOrWebIos()
}

/**
 * Returns true if current device has a touch screen monitor.
 * This could be a smartphone, a tablet or even a laptop.
 */
export function hasTouchScreen() {
  return navigator?.maxTouchPoints > 0
}

/**
 * An helper class to handle window resizing when virtual keyboard is shown
 */
export abstract class KeyboardHelper {
  /**
   * Any virtual keyboard related machinery will ignore keyboard events
   * when the virtual keyboard is smaller than this value (in pixels).
   * For example, this won't center focussed element if keyboard is 70px in height.
   */
  private static KEYBOARD_SIZE_THRESHOLD = 100

  private static intersectionObserver?: IntersectionObserver

  private static get appRoot() {
    return document.querySelector(`[data-app-root]`) as HTMLElement
  }

  private static get appContent() {
    return document.querySelector(`[data-app-content]`) as HTMLElement
  }

  private static get appHeader() {
    return document.querySelector('[data-app-header]') as HTMLElement
  }

  /**
   * Invoked when a virtual keyboard has been shown.
   * If the keyboard has a sufficient height, this method will resize the window and,
   * if the active input element is inside a drawer, this will center said input field.
   * @param info Keyboard activation event
   */
  static keyboardDidShow(info: KeyboardInfo) {
    if (info.keyboardHeight < this.KEYBOARD_SIZE_THRESHOLD) {
      return
    }

    this.appRoot.style.height = `${window.innerHeight - info.keyboardHeight}px`

    const activeElement = document.activeElement as HTMLElement | null
    const drawer = document.querySelector('.drawer-scrollable')

    // If, for some reason, we do not have an active element or we do not have a drawer,
    // we can safely return since we don't have to center the input field.
    if (!activeElement || !drawer) {
      return
    }

    // If we reached this point we create an IntersectionObserver
    // to check whether the active element is contained in the drawer.
    // This will help us with the vertical centering of the active element.
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        const intersecting = entries.find((e) => e.isIntersecting)

        if (intersecting) {
          // Since we got an active item that is intersecting with the drawer,
          // we scroll the drawer so that the relevant part is visible.
          activeElement.scrollTo({
            top: Math.round(intersecting?.boundingClientRect.top || 0),
            behavior: 'instant',
          })

          // Then we can vertically center the active element, so that it's visible in current viewport.
          activeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          })

          // finally we can disconnect this observer since we no longer need it.
          this.intersectionObserver?.disconnect()
          this.intersectionObserver = undefined
        }
      },
      { root: drawer }
    )

    // Start observing on current active element.
    this.intersectionObserver.observe(activeElement)
  }

  static keyboardDidHide() {
    // If we had an active element and it was an input element, trigger the blur event
    // so that form validation and such can happen as usual.
    if (document.activeElement instanceof HTMLInputElement) {
      document.activeElement?.blur()
    }

    const headerHeight = this.appHeader.getBoundingClientRect().height || 0

    // Resize some elements to their initial states.
    this.appRoot.style.height = `${window.innerHeight}px`
    this.appContent.style.height = `${window.innerHeight - headerHeight}px`

    // If we had an intersection observer, we can disconnect it.
    this.intersectionObserver?.disconnect()
  }
}
