import { GetItemsFabricsAttributes } from '@/api'
import {
  ComposizioneItem,
  LocalComposizioneItem,
} from '@/interfaces/sales-model'

export default function getComposizioneString(
  composizione: { [key: string]: Array<{ [key: string]: string }> },
  fabrics: GetItemsFabricsAttributes[]
) {
  return Object.keys(composizione)
    .reduce((final: string, key: string) => {
      return `${final} ${groupComposizione(key)}`
    }, '')
    .trim()

  function groupComposizione(key: string): string {
    return composizione[key].map((c) => getComposizione(c, key)).join('\n')
  }

  function getComposizione(c: { [key: string]: string }, key: string): string {
    const composition = Object.keys(c)
      .reduce(
        (cf, ck: string) =>
          `${cf} ${parseInt(c[ck], 10)}% ${findComposizione(ck)},`,
        ''
      )
      .replace(/,$/, '')

    return `${key}: ${composition}`
  }

  function findComposizione(key: string): string {
    return (
      fabrics?.find((f: GetItemsFabricsAttributes) => f?.cod_tessuto === key)
        ?.descrizione ?? ''
    )
  }
}

export function mapComposizione(
  localComp: LocalComposizioneItem[]
): ComposizioneItem {
  const compT: ComposizioneItem = {}
  localComp.forEach((t) => {
    if (t.key && t.value) {
      t.value = t.value?.match(/\d+/g)?.toString()
      compT[t.key] = t.value
    }
  })
  return compT
}
