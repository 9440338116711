<script lang="ts">
import { defineComponent } from 'vue'
import { ConfigGetters } from '@/store/configs-store'
import { Routes } from '@/router'
import { unformatMMFGDate } from '@/addons/date'
import { mapGetters } from 'vuex'
export default defineComponent({
  name: 'FeCustomDateIcon',
  components: {},
  props: {},
  data() {
    return {
      Routes,
    }
  },
  computed: {
    ...mapGetters({
      getCustomDate: ConfigGetters.GET_CUSTOM_DATE,
    }),
    getDate() {
      const unformatDate = unformatMMFGDate(this.getCustomDate)
      return {
        day: unformatDate.getDate(),
        month: unformatDate.getMonth(),
      }
    },
  },
  methods: {},
})
</script>

<template>
  <router-link
    v-if="getCustomDate"
    :to="Routes.TOOLS"
    class="cd-custom-date-icon__container"
  >
    <span class="cd-custom-date-icon __day">{{ getDate.day }}</span>
    <span class="cd-custom-date-icon __month">
      {{ $t(`pos_common.month${getDate.month}`).substring(0, 3) }}
    </span>
  </router-link>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

.cd-custom-date-icon {
  color: $color-white;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  &.__day {
    font-size: 1.6rem;
  }
  &.__month {
    font-size: 1.1rem;
  }
  &__container {
    text-decoration: none;
    width: 100%;
    max-width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 0.8rem;
    background-color: $color-custom-date;
    margin-top: 2rem;
  }
}
</style>
