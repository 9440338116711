import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import { GetCashiersAttributes } from '@/api'

export enum GetterNames {
  LIST = 'cashiersList',
}
export type Getters = {
  [GetterNames.LIST](state: State): State['cashiers']
}
export const getters: GetterTree<State, RootState> = {
  [GetterNames.LIST]: (state): GetCashiersAttributes[] => {
    return state.cashiers
  },
}
