import {
  GetCashesAttributes,
  GetCashesData,
  GetStoreConfigDataAttributes,
} from '@/api'
import store from '@/store'
import { AuthGetters } from '@/store/auth'
import { ConfigGetters } from '@/store/configs-store'
import { QueryStringFlags } from '../enums'

/**
 * Returns true if the requested config can be considered true.
 * So, for example, if the config is `"1"` or `1`, we consider it true.
 * If the config has not been found, we will consider it as disabled.
 * @param configName Name of the requested config
 */
export function isConfigTruthy(
  configName: keyof GetStoreConfigDataAttributes
): boolean {
  const config = store.getters[
    ConfigGetters.GET_STORE_CONFIGS
  ] as GetStoreConfigDataAttributes
  const rawValue = config?.[configName] || '0'
  const value = typeof rawValue === 'string' ? parseInt(rawValue, 10) : rawValue
  return value !== 0
}

/**
 * Returns true if the requested cash config can be considered true.
 * So, for example, if the config is `"1"` or `1`, we consider it true.
 * If the config has not been found, we will consider it as disabled.
 * @param configName Name of the requested cash config
 */
export function isCashConfigTruthy(
  configName: keyof GetCashesAttributes
): boolean {
  const currentCash = store.getters[
    AuthGetters.GET_CURRENT_CASH
  ] as GetCashesData

  const rawValue = currentCash?.attributes?.[configName] || '0'
  const value = typeof rawValue === 'string' ? parseInt(rawValue, 10) : rawValue

  return value !== 0
}
/**
 * Returns true if RFID enabled for current cash.
 * @param configName Name of the requested cash config
 */
export function isRFIDenabledForCash(): boolean {
  const currentCash = store.getters[
    AuthGetters.GET_CURRENT_CASH
  ] as GetCashesData

  const rawValue = currentCash?.attributes?.['CHECKOUT_RFID_ENABLED'] || '0'
  const value = typeof rawValue === 'string' ? parseInt(rawValue, 10) : rawValue

  return value !== 0
}

/**
 * Returns true if the requested query string flag can be considered true.
 * So, for example, if the config is `"1"` or `1`, we consider it true.
 * @param flagName Name of the query string flag
 */
export function isQueryStringFlagTruthy(flagName: QueryStringFlags): boolean {
  const params = new URLSearchParams(window.location.search)
  const value = params.get(flagName)

  return value === '1' || value === 'true'
}
