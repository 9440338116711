import { ApiV1SalesSaleIdDataAttributesCapi, SaleDataAttributes } from '@/api'

export interface LevelRangeStep {
  threshold_min?: number
  threshold_max?: number
  range?: string
  color?: string
}

export interface CustomStepsD {
  steps?: {
    [threshold: string]: number
  }
  custom_data?: object
  levelRange?: Array<LevelRangeStep>
  sconti_fidelity?: boolean
}

export interface CommonApiCardD {
  pk_consumer: string
  code: string
  promotion_code: string
  amount?: number
}
export interface PointsBalanceData {
  transactionId?: string | null
  current?: number
  used?: number
  earned?: number
  new?: number
}

export interface ColorConfD {
  [k: string]: Array<string>
}

export interface ErrorD {
  response: {
    data: ModelError
  }
}
export interface FidelityPointCapiD extends ApiV1SalesSaleIdDataAttributesCapi {
  error_code?: string
}

export interface SaleDataAttributesFidelity extends SaleDataAttributes {
  nuovo_saldo?: number
  guadagnati?: number
  utilizzati?: number
}

export type ApiV1SalesFidelityPointsPostDataD = Array<{
  attributes?: SaleDataAttributesFidelity
  id?: string
  type?: string
}>

export enum FidelityPointsType {
  SALES = 'sales',
  FIDELITY_POINTS = 'fidelity_points',
}

export interface UpdateFidelityPointsD {
  saleId: string
  saleData: {
    data: {
      id: string
      type: string
      attributes: SaleDataAttributes
    }
    preserve_decimals: boolean
  }
}
