import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import { RootState } from '..'
import { TypedMapActions, TypedMapMutations, TypedStateStore } from '../_types'
import { ActionNames, Actions, actions } from './_actions'
import { GetterNames, Getters, getters } from './_getters'
import { MutationNames, Mutations, mutations } from './_mutations'
import { State, initState } from './_state'

const CashierState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'cashiers'

export type CashierStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const CashiersActions = {
  RESET_STATE: `${NAMESPACE}/${ActionNames.RESET_STATE}`,
  FETCH_CASHIERS: `${NAMESPACE}/${ActionNames.FETCH_CASHIERS}`,
} as const

export const CashiersGetters = {
  LIST: `${NAMESPACE}/${GetterNames.LIST}`,
} as const

export const CashiersMutations = {
  RESET_STATE: `${NAMESPACE}/${MutationNames.RESET_STATE}`,
  STORE_CASHIERS: `${NAMESPACE}/${MutationNames.STORE_CASHIERS}`,
} as const

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_actions'
export * from './_getters'
export * from './_mutations'
export type { State as CashierState } from './_state'
export default CashierState
