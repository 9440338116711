import { isCancel, rfidApi } from '@/addons/axios'
import { ActionContext, ActionTree } from 'vuex'
import { TypedActionContext } from '../_types'
import { RootState } from '@/store'
import { MutationNames, Mutations } from './_mutations'
import { State } from './_state'
import { RfidState } from '@/store/rfid/rfid'
import { mockRFIDApi } from '@/store/rfid/_mock'
import { NotificationsActions } from '@/store/notifications-store'
import { mapDatiCorrezioneStatoRfid } from './_utils'
import { ErrorD } from '@/addons/interface'
import { ListaDeiCapiData } from '@/api'
export enum ActionNames {
  RESET_RFID = 'rfid-reset-rfid-status',
  UPDATE_RFID_STATUS = 'update-rfid-status',
  ENABLE_RFID = 'rfid-enable',
  DISABLE_RFID = 'rfid-disable',
  OPEN_POPOVER = 'rfid-open-popover',
  CLOSE_POPOVER = 'rfid-close-popover',
  SET_SEARCHING = 'rfid-set-is-searching',
  RESET_STATE = 'rfid-reset-state',
  POLL_RFID = 'rfid-poll',
}

type AugmentedActionContext = TypedActionContext<Mutations, State, RootState>

export interface Actions {
  [ActionNames.RESET_STATE](context: AugmentedActionContext): void
  [ActionNames.ENABLE_RFID](
    context: AugmentedActionContext,
    payload: {
      event: (skus: Array<ListaDeiCapiData>) => void
      singleProduct: boolean
    }
  ): Promise<void>
  [ActionNames.POLL_RFID](
    context: AugmentedActionContext,
    payload: {
      event: (skus: Array<ListaDeiCapiData>) => void
      singleProduct: boolean
    }
  ): void
  [ActionNames.DISABLE_RFID](context: AugmentedActionContext): Promise<void>
  [ActionNames.OPEN_POPOVER](context: AugmentedActionContext): void
  [ActionNames.CLOSE_POPOVER](context: AugmentedActionContext): void
  [ActionNames.UPDATE_RFID_STATUS](
    context: AugmentedActionContext,
    rfid: RfidState
  ): void
  [ActionNames.SET_SEARCHING](
    context: AugmentedActionContext,
    payload: boolean
  ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionNames.RESET_STATE]: ({
    commit,
  }: ActionContext<RfidState, RootState>) => {
    commit(MutationNames.RESET_STATE)
  },
  [ActionNames.ENABLE_RFID]: async (
    context: ActionContext<RfidState, RootState>,
    payload
  ) => {
    try {
      await context.dispatch(ActionNames.SET_SEARCHING, true)
      const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi
      const rfidStatus = await api.apiV1CartActionActionPut('start')

      context.commit(MutationNames.UPDATE_RFID_STATUS, {
        enabled: !!rfidStatus,
      })
      return await context.dispatch(ActionNames.POLL_RFID, payload)
    } catch (err: unknown) {
      const e = err as ErrorD
      await context.dispatch(ActionNames.SET_SEARCHING, false)
      if (!isCancel(e) && e.response?.data?.errors?.length) {
        await context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          e.response.data.errors[0].detail,
          { root: true }
        )
      }
    }
  },
  [ActionNames.POLL_RFID]: async (
    context: ActionContext<RfidState, RootState>,
    payload
  ) => {
    while (context.state.enabled && context.state.abortController) {
      await new Promise((resolve) => setTimeout(resolve, 1000))

      // Keep a reference to the API we have to use.
      const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi

      const response = await api.apiV1CartGet({
        signal: context.state.abortController.signal,
      })
      const products = response.data
      if (products?.data?.length) {
        const datiCorrezioneStatoItemRfid = mapDatiCorrezioneStatoRfid(
          products.data
        )
        const response2 = await api.apiV1CartPut(datiCorrezioneStatoItemRfid, {
          signal: context.state.abortController.signal,
        })
        const filteredProducts = response2.data.data?.filter(
          (product) => product.attributes?.stato !== 'E'
        )
        if (filteredProducts) {
          if (payload.singleProduct) {
            payload.event([filteredProducts[0]])
            await context.dispatch(ActionNames.SET_SEARCHING, false)
            return
          }
          payload.event(filteredProducts)
        }
      }
    }
  },
  [ActionNames.DISABLE_RFID]: async (
    context: ActionContext<RfidState, RootState>
  ) => {
    // Cancel any pending polling request.
    context.state.abortController?.abort()

    try {
      const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi
      const rfidDisabled = await api.apiV1CartActionActionPut('stop')

      if (rfidDisabled) {
        await context.commit(MutationNames.UPDATE_RFID_STATUS, {
          enabled: false,
        })
      }
    } catch (err: unknown) {
      const e = err as ErrorD
      if (e.response?.data?.errors?.length) {
        await context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          e.response.data.errors[0].detail,
          { root: true }
        )
      }
    }
    await context.dispatch(ActionNames.SET_SEARCHING, false)
  },
  [ActionNames.OPEN_POPOVER]: (
    context: ActionContext<RfidState, RootState>
  ) => {
    context.commit(MutationNames.UPDATE_RFID_STATUS, { popoverIsVisible: true })
  },
  [ActionNames.CLOSE_POPOVER]: (
    context: ActionContext<RfidState, RootState>
  ) => {
    context.commit(MutationNames.UPDATE_RFID_STATUS, {
      popoverIsVisible: false,
    })
  },
  [ActionNames.UPDATE_RFID_STATUS]: (
    context: ActionContext<RfidState, RootState>,
    rfid: RfidState
  ) => {
    context.commit(MutationNames.UPDATE_RFID_STATUS, rfid)
  },
  [ActionNames.RESET_RFID]: async (
    context: ActionContext<RfidState, RootState>
  ) => {
    const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi
    const reset = await api.apiV1CartActionActionPut('reset')

    if (reset) {
      context.commit(MutationNames.RESET_STATE)
    }
  },
  [ActionNames.SET_SEARCHING]: (
    context: ActionContext<RfidState, RootState>,
    payload: boolean
  ) => {
    context.commit(MutationNames.IS_SEARCHING, payload)
  },
}
