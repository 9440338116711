import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import { RootState } from '..'
import { TypedMapActions, TypedMapMutations, TypedStateStore } from '../_types'
import { ActionNames, Actions, actions } from './_actions'
import { GetterNames, Getters, getters } from './_getters'
import { MutationNames, Mutations, mutations } from './_mutations'
import { State, initState } from './_state'

const ConsumersState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'consumers'

export type ConsumersStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const ConsumersActions = {
  FETCH_CONSUMERS: `${NAMESPACE}/${ActionNames.FETCH_CONSUMERS}`,
  FETCH_ANONYMOUS_CONSUMERS: `${NAMESPACE}/${ActionNames.FETCH_ANONYMOUS_CONSUMERS}`,
  CLEAR_CONSUMER: `${NAMESPACE}/${ActionNames.CLEAR_CONSUMER}`,
  RESET_STATE: `${NAMESPACE}/${ActionNames.RESET_STATE}`,
  CHECK_SOGLIA_CONTANTI: `${NAMESPACE}/${ActionNames.CHECK_SOGLIA_CONTANTI}`,
  SEND_CONTACT_PREFERENCES: `${NAMESPACE}/${ActionNames.SEND_CONTACT_PREFERENCES}`,
  PRINT_CONTACT_FORM: `${NAMESPACE}/${ActionNames.PRINT_CONTACT_FORM}`,
  SET_CURRENT_CONSUMER: `${NAMESPACE}/${ActionNames.SET_CURRENT_CONSUMER}`,
  RESET_CURRENT_CONSUMER: `${NAMESPACE}/${ActionNames.RESET_CURRENT_CONSUMER}`,
  GET_EDIT_CUSTOMER_DATA: `${NAMESPACE}/${ActionNames.GET_EDIT_CUSTOMER_DATA}`,
  STORE_LAST_CHANGE_CONSUMER: `${NAMESPACE}/${ActionNames.STORE_LAST_CHANGE_CONSUMER}`,
  CREATE_CONSUMER: `${NAMESPACE}/${ActionNames.CREATE_CONSUMER}`,
  UPDATE_CONSUMER: `${NAMESPACE}/${ActionNames.UPDATE_CONSUMER}`,
  SEARCH_CONSUMER_BY_MULTIPLE: `${NAMESPACE}/${ActionNames.SEARCH_CONSUMER_BY_MULTIPLE}`,
  SET_DATA_VISIBILITY: `${NAMESPACE}/${ActionNames.SET_DATA_VISIBILITY}`,
  RESET_DATA_VISIBILITY: `${NAMESPACE}/${ActionNames.RESET_DATA_VISIBILITY}`,
  SET_SELECTED_CONSUMER_STATUS: `${NAMESPACE}/${ActionNames.SET_SELECTED_CONSUMER_STATUS}`,
  SET_IS_JUST_CREATED: `${NAMESPACE}/${ActionNames.SET_IS_JUST_CREATED}`,
  GET_CONSUMER_ANONYMIZATION_STATUS: `${NAMESPACE}/${ActionNames.GET_CONSUMER_ANONYMIZATION_STATUS}`,
} as const

export const ConsumersGetters = {
  GET_CONSUMERS_LIST: `${NAMESPACE}/${GetterNames.CONSUMERS_LIST}`,
  GET_ANONYMOUS_CONSUMER_LIST: `${NAMESPACE}/${GetterNames.ANONYMOUS_CONSUMER_LIST}`,
  GET_LAST_CHANGE_CONSUMER: `${NAMESPACE}/${GetterNames.GET_LAST_CHANGE_CONSUMER}`,
  GET_CURRENT_CONSUMER: `${NAMESPACE}/${GetterNames.GET_CURRENT_CONSUMER}`,
  GET_DATA_VISIBILITY: `${NAMESPACE}/${GetterNames.GET_DATA_VISIBILITY}`,
  GET_SELECTED_CONSUMER_STATUS: `${NAMESPACE}/${GetterNames.GET_SELECTED_CONSUMER_STATUS}`,
  GET_IS_JUST_CREATED: `${NAMESPACE}/${GetterNames.GET_IS_JUST_CREATED}`,
} as const

export const ConsumersMutations = {
  STORE_CONSUMER: `${NAMESPACE}/${MutationNames.STORE_CONSUMER}`,
  SET_CURRENT_CONSUMER: `${NAMESPACE}/${MutationNames.SET_CURRENT_CONSUMER}`,
  STORE_LAST_CHANGE_CONSUMER: `${NAMESPACE}/${MutationNames.STORE_LAST_CHANGE_CONSUMER}`,
  STORE_ANONYMOUS: `${NAMESPACE}/${MutationNames.STORE_ANONYMOUS}`,
} as const

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_actions'
export * from './_getters'
export * from './_mutations'
export type { State as ConsumersState } from './_state'
export default ConsumersState
