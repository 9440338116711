import { RootState } from '@/store'
import { Payload } from 'vuex'
import { SessionKeys, TipiMovimenti } from './enums'
import { ApiV1SalesDataAttributes, ApiV1SalesSaleIdDataAttributes } from '@/api'
import { selectedConsumerStatus } from '@/store/consumer/index.d'
export function handlePersistance(mutation: Payload, state: RootState) {
  if (
    mutation.type.includes('sale-') &&
    Object.keys(state.sales.currentSale).length
  ) {
    const tempSale: ApiV1SalesDataAttributes &
      ApiV1SalesSaleIdDataAttributes & {
        selectedConsumerStatus: selectedConsumerStatus | undefined
      } = { ...state.sales.currentSale }
    tempSale.pk_consumer =
      state.sales?.currentSale?.pk_consumer ||
      state.consumers?.currentConsumer?.pk_consumer
    tempSale.pagamenti = tempSale.pagamenti?.filter((p) => {
      if (state.payments.payments_validator[p.progressivo as number] === null) {
        return false
      }

      if (p.codice_movimento !== TipiMovimenti.CONTABILITA_PAGAMENTO) {
        return true
      }

      return state.payments.payments_validator[p.progressivo as number]
    })

    sessionStorage.setItem(SessionKeys.CURRENT_SALE, JSON.stringify(tempSale))
  }
}
