import { SessionKeys } from '@/addons/enums'
import { FeLogger } from '@/addons/monitoring'
import store from '@/store'
import { GenericActions } from '@/store/generic-store'
import { ComponentInternalInstance } from 'vue'
import { createI18n, I18n, Locale, Path } from 'vue-i18n'
import { commonDateFormats } from './dates'
import { loadRouteMessages, loadRouteMessagesFromLocalJson } from './labels'
import { commonNumberFormats } from './numbers'

export const setLanguage = async (
  i18nInstance: I18n,
  locale: string,
  localesModules: string[],
  json?: boolean
): Promise<void> => {
  sessionStorage.setItem(SessionKeys.LOCALE, locale)
  i18nInstance.global.locale =
    sessionStorage.getItem(SessionKeys.LOCALE) ?? 'it'
  store.dispatch(
    GenericActions.SET_CURRENT_LANGUAGE,
    i18nInstance.global.locale
  )
  if (json) {
    await loadRouteMessagesFromLocalJson(i18nInstance, localesModules)
  } else {
    await loadRouteMessages(i18nInstance, localesModules)
  }
}

const i18nInstance = createI18n<true>({
  fallbackLocale: sessionStorage.getItem(SessionKeys.LOCALE) ?? 'en',
  locale: sessionStorage.getItem(SessionKeys.LOCALE) ?? 'it',
  availableLocales: ['en', 'it'],
  datetimeFormats: {
    en: { ...commonDateFormats },
    it: { ...commonDateFormats },
  },
  numberFormats: {
    en: { ...commonNumberFormats },
    it: { ...commonNumberFormats },
  },
  missing: (
    locale: Locale,
    key: Path,
    component?: ComponentInternalInstance
    // _type?: string @TODO add back when it will be handled
  ) => {
    let componentName = ''
    if (typeof component === 'object') {
      componentName = `(component/view "${component.type.name}")`
    }

    const message =
      `Missing translation for label "${key}" in language "${locale}" ${componentName}`.trim()

    FeLogger.warn(message)
  },
})

export { DatePlaceholder } from './dates'
export * from './labels'
export * from './numbers'
export default i18nInstance
