import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import { RootState } from '..'
import { TypedMapActions, TypedMapMutations, TypedStateStore } from '../_types'
import { ActionNames, Actions, actions } from './_actions'
import { GetterNames, Getters, getters } from './_getters'
import { MutationNames, Mutations, mutations } from './_mutations'
import { State, initState } from './_state'

const suspendedCreditState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'suspendedCredit'

export type SuspendedCreditStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const SuspendedCreditActions = {
  RESET_STATE: `${NAMESPACE}/${ActionNames.RESET_STATE}`,
  SEARCH_SUSPENDED_CREDITS: `${NAMESPACE}/${ActionNames.SEARCH_SUSPENDED_CREDITS}`,
  SEARCH_SUSPENDED_CONSUMERS: `${NAMESPACE}/${ActionNames.SEARCH_SUSPENDED_CONSUMERS}`,
  UPDATE_SUSPENDED_CONSUMERS_LIST: `${NAMESPACE}/${ActionNames.UPDATE_SUSPENDED_CONSUMERS_LIST}`,
  SET_SUSPENDED_CONSUMER: `${NAMESPACE}/${ActionNames.SET_SUSPENDED_CONSUMER}`,
  UPDATE_SUSPENDED_CREDIT: `${NAMESPACE}/${ActionNames.UPDATE_SUSPENDED_CREDIT}`,
  LOAD_ALL_SUSPENDED_CREDITS: `${NAMESPACE}/${ActionNames.LOAD_ALL_SUSPENDED_CREDITS}`,
  PRINT_SUSPENDED_CREDITS: `${NAMESPACE}/${ActionNames.PRINT_SUSPENDED_CREDITS}`,
  GET_ALL_PERFORMED_PAYMENTS: `${NAMESPACE}/${ActionNames.GET_ALL_PERFORMED_PAYMENTS}`,
  PRINT_PAYMENT: `${NAMESPACE}/${ActionNames.PRINT_PAYMENT}`,
  UPDATE_NUMERO_BOLETTINA: `${NAMESPACE}/${ActionNames.UPDATE_NUMERO_BOLETTINA}`,
  UPDATE_DROPDOWN_SELECTION: `${NAMESPACE}/${ActionNames.UPDATE_DROPDOWN_SELECTION}`,
  PERFORM_PAYMENT: `${NAMESPACE}/${ActionNames.PERFORM_PAYMENT}`,
  SET_CASHIER: `${NAMESPACE}/${ActionNames.SET_CASHIER}`,
  PREPARE_ID_TRANSAZIONE: `${NAMESPACE}/${ActionNames.PREPARE_ID_TRANSAZIONE}`,
  IS_LOADING: `${NAMESPACE}/${ActionNames.IS_LOADING}`,
  BURN_GIFT_CARD: `${NAMESPACE}/${ActionNames.BURN_GIFT_CARD}`,
} as const

export const SuspendedCreditGetters = {
  GET_SUSPENDED_CONSUMERS_LIST: `${NAMESPACE}/${GetterNames.GET_SUSPENDED_CONSUMERS_LIST}`,
  GET_SUSPENDED_CONSUMER: `${NAMESPACE}/${GetterNames.GET_SUSPENDED_CONSUMER}`,
  GET_SUSPENDED_CREDITS_LIST: `${NAMESPACE}/${GetterNames.GET_SUSPENDED_CREDITS_LIST}`,
  GET_FOOTER_INFOS: `${NAMESPACE}/${GetterNames.GET_FOOTER_INFOS}`,
  GET_ALL_SUSPENDED_CREDITS: `${NAMESPACE}/${GetterNames.GET_ALL_SUSPENDED_CREDITS}`,
  GET_ALL_PERFORMED_PAYMENTS: `${NAMESPACE}/${GetterNames.GET_ALL_PERFORMED_PAYMENTS}`,
  GET_NUMERO_BOLLETTINA: `${NAMESPACE}/${GetterNames.GET_NUMERO_BOLLETTINA}`,
  GET_TOTALS: `${NAMESPACE}/${GetterNames.GET_TOTALS}`,
  GET_CASHIER_ID: `${NAMESPACE}/${GetterNames.GET_CASHIER_ID}`,
  GET_CASHIER_LABEL: `${NAMESPACE}/${GetterNames.GET_CASHIER_LABEL}`,
  IS_LOADING_DATA: `${NAMESPACE}/${GetterNames.IS_LOADING_DATA}`,
  GET_ID_TRANSAZIONE: `${NAMESPACE}/${GetterNames.GET_ID_TRANSAZIONE}`,
  GET_CREATION_DATE: `${NAMESPACE}/${GetterNames.GET_CREATION_DATE}`,
} as const

export const SuspendedCreditMutations = {
  RESET_STATE: `${NAMESPACE}/${MutationNames.RESET_STATE}`,
} as const

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_actions'
export * from './_getters'
export * from './_mutations'
export type {
  AllSuspendedCredits,
  SuspendedCredit,
  SuspendedCreditAttributes,
  State as SuspendedCreditState,
} from './_state'
export default suspendedCreditState
