import { DateTimeFormatOptions } from '@intlify/core-base'
import i18nInstance from '.'

export const commonDateFormats: Record<string, DateTimeFormatOptions> = {
  time: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  timeSeconds: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  },
  date: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  datetime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
}

export class DatePlaceholder {
  private static _cachedValue = ''

  static get value(): string {
    if (this._cachedValue) {
      return this._cachedValue
    }

    const { locale } = i18nInstance.global

    const formatter = new Intl.DateTimeFormat(locale)
    const parts = formatter.formatToParts()

    let result = ''

    for (const part of parts) {
      let value = ''

      if (part.type === 'literal') {
        value += part.value
      } else {
        value = i18nInstance.global.t(`pos_common.date_parts_${part.type}`)
      }

      result += value
    }

    return result
  }
}
