<script lang="ts" setup>
import { PageContexts } from '@/addons/enums'
import i18n from '@/addons/i18n'
import feHeader from '@/components/layout/page-header.vue'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import {
  BarcodeScanningMode,
  BarcodeValidatorFunction,
} from '@/interfaces/generics'
import {
  BarcodeScanner,
  ScanOptions,
  SupportedFormat,
} from '@capacitor-community/barcode-scanner'
import { IonPage } from '@ionic/vue'
import {
  PropType,
  defineEmits,
  ref,
  defineProps,
  onMounted,
  onUnmounted,
} from 'vue'
import FeButtonCounter from '@/modules/barcode-scanner/components/button-counter.vue'
import FeProductCard from '@/modules/barcode-scanner/components/product-card.vue'
import FeMaskViewfinder from '@/modules/barcode-scanner/components/mask-viewfinder.vue'
import { catalogApi } from '@/addons/axios'
import { ErrorD } from '@/addons/interface'
import { NotificationsActions } from '@/store/notifications-store'
import { useStore } from 'vuex'
import { SoundCueHelper } from '@/addons/functions/sales'

const BARCODE_SCANNER_CLASS = 'has-barcode-scanner'

const props = defineProps({
  context: {
    type: String as PropType<PageContexts>,
    default: PageContexts.FRONTOFFICE,
    validator(value: PageContexts) {
      return Object.values(PageContexts).includes(value)
    },
  },

  container: {
    type: Object as PropType<HTMLElement>,
    required: true,
  },

  validator: {
    type: Function as PropType<BarcodeValidatorFunction>,
    default: () => Promise.resolve(true),
  },

  mode: {
    type: String as PropType<BarcodeScanningMode>,
    default: 'single',
  },

  cashier: {
    type: String,
    default: '',
  },
})

const store = useStore()
const emits = defineEmits(['results', 'barcode'])
const barcodes = ref<Array<string>>([])
const coords = ref(['', ''])
const selectedProduct = ref()

// TODO: Make this options configurable.
const viewFinderWidth = 400
const viewFinderHeight = 100
const sidebarWidth = 64
const headerHeight = 48
const viewportWidth = window.innerWidth
const viewportHeight = window.innerHeight

const startScan = async () => {
  const grantedPermissions = await didUserGrantPermission()

  if (!grantedPermissions) {
    await BarcodeScanner.checkPermission({ force: false })
  }

  try {
    const scannerCoords = [
      Math.round((viewportWidth - viewFinderWidth + sidebarWidth) / 2),
      Math.round((viewportHeight - viewFinderHeight + headerHeight) / 2),
    ]
    // make background of WebView transparent
    const scanOption: ScanOptions = {
      coords: scannerCoords,
      width: viewFinderWidth,
      height: viewFinderHeight,
      targetedFormats: [
        SupportedFormat.UPC_A,
        SupportedFormat.UPC_E,
        SupportedFormat.UPC_EAN_EXTENSION,
        SupportedFormat.EAN_8,
        SupportedFormat.EAN_13,
        SupportedFormat.CODE_39,
        SupportedFormat.CODE_39_MOD_43,
        SupportedFormat.CODE_93,
        SupportedFormat.CODE_128,
        SupportedFormat.CODABAR,
        SupportedFormat.ITF,
        SupportedFormat.ITF_14,
      ],
    }
    await new Promise<string[]>((resolve) => {
      BarcodeScanner.startScanning(scanOption, async (result) => {
        const barcode = result?.hasContent ? result?.content : undefined
        // Check whether barcode we just read can be considered valid
        // I think this is a bug, it should be `if (barcode && await validator(barcode)) {` but with (await props.validator(barcode))  this condition not work properly
        if (barcode) {
          barcodes.value.push(barcode)
          SoundCueHelper.onProductAdded(true)
          // If we need a single barcode, we can short circuit out.
          if (props.mode === 'single') {
            await BarcodeScanner.pauseScanning()
            emits('barcode', barcode)
            emits('results', barcodes.value)
            return resolve(barcodes.value.map(String))
          }
          await BarcodeScanner.pauseScanning()
          await getProductData(barcode)
          if (selectedProduct.value) {
            setTimeout(async () => {
              selectedProduct.value = undefined
              await BarcodeScanner.resumeScanning()
            }, 1500)
          }
        }
      })
    })
  } catch (e: unknown) {
    selectedProduct.value = undefined
    const error = e as ErrorD
    store.dispatch(
      NotificationsActions.NOTIFY_ERROR,
      error?.response?.data?.errors?.length
        ? error.response.data.errors[0]?.detail
        : 'pos_sale.barcode_scan_not_valid',
      { root: true }
    )
  }
}

const addProducts = () => {
  barcodes.value.forEach((barcode) => {
    emits('barcode', barcode)
  })
  emits('results', barcodes.value.length === 0 ? [] : barcodes.value)
}

const removeProduct = () => {
  barcodes.value.pop()
  selectedProduct.value = undefined
}

const getProductData = async (barcode: string): Promise<void> => {
  try {
    const result = await catalogApi.apiV1PoswebModelsDataGet(barcode)
    selectedProduct.value = result.data?.data
      ? result.data?.data[0].attributes
      : undefined
  } catch (e: unknown) {
    selectedProduct.value = undefined
    const error = e as ErrorD
    store.dispatch(
      NotificationsActions.NOTIFY_ERROR,
      error?.response?.data?.errors?.length
        ? error.response.data.errors[0]?.detail
        : 'pos_sale.barcode_scan_not_valid',
      { root: true }
    )
  }
}

const hideBackground = async () => {
  await BarcodeScanner.hideBackground()

  props.container?.classList.add(BARCODE_SCANNER_CLASS)

  window.screen.orientation?.lock?.('portrait')
}

const showBackground = async () => {
  await BarcodeScanner.showBackground()
  await BarcodeScanner.stopScan({ resolveScan: true })

  props.container?.classList.remove(BARCODE_SCANNER_CLASS)

  window.screen.orientation?.unlock?.()
}

const didUserGrantPermission = async () => {
  const status = await BarcodeScanner.checkPermission({ force: false })
  if (status.granted) {
    return true
  }

  if (status.denied) {
    modalController.open({
      type: ModalTypes.ERROR,
      title: i18n.global.t('pos_common.error'),
      component: '',
      message: i18n.global.t('pos_sale.camera_permission_denied'),
      confirmLabel: i18n.global.t('pos_common.continue'),
      confirmActionButton: true,
      confirmAction: () => {
        BarcodeScanner.openAppSettings()
      },
      closedAction: () => {
        // TODO: Router push on previus page
      },
    })
  }

  if (status.restricted || status.unknown) {
    return false
  }

  const statusRequest = await BarcodeScanner.checkPermission({
    force: true,
  })

  if (statusRequest.granted) {
    return true
  }

  return false
}

onMounted(() => {
  // Start scanning for barcodes immediately.
  coords.value = [
    // Center the viewfinder in the middle of the screen.
    // We subtract 48 from the width and 96 from the height to account for the header and sidebar.
    // This is a temporary solution until we can get the actual height of the header and sidebar.
    Math.round((viewportWidth - sidebarWidth - viewFinderWidth) / 2).toString(),
    // TODO: fix the calculation of the height, remove all fixed constants like 2, 30
    Math.round(
      (viewportHeight - headerHeight * 2 - 30 - viewFinderHeight) / 2
    ).toString(),
  ]
  hideBackground()
  startScan()
})

onUnmounted(() => {
  if (props.mode === 'multiple') {
    emits('results', barcodes.value.length !== 0 ? barcodes.value : [])
  }
  showBackground()
})
</script>

<template>
  <ion-page id="feBarcodeScanner">
    <fe-header
      :context="context"
      :title="$t('pos_sale.barcode_title')"
    ></fe-header>
    <fe-mask-viewfinder
      :width="viewFinderWidth"
      :height="viewFinderHeight"
      :coords="coords"
    ></fe-mask-viewfinder>
    <fe-product-card
      v-if="selectedProduct"
      :barcode="selectedProduct?.sku"
      :product="selectedProduct"
      :index="barcodes.length"
      :cod-cashier="cashier"
      :mode="mode"
      @delete-product="removeProduct(selectedProduct?.sku)"
    ></fe-product-card>
    <fe-button-counter
      v-if="mode === 'multiple'"
      :counter="barcodes.length"
      :barcodes="barcodes"
      @add-products="addProducts"
    ></fe-button-counter>
  </ion-page>
</template>
