import {
  GetCashiersAttributes,
  GiftCardInformationDataAttributes,
  InformazioniConsumatriciTrovateConSospesiDataAttributes,
  InformazioniListaPagamentiDiSospesiDataAttributes,
} from '@/api'

export interface AllSuspendedCredits {
  importo_totale: string
  crediti: any[]
}

export interface State {
  suspendedCreditList: any[]
  numeroBollettina: number | undefined
  consumersList: InformazioniConsumatriciTrovateConSospesiDataAttributes[]
  consumer: any
  footerInfos: any
  allSuspendedCredits: AllSuspendedCredits
  cashier: GetCashiersAttributes
  allPerformedPayments: InformazioniListaPagamentiDiSospesiDataAttributes[]
  idTransazione: string
  creationDate: string
  isLoading: boolean
}

export interface SuspendedCreditAttributes {
  cod_negozio: string
  numero_documento: number
  data_documento: string
  pk_consumer: string
  tipo: string
  valore_codice: string
  importo: number
  cognome: string
  nome: string
  tipo_prodotto: string
  pagamento_remoto_preferito: string
  scaduto: boolean
  desc_val: string
  data_vendita: string
  numero_bollettina: number
  consumatrice: string
  saldo_vendita: number
  importo_rimanente: number
  selected?: boolean
  importo_ricevuto?: number
}
export interface SuspendedCredit {
  id: number | string
  type: string
  attributes: SuspendedCreditAttributes
}

export interface BurnGiftCardType {
  giftCard: GiftCardInformationDataAttributes
  amount: number
  progressivo: number
}

export const initState = (): State => ({
  suspendedCreditList: [],
  numeroBollettina: undefined,
  consumersList: [],
  consumer: {},
  footerInfos: {},
  allSuspendedCredits: {} as AllSuspendedCredits,
  cashier: {} as GetCashiersAttributes,
  allPerformedPayments: [],
  idTransazione: '',
  creationDate: '',
  isLoading: false,
})
