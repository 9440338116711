import { isQueryStringFlagTruthy } from '@/addons/functions/configs'
import { QueryStringFlags } from '@/addons/enums'

export interface State {
  enabled: boolean
  popoverIsVisible: boolean
  enableClientSideMocks: boolean
  is_searching: boolean
  abortController?: AbortController
}
export const initState = (): State => ({
  enabled: false,
  popoverIsVisible: false,
  is_searching: false,
  abortController: undefined,
  enableClientSideMocks: isQueryStringFlagTruthy(
    QueryStringFlags.ENABLE_RFID_MOCKS
  ),
})
