import { MutationTree } from 'vuex'
import { initState, State } from './_state'

export enum MutationNames {
  RESET_STATE = 'resetState',
  SET_SUSPENDED_CREDITS_LIST = 'set-suspended-credits-list',
  SET_SUSPENDED_CONSUMERS_LIST = 'set-suspended-consumers-list',
  SET_SUSPENDED_CONSUMER = 'set-suspended-consumer',
  SET_FOOTER_INFOS = 'set-footer-infos',
  UPDATE_SUSPENDED_CREDIT = 'update-suspended-credit',
  UPDATE_ALL_SUSPENDED_CREDITS_LIST = 'update-all-suspended-credits-list',
  UPDATE_ALL_PERFORMED_PAYMENTS = 'update-all-performed-payments',
  UPDATE_NUMERO_BOLLETTINA = 'update-numero-bollettina',
  SET_CASHIER = 'set-cashier',
  STORE_ID_TRANSAZIONE = 'store-id-transazione',
  STORE_CREATION_DATE = 'store-creation-date',
  IS_LOADING = 'is-loading',
}

export type Mutations = {
  [MutationNames.RESET_STATE](state: State): void

  [MutationNames.SET_SUSPENDED_CREDITS_LIST](
    state: State,
    creditList: State['suspendedCreditList']
  ): void

  [MutationNames.SET_SUSPENDED_CONSUMERS_LIST](
    state: State,
    consumerList: State['consumersList']
  ): void

  [MutationNames.SET_SUSPENDED_CONSUMER](
    state: State,
    consumer: State['consumer']
  ): void

  [MutationNames.SET_FOOTER_INFOS](
    state: State,
    payload: State['footerInfos']
  ): void

  [MutationNames.UPDATE_SUSPENDED_CREDIT](
    state: State,
    payload: { id: number; attribute: string; value: string } // FIXME: maybe we can improve this?
  ): void

  [MutationNames.UPDATE_ALL_SUSPENDED_CREDITS_LIST](
    state: State,
    payload: State['allSuspendedCredits']
  ): void

  [MutationNames.UPDATE_NUMERO_BOLLETTINA](
    state: State,
    numeroBollettina: State['numeroBollettina']
  ): void

  [MutationNames.SET_CASHIER](state: State, cashier: State['cashier']): void

  [MutationNames.UPDATE_ALL_PERFORMED_PAYMENTS](
    state: State,
    allPerformedPayments: State['allPerformedPayments']
  ): void

  [MutationNames.STORE_ID_TRANSAZIONE](
    state: State,
    idTransazione: string
  ): void

  [MutationNames.STORE_CREATION_DATE](state: State, creationDate: string): void

  [MutationNames.IS_LOADING](state: State, isLoading: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationNames.RESET_STATE](state) {
    Object.assign(state, initState())
  },
  [MutationNames.SET_SUSPENDED_CREDITS_LIST](state, creditLists) {
    state.suspendedCreditList = creditLists
  },
  [MutationNames.SET_SUSPENDED_CONSUMERS_LIST](state, consumersList) {
    state.consumersList = consumersList
  },
  [MutationNames.SET_SUSPENDED_CONSUMER](state, consumer) {
    state.consumer = consumer
  },
  [MutationNames.SET_FOOTER_INFOS](state, footerInfos) {
    state.footerInfos = footerInfos
  },
  [MutationNames.UPDATE_SUSPENDED_CREDIT](
    state,
    payload: { id: number; attribute: string; value: string }
  ) {
    const { id, attribute, value } = payload
    const suspendedCredit = state.suspendedCreditList.find(
      (credit) => credit.id === id
    )
    if (suspendedCredit) {
      suspendedCredit.attributes[attribute] = value
    }
  },
  [MutationNames.UPDATE_ALL_SUSPENDED_CREDITS_LIST](state, payload) {
    state.allSuspendedCredits = payload
  },

  [MutationNames.UPDATE_NUMERO_BOLLETTINA](state, numeroBollettina) {
    state.numeroBollettina = numeroBollettina
  },
  [MutationNames.SET_CASHIER](state, cashier) {
    state.cashier = cashier
  },
  [MutationNames.UPDATE_ALL_PERFORMED_PAYMENTS](state, allPerformedPayments) {
    state.allPerformedPayments = allPerformedPayments
  },
  [MutationNames.STORE_ID_TRANSAZIONE]: (state, idTransazione) => {
    state.idTransazione = idTransazione
  },
  [MutationNames.STORE_CREATION_DATE]: (state, creationDate) => {
    state.creationDate = creationDate
  },
  [MutationNames.IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },
}
