import { unformatMMFGDate } from '@/addons/date'
import { isWithinInterval } from 'date-fns'

// Verifica se la data attuale è compresa tra data_inizio e data_fine.
// Restituisce true se data_fine è assente o se oggi è all'interno dell'intervallo.
export function isInRange(data): boolean {
  const { data_inizio, data_fine } = data
  if (!data_fine) {
    return true
  }
  const unformattedDataInizio = unformatMMFGDate(data_inizio)
  const unformattedDataFine = unformatMMFGDate(data_fine)
  const today = new Date()
  return isWithinInterval(today, {
    start: unformattedDataInizio,
    end: unformattedDataFine,
  })
}
