<script lang="ts">
import { defineComponent } from 'vue'
import { ResponsePayload11DataAttributes } from '@/api'
import { dashboardApi } from '@/addons/axios'
import FeIcon from '@/components/global/icon.vue'
import { IonList, IonItem, IonLabel } from '@ionic/vue'

export default defineComponent({
  name: 'FeExternalLinks',
  components: {
    FeIcon,
    IonList,
    IonItem,
    IonLabel,
  },
  data() {
    return {
      externalLinks: [] as ResponsePayload11DataAttributes[] | undefined,
    }
  },
  async mounted() {
    this.externalLinks = (
      await dashboardApi.apiV1DashboardSitiEsterniGet()
    ).data.data?.attributes
  },
  methods: {
    onLinkClick(link: ResponsePayload11DataAttributes): void {
      window.open(link.url, '_system')
    },
  },
})
</script>

<template>
  <ion-list class="external-links-list">
    <ion-item
      v-for="link in externalLinks"
      :key="link.descrizione"
      lines="none"
      class="external-links-list__item"
      @click="onLinkClick(link)"
    >
      <ion-label>{{ link.descrizione }}</ion-label>
      <fe-icon slot="end" icon="link" />
    </ion-item>
  </ion-list>
</template>

<style lang="scss" scoped>
.external-links-list {
  padding: 1rem 3rem;
  background-color: transparent;

  &__item {
    cursor: pointer;
    box-shadow: 0 0 1.8rem -0.7rem #aaaaaa;
    padding: 0 2rem !important;
    border-radius: 0.5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
    align-items: center;

    :deep(.item-inner) {
      border: 0 !important;
    }
  }
}
</style>
