import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import {
  TypedMapActions,
  TypedMapMutations,
  TypedStateStore,
} from '@/store/_types'
import { RootState } from '@/store'
import { State, initState } from './_state'
import { GetterNames, Getters, getters } from './_getters'
import {
  ActionsNames,
  Actions,
  actions,
} from '@/modules/loyalty/store/_actions'
import {
  MutationsNames,
  Mutations,
  mutations,
} from '@/modules/loyalty/store/_mutations'

const loyaltyState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'loyalty'

export type LoyaltyStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const LoyaltyActions = {
  RESET_STATE: `${NAMESPACE}/${ActionsNames.RESET_STATE}`,
  SET_LOYALTY_CARDS: `${NAMESPACE}/${ActionsNames.SET_LOYALTY_CARDS}`,
  SET_LOYALTY_ERROR: `${NAMESPACE}/${ActionsNames.SET_LOYALTY_ERROR}`,
  SET_PROMOTION_TYPE: `${NAMESPACE}/${ActionsNames.SET_PROMOTION_TYPE}`,
  ACTIVATE_CARD: `${NAMESPACE}/${ActionsNames.ACTIVATE_CARD}`,
  DISABLE_CARD: `${NAMESPACE}/${ActionsNames.DISABLE_CARD}`,
  FORCING_POINTS: `${NAMESPACE}/${ActionsNames.FORCING_POINTS}`,
  SET_SELECTED_DISCOUNT: `${NAMESPACE}/${ActionsNames.SET_SELECTED_DISCOUNT}`,
  RESET_DISCOUNTS: `${NAMESPACE}/${ActionsNames.RESET_DISCOUNTS}`,
  SET_AFFILIATE_PROGRAMS: `${NAMESPACE}/${ActionsNames.SET_AFFILIATE_PROGRAMS}`,
  RESET_AFFILIATE_PROGRAMS: `${NAMESPACE}/${ActionsNames.RESET_AFFILIATE_PROGRAMS}`,
  SET_CURRENT_SALE_FIDELITY_POINTS: `${NAMESPACE}/${ActionsNames.SET_CURRENT_SALE_FIDELITY_POINTS}`,
  UPDATE_CURRENT_SALE_FIDELITY_POINTS: `${NAMESPACE}/${ActionsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS}`,
  RESET_CURRENT_SALE_FIDELITY_POINTS: `${NAMESPACE}/${ActionsNames.RESET_CURRENT_SALE_FIDELITY_POINTS}`,
  TRANSFER_POINTS: `${NAMESPACE}/${ActionsNames.TRANSFER_POINTS}`,
  CONFIRM_PROMOTION_LOYALTY: `${NAMESPACE}/${ActionsNames.CONFIRM_PROMOTION_LOYALTY}`,
  SET_CURRENT_PROGRAM: `${NAMESPACE}/${ActionsNames.SET_CURRENT_PROGRAM}`,
}

export const LoyaltyMutations = {
  RESET_STATE: `${NAMESPACE}/${MutationsNames.RESET_STATE}`,
  SET_LOYALTY_CARDS: `${NAMESPACE}/${MutationsNames.SET_LOYALTY_CARDS}`,
  UPDATE_LOYALTY_ERROR: `${NAMESPACE}/${MutationsNames.UPDATE_LOYALTY_ERROR}`,
  SET_PROMOTION_TYPE: `${NAMESPACE}/${MutationsNames.SET_PROMOTION_TYPE}`,
  SET_CUSTOM_STEPS: `${NAMESPACE}/${MutationsNames.SET_CUSTOM_STEPS}`,
  SET_SELECTED_DISCOUNT: `${NAMESPACE}/${MutationsNames.SET_SELECTED_DISCOUNT}`,
  RESET_DISCOUNTS: `${NAMESPACE}/${MutationsNames.RESET_DISCOUNTS}`,
  SET_AFFILIATE_PROGRAMS: `${NAMESPACE}/${MutationsNames.SET_AFFILIATE_PROGRAMS}`,
  RESET_AFFILIATE_PROGRAMS: `${NAMESPACE}/${MutationsNames.RESET_AFFILIATE_PROGRAMS}`,
  UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE: `${NAMESPACE}/${MutationsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE}`,
}

export const LoyaltyGetters = {
  GET_SELECTED_CARD: `${NAMESPACE}/${GetterNames.GET_LOYALTY_CARDS}`,
  GET_ENABLED_CARD: `${NAMESPACE}/${GetterNames.GET_ENABLED_CARD}`,
  GET_DISABLED_CARDS: `${NAMESPACE}/${GetterNames.GET_DISABLED_CARDS}`,
  GET_CUSTOM_STEPS: `${NAMESPACE}/${GetterNames.GET_CUSTOM_STEPS}`,
  GET_CURRENT_LEVEL: `${NAMESPACE}/${GetterNames.GET_CURRENT_LEVEL}`,
  GET_PROMOTION_TYPE: `${NAMESPACE}/${GetterNames.GET_PROMOTION_TYPE}`,
  IS_LOYALTY_ACTIVE: `${NAMESPACE}/${GetterNames.IS_LOYALTY_ACTIVE}`,
  IS_ERROR_LOYALTY: `${NAMESPACE}/${GetterNames.IS_ERROR_LOYALTY}`,
  GET_SELECTED_DISCOUNT: `${NAMESPACE}/${GetterNames.GET_SELECTED_DISCOUNT}`,
  GET_AFFILIATE_PROGRAMS: `${NAMESPACE}/${GetterNames.GET_AFFILIATE_PROGRAMS}`,
  GET_POINTS_BALANCE: `${NAMESPACE}/${GetterNames.GET_POINTS_BALANCE}`,
  IS_DISABLE_CARDS_ACTIVE: `${NAMESPACE}/${GetterNames.IS_DISABLE_CARDS_ACTIVE}`,
  HAS_CUSTOM_STEPS_LEVEL: `${NAMESPACE}/${GetterNames.HAS_CUSTOM_STEPS_LEVEL}`,
  GET_CURRENT_PROGRAM: `${NAMESPACE}/${GetterNames.GET_CURRENT_PROGRAM}`,
  GET_ALL_PROMOTION_TYPE: `${NAMESPACE}/${GetterNames.GET_ALL_PROMOTION_TYPE}`,
  IS_PROGRAM_ACTIVE: `${NAMESPACE}/${GetterNames.IS_PROGRAM_ACTIVE}`,
}

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_mutations'
export * from './_actions'
export * from './_getters'
export type { State as LoyaltyState } from './_state'
export default loyaltyState
