import { b2eRefundsApi } from '@/addons/axios'
import {
  GetB2ERefundsData,
  GetB2ERefundsAttributes,
  GetCashiersAttributes,
  ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributesTipoStornoEnum as TipoStornoEnum,
} from '@/api'
import { ActionContext } from 'vuex'
import { RootState } from './index'
import i18n from '@/addons/i18n'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import { NotificationsActions } from '@/store/notifications-store'
import store from '@/store'
import { ConfigGetters } from '@/store/configs-store'
import { formatToMMFGDate, fromHumanDateStringToDate } from '@/addons/date'
import LoaderController from '@/components/loader/LoaderController'
import { PageContexts } from '@/addons/enums'

type modalCreditCardInstanceType = {
  confirm(): void
  close(): void
}
export interface RefundB2EListState {
  refundB2EList: GetB2ERefundsAttributes[]
  filters: RefundB2EListFilter
  isLoading: boolean
  cod_cassiera: GetCashiersAttributes | null
  refundsSelected: GetB2ERefundsAttributes[] | null
  modalCreditCardInstance: modalCreditCardInstanceType | undefined
  handleOpenCreditCard: undefined | boolean
  insertCreditCard: undefined | boolean
}

export interface RefundB2EListFilter {
  filterOrderNumber: number | undefined
  filterOrderDate: string
  filterBollettinaNumber: string
  filterBollettinaDate: string
  filterDeliveryNumber: string
  filterName: string
  filterSurname: string
}

enum Mutations {
  RESET_STATE = 'resetState',
  SET_SEARCH_FORM = 'setfilters',
  RESET_FILTER = 'resetFilter',
  SET_FILTER_VALUE = 'setFilterValue',
  SET_LIST_REFUND_B2E = 'setListRefundB2E',
  SET_LOADING_DATA = 'setLoadingData',
  SET_CASHIER = 'setCashier',
  ADD_SELECTED_REFUNDS = 'addSelectedRefunds',
  REMOVE_SELECTED_REFUNDS = 'removeSelectedRefunds',
  RESET_SELECTED_REFUNDS = 'resetSelectedRefunds',
  SET_INSERT_CREDIT_CARD = 'setInsertCreditCard',
  SET_MODAL_CREDIT_CARD_INSTANCE = 'setModalCrediCardInstance',
}

enum Actions {
  RESET_STATE = 'resetState',
  RESET_FILTER = 'resetFilter',
  SET_FILTER_VALUE = 'setFilterValue',
  SEARCH_REFUND_B2E = 'searchRefundB2E',
  SET_CASHIER = 'setCashier',
  ADD_SELECTED_REFUNDS = 'addSelectedRefunds',
  REMOVE_SELECTED_REFUNDS = 'removeSelectedRefunds',
  RESET_SELECTED_REFUNDS = 'resetSelectedRefunds',
  OPEN_CREDIT_CARD_MODAL = 'openCreditCardModal',
  CLOSE_CREDIT_CARD_MODAL = 'closeCreditCardModal',
  SET_INSERT_CREDIT_CARD = 'setInsertCreditCard',
}

enum Getters {
  GET_REFUND_B2E_LIST = 'refundB2EList',
  GET_FILTERS = 'getFilters',
  IS_LOADING_DATA = 'isLoading',
  GET_TOTALE_PRICE_REFUND_SELECTED = 'getTotalPriceRefundSelected',
  GET_CASHIER = 'getCashier',
  GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION = 'getHandleOpenCreditCardAccordion',
  GET_SELECTED_REFUNDS = 'getSelectedRefunds',
  GET_INSERT_CREDIT_CARD = 'getInsertCreditCard',
}

const initFilter = (): RefundB2EListFilter => {
  return {
    filterBollettinaDate: '',
    filterBollettinaNumber: '',
    filterDeliveryNumber: '',
    filterName: '',
    filterOrderDate: '',
    filterOrderNumber: undefined,
    filterSurname: '',
  }
}

const initState = (): RefundB2EListState => ({
  refundB2EList: [],
  filters: initFilter(),
  isLoading: false,
  cod_cassiera: null,
  refundsSelected: [],
  modalCreditCardInstance: undefined,
  handleOpenCreditCard: undefined,
  insertCreditCard: false,
})

const refundB2EListStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: RefundB2EListState): void => {
      Object.assign(state, initState())
    },
    [Mutations.RESET_FILTER]: (state: RefundB2EListState): void => {
      state.filters = initFilter()
    },
    [Mutations.SET_FILTER_VALUE]: (
      state: RefundB2EListState,
      payload: object
    ): void => {
      const temp = { ...state.filters }
      temp[payload.name] = payload.value
      state.filters = temp
    },
    [Mutations.SET_LIST_REFUND_B2E]: (
      state: RefundB2EListState,
      list: GetB2ERefundsAttributes[]
    ): void => {
      state.refundB2EList = list
    },
    [Mutations.SET_LOADING_DATA]: (
      state: RefundB2EListState,
      value: boolean
    ): void => {
      state.isLoading = value
    },
    [Mutations.SET_CASHIER]: (
      state: RefundB2EListState,
      cod_cassiera: GetCashiersAttributes | null
    ): void => {
      state.cod_cassiera = cod_cassiera
    },
    [Mutations.RESET_SELECTED_REFUNDS]: (state: RefundB2EListState): void => {
      state.refundsSelected = []
    },
    [Mutations.ADD_SELECTED_REFUNDS]: (
      state: RefundB2EListState,
      value: GetB2ERefundsAttributes
    ): void => {
      state.refundsSelected?.push(value)
    },
    [Mutations.REMOVE_SELECTED_REFUNDS]: (
      state: RefundB2EListState,
      value: GetB2ERefundsAttributes
    ): void => {
      state.refundsSelected =
        state.refundsSelected?.filter(
          (el) => el.numero_spedizione !== value.numero_spedizione
        ) || state.refundsSelected
    },
    [Mutations.SET_MODAL_CREDIT_CARD_INSTANCE]: (
      state: RefundB2EListState,
      payload: modalCreditCardInstanceType
    ): void => {
      state.modalCreditCardInstance = payload
    },
    [Mutations.SET_INSERT_CREDIT_CARD]: (
      state: RefundB2EListState,
      payload: boolean | undefined
    ): void => {
      state.insertCreditCard = payload
    },
  },
  actions: {
    [Actions.RESET_STATE]: (
      context: ActionContext<RefundB2EListState, RootState>
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.RESET_FILTER]: (
      context: ActionContext<RefundB2EListState, RootState>
    ): void => {
      context.commit(Mutations.RESET_FILTER)
    },
    [Actions.SET_FILTER_VALUE]: (
      context: ActionContext<RefundB2EListState, RootState>,
      payload: object
    ): void => {
      context.commit(Mutations.SET_FILTER_VALUE, payload)
    },
    [Actions.SET_CASHIER]: (
      context: ActionContext<RefundB2EListState, RootState>,
      cashier: string
    ): void => {
      context.commit(Mutations.SET_CASHIER, cashier)
    },
    [Actions.SEARCH_REFUND_B2E]: async (
      context: ActionContext<RefundB2EListState, RootState>
    ): Promise<void> => {
      context.commit(Mutations.SET_LOADING_DATA, true)
      context.commit(Mutations.SET_LIST_REFUND_B2E, [])
      const formatOrderDate =
        context.state.filters.filterOrderDate !== ''
          ? formatToMMFGDate(
              fromHumanDateStringToDate(
                context.state.filters.filterOrderDate,
                store.getters[ConfigGetters.GET_DATE_FORMAT]
              )
            ).replaceAll(/-/g, '')
          : ''
      const formatBollettinaDate =
        context.state.filters.filterBollettinaDate !== ''
          ? formatToMMFGDate(
              fromHumanDateStringToDate(
                context.state.filters.filterBollettinaDate,
                store.getters[ConfigGetters.GET_DATE_FORMAT]
              )
            ).replaceAll(/-/g, '')
          : ''
      try {
        LoaderController.show({
          section: PageContexts.FRONTOFFICE,
        })
        const response = await b2eRefundsApi.apiV1B2eRefundsGet(
          context.state.filters.filterBollettinaNumber,
          formatBollettinaDate,
          context.state.filters.filterOrderNumber,
          formatOrderDate,
          context.state.filters.filterDeliveryNumber,
          context.state.filters.filterSurname,
          context.state.filters.filterName
        )
        const refunds = (response.data as { data: Array<GetB2ERefundsData> })
          .data
        context.commit(
          Mutations.SET_LIST_REFUND_B2E,
          refunds.map((el) => el.attributes)
        )
      } catch (e) {
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          'pos_common.generic_error',
          { root: true }
        )
      } finally {
        context.commit(Mutations.SET_LOADING_DATA, false)
        LoaderController.hide()
      }
    },
    [Actions.ADD_SELECTED_REFUNDS]: (
      context: ActionContext<RefundB2EListState, RootState>,
      payload: GetB2ERefundsAttributes
    ): void => {
      context.commit(Mutations.ADD_SELECTED_REFUNDS, payload)
    },
    [Actions.RESET_SELECTED_REFUNDS]: (
      context: ActionContext<RefundB2EListState, RootState>,
      payload: GetB2ERefundsAttributes
    ): void => {
      context.commit(Mutations.RESET_SELECTED_REFUNDS, payload)
    },
    [Actions.REMOVE_SELECTED_REFUNDS]: (
      context: ActionContext<RefundB2EListState, RootState>,
      payload: GetB2ERefundsAttributes
    ): void => {
      context.commit(Mutations.REMOVE_SELECTED_REFUNDS, payload)
    },
    [Actions.OPEN_CREDIT_CARD_MODAL]: (
      context: ActionContext<RefundB2EListState, RootState>
    ): Promise<TipoStornoEnum | undefined> => {
      return new Promise((resolve) => {
        const instance = modalController.open({
          type: ModalTypes.INFO,
          component: 'FeModalCreditCard',
          title: i18n.global.t('pos_common.attention'),
          message: i18n.global.t('pos_returns.modal_credit_card_message'),
          confirmActionButton: false,
          customClass: 'fe-custom-modal-returns',
          customFooter: true,
          customProps: {
            refundType: 'B2EREFUNDS',
          },
          customEventHandlers: {
            returnTransaction: () => resolve(TipoStornoEnum.Storno),
            creditAmount: () => resolve(TipoStornoEnum.Accredito),
          },
          closedAction: () => resolve(undefined),
        })
        context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, instance)
      })
    },
    [Actions.CLOSE_CREDIT_CARD_MODAL]: (
      context: ActionContext<RefundB2EListState, RootState>
    ): void => {
      if (context.state.modalCreditCardInstance) {
        context.state.modalCreditCardInstance.close()
        context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, undefined)
      }
    },
    [Actions.SET_INSERT_CREDIT_CARD]: (
      context: ActionContext<RefundB2EListState, RootState>,
      payload: boolean | undefined
    ): void => {
      context.commit(Mutations.SET_INSERT_CREDIT_CARD, payload)
    },
  },
  getters: {
    [Getters.GET_REFUND_B2E_LIST]: (state: RefundB2EListState) =>
      state.refundB2EList,
    [Getters.GET_FILTERS]: (state: RefundB2EListState) => {
      return state.filters
    },
    [Getters.IS_LOADING_DATA]: (state: RefundB2EListState) => state.isLoading,
    [Getters.GET_SELECTED_REFUNDS]: (state: RefundB2EListState) =>
      state.refundsSelected,
    [Getters.GET_CASHIER]: (state: RefundB2EListState): any =>
      state.cod_cassiera,
    [Getters.GET_INSERT_CREDIT_CARD]: (
      state: RefundB2EListState
    ): boolean | undefined => state.insertCreditCard,
    [Getters.GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION]: (
      state: RefundB2EListState
    ): boolean | undefined => state.handleOpenCreditCard,
    [Getters.GET_TOTALE_PRICE_REFUND_SELECTED]: (
      state: RefundB2EListState
    ): number => {
      return (
        state.refundsSelected?.reduce(
          (pr, cur) => pr + (cur?.importo_rimborsabile ?? 0),
          0
        ) || 0
      )
    },
  },
}

export default refundB2EListStore

export const RefundB2EListActions = {
  RESET_STATE: `refundB2EList/${Actions.RESET_STATE}`,
  RESET_FILTER: `refundB2EList/${Actions.RESET_FILTER}`,
  SET_FILTER_VALUE: `refundB2EList/${Actions.SET_FILTER_VALUE}`,
  SEARCH_REFUND_B2E: `refundB2EList/${Actions.SEARCH_REFUND_B2E}`,
  ADD_SELECTED_REFUNDS: `refundB2EList/${Actions.ADD_SELECTED_REFUNDS}`,
  REMOVE_SELECTED_REFUNDS: `refundB2EList/${Actions.REMOVE_SELECTED_REFUNDS}`,
  RESET_SELECTED_REFUNDS: `refundB2EList/${Actions.RESET_SELECTED_REFUNDS}`,
  SET_CASHIER: `refundB2EList/${Actions.SET_CASHIER}`,
  OPEN_CREDIT_CARD_MODAL: `refundB2EList/${Actions.OPEN_CREDIT_CARD_MODAL}`,
  CLOSE_CREDIT_CARD_MODAL: `refundB2EList/${Actions.CLOSE_CREDIT_CARD_MODAL}`,
  SET_INSERT_CREDIT_CARD: `refundB2EList/${Actions.SET_INSERT_CREDIT_CARD}`,
}

export const RefundB2EListGetters = {
  GET_REFUND_B2E_LIST: `refundB2EList/${Getters.GET_REFUND_B2E_LIST}`,
  GET_FILTERS: `refundB2EList/${Getters.GET_FILTERS}`,
  IS_LOADING_DATA: `refundB2EList/${Getters.IS_LOADING_DATA}`,
  GET_CASHIER: `refundB2EList/${Getters.GET_CASHIER}`,
  GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION: `refundB2EList/${Getters.GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION}`,
  GET_TOTALE_PRICE_REFUND_SELECTED: `refundB2EList/${Getters.GET_TOTALE_PRICE_REFUND_SELECTED}`,
  GET_SELECTED_REFUNDS: `refundB2EList/${Getters.GET_SELECTED_REFUNDS}`,
  GET_INSERT_CREDIT_CARD: `refundB2EList/${Getters.GET_INSERT_CREDIT_CARD}`,
}

export const RefundB2EListMutations = {
  RESET_STATE: `refundB2EList/${Mutations.RESET_STATE}`,
  RESET_SELECTED_REFUNDS: `refundB2EList/${Mutations.RESET_SELECTED_REFUNDS}`,
  SET_FILTER_VALUE: `refundB2EList/${Mutations.SET_FILTER_VALUE}`,
  SET_CASHIER: `refundB2EList/${Mutations.SET_CASHIER}`,
}
