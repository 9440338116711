import { ActionContext } from 'vuex'
import store, { RootState } from '.'
import { toolsApi } from '@/addons/axios'
import {
  StrumentiTestKubeDataAttributes,
  StrumentiSetKubeDateDataAttributes,
  StrumentiResponseSezioniAbilitateDataAttributes,
  StrumentiForceCashClosure,
  StrumentiResponseForceCashClosure,
  StrumentiEsitoInvioComandiStampanteDataAttributes,
  StrumentiEsitoStampaTotaliDataAttributes,
  ApiV1SessionsDataAttributes,
  GetCashesData,
  StrumentiStampaChiusuraFallitaDataAttributes,
  StrumentiConcessioneAutorizzazioneDataAttributes,
  StrumentiControlloCodiceAutorizzazione,
  StrumentiControlloAutorizzazioneDataAttributes,
  StrumentiTestKubeDataAttributesStatusEnum,
  StrumentiChangeDate,
  ApiV1StrumentiChangeDateDataAttributesModeEnum,
  StrumentiStampaTotaliPos,
} from '@/api'
import { extractErrorMessage, parseHTML } from '@/addons/functions'
import { AuthActions } from '@/store/auth'
import { ToolsAdvanceConfigurationStatus } from '@/addons/enums'
import { NotificationsActions } from '@/store/notifications-store'

enum Mutations {
  SET_PERMISSIONS = 'setPermissions',
}

export interface RequestAdvancedPermission {
  numeroIniziale: string
  codice: string
}
enum Actions {
  RESET_STATE = 'resetState',
  CASH_STATUS = 'cashStatus',
  ADJUST_DATE_TIME = 'adjustDateTime',
  LOAD_TOOLS_PERMISSIONS = 'loadToolPermissions',
  CLOSE_CASH_REGISTER = 'closeCashRegister',
  RUN_PRINTER_COMMAND = 'runPrinterCommand',
  CLOSE_CASH = 'closeCash',
  TEST_POS = 'testPos',
  CANCEL_SID = 'cancelSID',
  GET_ADVANCED_CODE = 'getAdvancedCode',
  REQUEST_ADVANCED_PERMISSION = 'getAdvancedPermission',
  TOGGLE_CASH_REGISTER = 'toggleCashRegister',
  CHANGE_CUSTOM_DATE = 'changeCustomDate',
  RESET_CUSTOM_DATE = 'resetCustomDate',
}

export const ToolsActions = {
  RESET_STATE: `tools/${Actions.RESET_STATE}`,
  CASH_STATUS: `tools/${Actions.CASH_STATUS}`,
  ADJUST_DATE_TIME: `tools/${Actions.ADJUST_DATE_TIME}`,
  LOAD_TOOLS_PERMISSIONS: `tools/${Actions.LOAD_TOOLS_PERMISSIONS}`,
  CLOSE_CASH_REGISTER: `tools/${Actions.CLOSE_CASH_REGISTER}`,
  RUN_PRINTER_COMMAND: `tools/${Actions.RUN_PRINTER_COMMAND}`,
  CLOSE_CASH: `tools/${Actions.CLOSE_CASH}`,
  TEST_POS: `tools/${Actions.TEST_POS}`,
  CANCEL_SID: `tools/${Actions.CANCEL_SID}`,
  GET_ADVANCED_CODE: `tools/${Actions.GET_ADVANCED_CODE}`,
  REQUEST_ADVANCED_PERMISSION: `tools/${Actions.REQUEST_ADVANCED_PERMISSION}`,
  TOGGLE_CASH_REGISTER: `tools/${Actions.TOGGLE_CASH_REGISTER}`,
  CHANGE_CUSTOM_DATE: `tools/${Actions.CHANGE_CUSTOM_DATE}`,
  RESET_CUSTOM_DATE: `tools/${Actions.RESET_CUSTOM_DATE}`,
}

enum Getters {
  GET_PERMISSION = 'get-permission',
}
export const ToolsGetters = {
  GET_PERMISSION: `tools/${Getters.GET_PERMISSION}`,
}

export enum CashStatusType {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}
export interface AuthState {
  isAuthenticated: boolean
  sid?: string | null
  user?: ApiV1SessionsDataAttributes
  cashes: GetCashesData[]
}
export interface ToolsState {
  testResults: []
  permissions: StrumentiResponseSezioniAbilitateDataAttributes | null
}

const initState = (): ToolsState => ({
  testResults: [],
  permissions: null,
})

const tools = {
  namespaced: true,
  state: initState,
  mutations: {
    resetState: (state: ToolsState): void => {
      Object.assign(state, initState())
    },
    [Mutations.SET_PERMISSIONS]: (
      state: ToolsState,
      payload: StrumentiResponseSezioniAbilitateDataAttributes
    ) => {
      state.permissions = payload
    },
  },
  actions: {
    [Actions.CASH_STATUS]: async (): Promise<
      StrumentiTestKubeDataAttributes | undefined
    > => {
      try {
        return (await toolsApi.apiV1StrumentiTestKubePost()).data.data
          ?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          status: StrumentiTestKubeDataAttributesStatusEnum.Error,
          message: message,
        } as StrumentiTestKubeDataAttributes
      }
    },
    [Actions.ADJUST_DATE_TIME]: async (): Promise<
      StrumentiSetKubeDateDataAttributes | undefined
    > => {
      try {
        return (await toolsApi.apiV1StrumentiRegolaDataOraKubePost()).data.data
          ?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          status: 'ERROR',
          message: message,
        } as StrumentiSetKubeDateDataAttributes
      }
    },
    [Actions.CLOSE_CASH_REGISTER]: async (): Promise<
      StrumentiStampaChiusuraFallitaDataAttributes | undefined
    > => {
      try {
        return (await toolsApi.apiV1StrumentiStampaChiusuraRegistratorePost())
          .data.data?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          status: 'ERROR',
          message: message,
        } as StrumentiStampaChiusuraFallitaDataAttributes
      }
    },
    [Actions.RUN_PRINTER_COMMAND]: async (
      context: ActionContext<ToolsState, RootState>,
      text: string
    ): Promise<
      StrumentiEsitoInvioComandiStampanteDataAttributes | undefined
    > => {
      try {
        return (
          await toolsApi.apiV1StrumentiSendCommandToCustomPrinterPost({
            data: {
              attributes: {
                testo: text,
              },
            },
          })
        ).data.data?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          status: 'ERROR',
          message: message,
        } as StrumentiEsitoInvioComandiStampanteDataAttributes
      }
    },
    [Actions.TEST_POS]: async (): Promise<
      StrumentiEsitoStampaTotaliDataAttributes | undefined
    > => {
      try {
        const payload: StrumentiStampaTotaliPos = {
          data: {
            type: 'stampa-totali-pos',
            attributes: {
              pos_transaction_id: `TEST_POS_${new Date().getTime().toString()}`,
            },
          },
        }
        return (await toolsApi.apiV1StrumentiStampaTotaliPosPost(payload)).data
          .data?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          status: 'ERROR',
          message: message,
        } as StrumentiEsitoStampaTotaliDataAttributes
      }
    },
    [Actions.CLOSE_CASH]: async (
      context: ActionContext<AuthState, RootState>,
      payload: StrumentiForceCashClosure
    ): Promise<StrumentiResponseForceCashClosure | undefined> => {
      try {
        const res = await toolsApi.apiV1StrumentiForceCashClosurePost(payload)
        await context.dispatch(AuthActions.FETCH_CASHES, true, { root: true })
        return res.data
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          status: 'ERROR',
          message: message,
        } as StrumentiResponseForceCashClosure
      }
    },
    [Actions.LOAD_TOOLS_PERMISSIONS]: async (
      context: ActionContext<ToolsState, RootState>
    ): Promise<void> => {
      context.commit(
        Mutations.SET_PERMISSIONS,
        (await toolsApi.apiV1StrumentiSezioniAbilitateGet()).data.data
          ?.attributes
      )
    },
    [Actions.GET_ADVANCED_CODE]: async (): Promise<
      StrumentiConcessioneAutorizzazioneDataAttributes | undefined
    > => {
      try {
        return (await toolsApi.apiV1StrumentiGetCodiceAutorizzazionePost()).data
          .data?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          message: message,
        } as StrumentiConcessioneAutorizzazioneDataAttributes
      }
    },
    [Actions.REQUEST_ADVANCED_PERMISSION]: async (
      context: ActionContext<ToolsState, RootState>,
      payload: RequestAdvancedPermission
    ): Promise<StrumentiControlloAutorizzazioneDataAttributes | undefined> => {
      try {
        const codice: StrumentiControlloCodiceAutorizzazione = {
          data: {
            attributes: {
              codice_autorizzazione: payload.codice,
              numero_iniziale: payload.numeroIniziale,
            },
          },
        }
        return (
          await toolsApi.apiV1StrumentiCheckCodiceAutorizzazionePost(codice)
        ).data.data?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          message: message,
        }
      }
    },
    [Actions.TOGGLE_CASH_REGISTER]: async (
      context: ActionContext<ToolsState, RootState>,
      payload: RequestAdvancedPermission
    ): Promise<StrumentiControlloAutorizzazioneDataAttributes | undefined> => {
      try {
        const codice: StrumentiControlloCodiceAutorizzazione = {
          data: {
            attributes: {
              codice_autorizzazione: payload.codice,
              numero_iniziale: payload.numeroIniziale,
            },
          },
        }
        return (
          await toolsApi.apiV1StrumentiCheckCodiceAutorizzazionePost(codice)
        ).data.data?.attributes
      } catch (error: any) {
        let message = ''
        if (error?.response?.data?.errors?.length) {
          message = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
        }
        return {
          message: message,
        }
      }
    },
    [Actions.RESET_STATE]: (
      context: ActionContext<ToolsState, RootState>
    ): void => {
      context.commit('resetState')
    },
    [Actions.CANCEL_SID]: () => {
      return toolsApi.apiV1StrumentiRemovalSidPost()
    },
    [Actions.CHANGE_CUSTOM_DATE]: async (
      context: ActionContext<ToolsState, RootState>,
      payload: string
    ): Promise<boolean | string> => {
      try {
        const payloadRequest: StrumentiChangeDate = {
          data: {
            type: 'change-date',
            attributes: {
              mode: ApiV1StrumentiChangeDateDataAttributesModeEnum.Modify,
              custom_date: payload,
            },
          },
        }
        const r = await toolsApi.apiV1StrumentiChangeDatePost(payloadRequest)
        if (
          r.data?.data?.attributes?.status ===
          ToolsAdvanceConfigurationStatus.ERROR
        ) {
          await store.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            r.data?.data?.attributes?.message
          )
          return false
        }
        await store.dispatch(
          NotificationsActions.NOTIFY_SUCCESS,
          parseHTML(r.data?.data?.attributes?.message || '')
        )
        return r.data?.data?.attributes?.message || ''
      } catch (e) {
        return false
      }
    },
    [Actions.RESET_CUSTOM_DATE]: async (): Promise<boolean | string> => {
      try {
        const payloadRequest: StrumentiChangeDate = {
          data: {
            type: 'change-date',
            attributes: {
              mode: ApiV1StrumentiChangeDateDataAttributesModeEnum.Reset,
            },
          },
        }
        const r = await toolsApi.apiV1StrumentiChangeDatePost(payloadRequest)
        await store.dispatch(
          NotificationsActions.NOTIFY_SUCCESS,
          parseHTML(r.data?.data?.attributes?.message || '')
        )
        return r.data?.data?.attributes?.message || ''
      } catch (e) {
        return false
      }
    },
  },
  getters: {
    [Getters.GET_PERMISSION]: (
      state: ToolsState
    ): StrumentiResponseSezioniAbilitateDataAttributes | null => {
      if (state.permissions) {
        return state.permissions
      }
      return {}
    },
  },
}

export default tools
