import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import { RootState } from '..'
import { TypedMapActions, TypedMapMutations, TypedStateStore } from '../_types'
import { ActionNames, Actions, actions } from './_actions'
import { GetterNames, Getters, getters } from './_getters'
import { Mutations, mutations } from './_mutations'
import { State, initState } from './_state'

const RFIDState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'RFID'

export type RFIDStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const RFIDActions = {
  RESET_RFID: `${NAMESPACE}/${ActionNames.RESET_RFID}`,
  ENABLE_RFID: `${NAMESPACE}/${ActionNames.ENABLE_RFID}`,
  DISABLE_RFID: `${NAMESPACE}/${ActionNames.DISABLE_RFID}`,
  OPEN_POPOVER: `${NAMESPACE}/${ActionNames.OPEN_POPOVER}`,
  CLOSE_POPOVER: `${NAMESPACE}/${ActionNames.CLOSE_POPOVER}`,
  RESET_STATE: `${NAMESPACE}/${ActionNames.RESET_STATE}`,
} as const

export const RFIDGetters = {
  IS_ENABLED: `${NAMESPACE}/${GetterNames.IS_ENABLED}`,
  POPOVER_IS_VISIBLE: `${NAMESPACE}/${GetterNames.POPOVER_IS_VISIBLE}`,
  IS_SEARCHING: `${NAMESPACE}/${GetterNames.IS_SEARCHING}`,
} as const

export const RFIDMutations = {} as const

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_actions'
export * from './_getters'
export * from './_mutations'
export type { State as RFIDState } from './_state'
export default RFIDState
