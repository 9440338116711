import store from '@/store'

export function roundDecimals(toRound: number): number {
  const decimalRounding =
    store.state.configs.currentStore?.DECIMAL_PLACES_ROUNDING || '0'
  const isNegative = toRound < 0
  const result =
    Math.ceil(
      parseFloat(
        (
          Math.abs(toRound) * Math.pow(10, parseInt(decimalRounding, 10))
        ).toFixed(5)
      )
    ) / Math.pow(10, parseInt(decimalRounding, 10))
  return isNegative ? -result : result
}

/**
 * Given a number, this function returns a number that has been truncated,
 * while preserving a certain number of decimals digits.
 * @param value Value to truncate
 * @deprecated since v1.4.0.08-1
 */
export function toFixed(value: number | string): number {
  const decimalPlaces = parseInt(
    store.state.configs.currentStore?.CURRENCY_DECIMAL || '2',
    10
  )
  const factor = Math.pow(10, decimalPlaces)
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value

  return Math.trunc(parsedValue * factor) / factor
}

/**
 * @deprecated since v1.4.0.08-5
 */
export function formatNumber(value: number): string {
  const decNum =
    store.state.configs.currentStore?.CURRENCY_DECIMAL ??
    store.state.configs.currentStore?.DECIMAL_PLACES_ROUNDING ??
    '0'
  const nDigits = parseInt(decNum, 10) || 0

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: nDigits,
    maximumFractionDigits: nDigits,
  })
  const thousandSep = store?.state?.configs?.currentStore?.THOUSAND_SEPARATOR
  const decimalSep = store?.state?.configs?.currentStore?.DECIMAL_SEPARATOR

  const parts = formatter.formatToParts(toFixed(value))
  const result = parts.reduce((p: string, c) => {
    switch (c.type) {
      case 'decimal':
        return p + decimalSep
      case 'group':
        return p + thousandSep
      default:
        return p + c.value
    }
  }, '')

  return result
}

export function unformatNumber(value: string): number {
  const thousandSep =
    store?.state?.configs?.currentStore?.THOUSAND_SEPARATOR || ''
  const decimalSep =
    store?.state?.configs?.currentStore?.DECIMAL_SEPARATOR || '.'

  const replaced = value.replaceAll(thousandSep, '').replace(decimalSep, '.')
  return parseFloat(replaced)
}

export function formatNumero(
  numPar: number | string,
  num_dec?: string
): string {
  if (typeof numPar !== 'string' && typeof numPar !== 'number') {
    return ''
  }
  let num = numPar.toString()
  const currency_decimals =
    store.state.configs.currentStore?.CURRENCY_DECIMAL || ''

  if (num !== '') {
    const sep_char = store?.state?.configs?.currentStore?.THOUSAND_SEPARATOR
    const sep_dec = store?.state?.configs?.currentStore?.DECIMAL_SEPARATOR

    let decimals
    if (!num_dec) {
      const parseIntDec = parseInt(num_dec, 10)
      decimals = isNaN(parseIntDec)
        ? parseInt(currency_decimals, 10)
        : parseIntDec
    } else {
      decimals = parseInt(currency_decimals, 10)
    }
    const reg_exp_f =
      decimals !== 0
        ? `^(-)?((\\d{1,3}(\\${sep_char}\\d{3})*)|(\\d+))\\${sep_dec}\\d{${decimals}}$`
        : `^(-)?(\\d{1,3}(\\${sep_char}\\d{3})*)$`
    const re_f = new RegExp(reg_exp_f)
    const test_f = re_f.test(num)

    if (test_f) {
      return num
    }
    const reg_exp = /^(-)?\d+(\.\d+|\d*)?$/
    if (reg_exp.test(num)) {
      num = parseFloat(num).toFixed(decimals).toString()
      const splitStr = num.split('.')
      let splitLeft = splitStr[0]
      const splitRight = splitStr[1] ?? ''
      const regx = /(-?\d+)(\d{3})/
      while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + sep_char + '$2')
      }
      return decimals !== 0
        ? splitLeft + sep_dec + splitRight.padEnd(decimals, '0')
        : splitLeft
    }
    return ''
  }
  return num
}
