<script lang="ts">
import FeIcon from '@/components/global/icon.vue'
import { IonButton } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { ContextPageGetters } from '@/store/context-page/context-page'

export default defineComponent({
  name: 'FeButton',

  components: {
    IonButton,
    FeIcon,
  },

  props: {
    /* eslint-disable vue/require-default-prop */
    // @TODO set default value
    icon: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    disabled: {
      type: [Boolean, String],
      default: false,
    },

    color: {
      type: String,
      default: 'shadow',
      validator: (value: string) => {
        return [
          'base',
          'primary',
          'shadow',
          'border',
          'warn',
          'icon',
          'medium',
          'info',
        ].includes(value)
      },
    },

    fill: {
      type: String,
      default: 'clear',
      validator: (value: string) => {
        return ['default', 'clear', 'outline', 'solid'].includes(value)
      },
    },

    type: {
      type: String,
      default: 'button',
    },
  },

  emits: ['click'],

  computed: {
    ...mapGetters({
      getContextPage: ContextPageGetters.GET_CONTEXT_PAGE,
    }),
    castedFill() {
      return this.fill as 'default' | 'clear' | 'outline' | 'solid'
    },
    castedType() {
      return this.type as 'button' | 'submit' | 'reset'
    },
    isDisabled(): boolean {
      return this.disabled === true || this.disabled === 'true'
    },
    context(): string {
      if (this.color === 'primary') {
        const context = this.getContextPage as string | undefined
        return context?.toLowerCase() || ''
      }
      return ''
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
})
</script>

<template>
  <ion-button
    :type="castedType"
    :class="color"
    :disabled="isDisabled"
    :color="context"
    :fill="castedFill"
    @click="onClick"
  >
    <slot name="icon">
      <fe-icon v-if="icon" :icon="icon"></fe-icon>
    </slot>

    <slot>
      <span v-if="label && color !== 'icon'">{{ label }}</span>
    </slot>

    <slot name="icon-right"></slot>
  </ion-button>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';

$context-colors: (
  'frontoffice': #{$color-frontoffice},
  'backoffice': #{$color-backoffice},
  'stock': #{$color-stock},
  'custom_date': #{$color-custom-date},
);

$context-colors-dark: (
  'frontoffice': #{$color-frontoffice-dark},
  'backoffice': #{$color-backoffice-dark},
  'stock': #{$color-stock-dark},
  'custom_date': #{$color-custom-date},
);

@mixin apply-color($context) {
  &.ion-color-#{$context} {
    --color: #{$color-white};
    --fill: #{$color-white};
    background-color: map-get($map: $context-colors, $key: $context);
    &:hover,
    &:active,
    &:focus {
      background-color: map-get($map: $context-colors-dark, $key: $context);
    }
  }
}

ion-button {
  --background: #{$color-white};
  --border-radius: 0.8rem;
  --border-width: 0.1rem;
  --border-style: solid;
  --box-shadow: none;
  --border-color: #{$color-black};
  --color: #{$color-black};
  --padding-top: 1rem;
  --padding-end: 1.5rem;
  --padding-bottom: 1rem;
  --padding-start: 1.5rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.4em;
  letter-spacing: 0.03em;
  border-radius: 0.8rem;

  &:disabled {
    opacity: 0.5;
  }

  &.primary {
    --border-style: none;
  }
  &.base {
    --border-style: none;
    --background: #{$color-base};
    --color: #{$color-white};
    &:hover,
    &:active,
    &:focus {
      --background: #{$color-dark-grey};
    }
  }

  &.shadow {
    --border-style: none;
    box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 22%);
    &:hover,
    &:active,
    &:focus {
      --background: #{$color-bg-hover-button};
    }
  }

  &.medium {
    --border-style: none;
    --background: #{$color-medium-grey};
    --color: #{$color-white};
    &:hover,
    &:active,
    &:focus {
      --background: #{$color-dark-grey};
    }
  }
  &.info {
    --border-style: none;
    --background: #{$color-selected-light};
    --color: #{$color-white};
    &:hover,
    &:active,
    &:focus {
      --background: #{$color-selected-light};
    }
  }

  &.border {
    --background: transparent;
    &:hover,
    &:active,
    &:focus {
      --background: #{$color-bg-hover-button-border};
    }
  }

  &.icon {
    --border-style: none;
    --background: transparent;

    --padding-start: 0;
    --padding-end: 0;
  }

  &.warn {
    --border-color: #{$color-error};
    --color: #{$color-error};
    --background: transparent;

    &:hover,
    &:active,
    &:focus {
      --background: #{$color-bg-hover-button-warn};
    }

    .fe-icon {
      fill: #{$color-error};
    }
  }

  :deep(.fe-icon) {
    width: 1.4rem;
    height: 1.4rem;

    &:deep(ion-icon) {
      height: 100%;
      width: 100%;
    }

    &:first-child {
      margin-right: 1.2rem;
    }

    &:last-child {
      margin-left: 1.2rem;
    }
  }

  @include apply-color('frontoffice');
  @include apply-color('backoffice');
  @include apply-color('stock');
  @include apply-color('custom_date');
}
</style>
