import { MutationTree } from 'vuex'
import { initState, State } from './_state'
import { SID_DURATION } from '@/configs'
import { setCookie } from '@/addons/functions'
import { CookieKeys } from '@/addons/enums'
import { ApiV1SessionsDataAttributes, GetCashesData } from '@/api'

export enum MutationNames {
  IS_AUTHENTICATED = 'setIsAuthenticated',
  SID = 'setSid',
  USER = 'setUser',
  CASHES = 'setCashes',
  RESET_STATE = 'resetState',
}

export type Mutations = {
  [MutationNames.IS_AUTHENTICATED](
    state: State,
    isAuthenticated: State['isAuthenticated']
  ): void

  [MutationNames.SID](state: State, sid: State['sid']): void

  [MutationNames.USER](state: State, user: State['user']): void

  [MutationNames.CASHES](state: State, cashes: State['cashes']): void

  [MutationNames.RESET_STATE](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationNames.IS_AUTHENTICATED](state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  },
  async [MutationNames.SID](state, sid) {
    state.sid = sid
    await setCookie(CookieKeys.SID, `sid=${sid}`, SID_DURATION)
  },

  [MutationNames.USER](state, user: ApiV1SessionsDataAttributes) {
    state.user = user
  },

  [MutationNames.CASHES](state, cashes: GetCashesData[]) {
    state.cashes = cashes
  },

  [MutationNames.RESET_STATE](state) {
    Object.assign(state, initState())
  },
}
