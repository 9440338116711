import { createApp } from 'vue'
import Modal from './modal.vue'
import i18n from '@/addons/i18n'
import store from '@/store'
import { TaxFreeMessages } from '@/interfaces/taxfree'
interface CustomButton {
  icons?: string
  color?: string
  label?: string
}

export interface ModalInterface {
  component?: string
  pathComponent?: any
  name?: string
  type?: string
  title: string | null
  closeIcon?: boolean
  message: string | null
  confirmLabel?: string
  confirmActionButton?: boolean
  noCancelButton?: boolean
  closedAction?: () => void
  confirmAction?: () => void
  customHeader?: boolean
  customFooter?: boolean
  customClass?: string
  teleportTo?: string
  customEventHandlers?: { [key: string]: (...args: any[]) => any }
  customProps?: { [key: string]: any }
  borderButtons?: string
  buttonAlign?: string
  textAlign?: string
  taxfreeMessages?: TaxFreeMessages
  customButtons?: {
    confirm?: CustomButton
    close?: CustomButton
  }
}

export enum ModalTypes {
  INFO = 'modal-info',
  WARNING = 'modal-warning',
  ERROR = 'modal-error',
  STEPPER = 'modal-stepper',
  BLACK = 'modal-black',
}
export default abstract class ModalController {
  private static isOpen = false

  private static toggleOpen(): void {
    ModalController.isOpen = !ModalController.isOpen
  }

  static open(modalOptions: ModalInterface) {
    if (!ModalController.isOpen) {
      ModalController.toggleOpen()

      const mountEl = document.createElement('div')
      mountEl.setAttribute('id', 'modal-container')
      document.body.appendChild(mountEl)

      const modal = createApp(
        { extends: Modal },
        {
          // props
          modal: {
            component: modalOptions.component,
            type: modalOptions.type,
            title: modalOptions.title,
            closeIcon: modalOptions.closeIcon ?? true,
            name: modalOptions.name,
            message: modalOptions.message,
            confirmLabel: modalOptions.confirmLabel,
            confirmActionButton: modalOptions.confirmActionButton,
            customHeader: modalOptions.customHeader,
            customFooter: modalOptions.customFooter,
            customClass: modalOptions.customClass,
            teleportTo: modalOptions.teleportTo,
            customEventHandlers: modalOptions.customEventHandlers,
            customProps: modalOptions.customProps,
            noCancelButton: modalOptions.noCancelButton,
            borderButtons: modalOptions.borderButtons,
            buttonAlign: modalOptions.buttonAlign,
            textAlign: modalOptions.textAlign ? modalOptions.textAlign : 'left',
            taxfreeMessages: modalOptions.taxfreeMessages,
            customButtons: modalOptions.customButtons,
          },
          // events are passed as props here with on[EventName]
          onClose() {
            close()
          },
          onHandleConfirmClick() {
            confirm()
          },
        }
      )
      const close = () => {
        if (modalOptions.closedAction) {
          modalOptions.closedAction()
        }
        mountEl.remove()
        modal.unmount()
        ModalController.toggleOpen()
      }
      const confirm = () => {
        if (modalOptions.confirmAction) {
          modalOptions.confirmAction()
        }
        mountEl.remove()
        modal.unmount()
        ModalController.toggleOpen()
      }
      if (modalOptions.pathComponent && modalOptions.component) {
        try {
          modal.component(modalOptions.component, modalOptions.pathComponent)
        } catch {
          close()
        }
      }
      modal.use(i18n).use(store)
      modal.mount(mountEl)
      return { close, confirm }
    }
  }
}
