import store from '@/store'
import { NotificationsActions } from '@/store/notifications-store'
import { I18n } from 'vue-i18n'
import { systemApi } from '../axios'
const labels = import.meta.glob('@/locales/**/*.json')

const COMMON_CLUSTER = 'pos_common'

/**
 * Given a locale id and the name of a group of translations, this function will
 * load the corresponding labels and set them in the received i18n instance.
 * Labels will be parsed and mapped to use the syntax from the i18n library.
 * @param i18nInstance The i18n instance we are working with
 * @param locale Id of the wanted language. E.g. `en`
 * @param moduleName Name of the group of labels to load.
 */
async function loadLocaleMessagesForLocale(
  i18nInstance: I18n,
  locale: string,
  moduleName: string
): Promise<void> {
  const localeModule: Record<string, string | Record<string, string>> = {}
  const messages = await systemApi.apiV1PoswebTranslationsGet(
    locale,
    moduleName
  )
  const labels = messages.data.data || []

  const translations: Record<string, string> = {}
  labels.forEach((label) => {
    if (label.attributes?.id) {
      const text =
        label.attributes?.translated_text ||
        label.attributes?.original_text ||
        ''
      const replaced = mapTokens(text)
      translations[label.attributes.id] = replaced
    }
  })

  localeModule[moduleName] = translations

  i18nInstance.global.mergeLocaleMessage(locale, localeModule)
}

/**
 * Given the name of a group of labels, this function will load those labels.
 * If something loading of those resources fails, this function will fallback to a secondary language.
 * @param i18nInstance The i18n instance we are working with
 * @param primaryLocale Language id of the current user
 * @param fallbackLocale A fallback language id
 * @param moduleName Name of the group of labels to load.
 */
export async function loadLocaleMessages(
  i18nInstance: I18n,
  primaryLocale: string,
  fallbackLocale: string,
  moduleName: string
): Promise<void> {
  // If we do not have already loaded labels for the primary locale, we try to load them.
  if (!hasBeenAlreadyLoaded(i18nInstance, primaryLocale, moduleName)) {
    try {
      await loadLocaleMessagesForLocale(i18nInstance, primaryLocale, moduleName)
    } catch (_error) {
      // If something goes wrong and we do not have fallback lables, we try to laod those for the fallback language.
      if (
        !hasBeenAlreadyLoaded(i18nInstance, fallbackLocale, moduleName) &&
        fallbackLocale !== primaryLocale
      ) {
        await loadLocaleMessagesForLocale(
          i18nInstance,
          fallbackLocale,
          moduleName
        )
      }
    }
  }
}

/**
 * Loads a series of labels.
 * It will ignore duplicate and any reference to the `COMMON_CLUSTER` group since those are automatically loaded.
 * @param i18nInstance The i18n instance we are working with
 * @param localesModules Name of the group of labels to load.
 */
export async function loadRouteMessages(
  i18nInstance: I18n,
  localesModules: string[] = []
): Promise<void> {
  const currentLocale = i18nInstance.global.locale as string
  const i18nFallbackLocale = i18nInstance.global.fallbackLocale as string

  // Filter out any additional reference to those labels defined in the `COMMON_CLUSTER` labels group.
  // We also remove any duplicate labels group, since we need to load them only once.
  const additionaLabelPromises = localesModules
    .filter(
      (cluster, index) =>
        cluster !== COMMON_CLUSTER && localesModules.indexOf(cluster) === index
    )
    .flatMap((cluster) =>
      loadLocaleMessages(
        i18nInstance,
        currentLocale,
        i18nFallbackLocale,
        cluster
      )
    )

  try {
    // Try to load them all.
    await Promise.all([
      loadRouteMessagesFromLocalJson(i18nInstance, ['common']),
      loadLocaleMessages(
        i18nInstance,
        currentLocale,
        i18nFallbackLocale,
        COMMON_CLUSTER
      ),
      ...additionaLabelPromises,
    ])
  } catch (error) {
    // If something goes wrong, we show an error message.
    store.dispatch(NotificationsActions.NOTIFY_ERROR, 'common.i18nError', {
      root: true,
    })
  }
}

export async function loadLocaleMessagesFromLocalJson(
  i18nInstance: I18n,
  locale: string,
  module: string
) {
  const localeModule: Record<string, string | Record<string, string>> = {}

  const key = Object.keys(labels)
    .filter((relativeFilePath) =>
      relativeFilePath.endsWith(`/locales/${module}/${locale}.json`)
    )
    .shift()

  if (!key) {
    return Promise.resolve()
  }

  const messages = (await labels[key]()) as { default: Record<string, string> }

  localeModule[module] = messages.default
  i18nInstance.global.mergeLocaleMessage(locale, localeModule)
}

export async function loadRouteMessagesFromLocalJson(
  i18nInstance: I18n,
  localesModules: string[] = []
) {
  const currentLocale = i18nInstance.global.locale as string
  const i18nFallbackLocale = i18nInstance.global.fallbackLocale as string

  try {
    await loadLocaleMessagesFromLocalJson(i18nInstance, currentLocale, 'common')
    if (currentLocale !== i18nFallbackLocale) {
      await loadLocaleMessagesFromLocalJson(
        i18nInstance,
        i18nFallbackLocale,
        'common'
      )
    }

    await Promise.all(
      localesModules.map(async (localeModule) => {
        await loadLocaleMessagesFromLocalJson(
          i18nInstance,
          currentLocale,
          localeModule
        )
        if (currentLocale !== i18nFallbackLocale) {
          await loadLocaleMessagesFromLocalJson(
            i18nInstance,
            i18nFallbackLocale,
            localeModule
          )
        }
      })
    )
  } catch (error) {
    store.dispatch(NotificationsActions.NOTIFY_ERROR, 'common.i18nError', {
      root: true,
    })
  }
}

/**
 * Performs a mapping from server side variable tokens to client side variable tokens.
 * Labels fetched from APIs could use either of these formats:
 *  - 'text <var>variable_name</var> some more text'
 *  - 'text VAR[varible_name] some more text'
 * Either way, this function will strip out '<var>' and '</var>' tags and replace them with
 * those used by the library that handle the i18n process. Same thing goes for those in the
 * 'VAR[variable_name]' notation.
 *
 * @example mapTokens('Unauthorized access to function: VAR[func_name]') -> 'Unauthorized access to function: {func_name}'
 * @example mapTokens('<var><b>VAR[0]</b></var>') -> '<b>{0}</b>'
 *
 * @param text Label on which we want to perform this mapping
 */
function mapTokens(text: string): string {
  let replaced = text.replace(/<var>/g, '').replace(/<\/var>/g, '')

  let hasVarPlaceholders = false

  do {
    const match = replaced.match(/VAR\[([a-zA-Z0-9_]*)\]/)
    hasVarPlaceholders = (match && match.length > 0) || false

    if (hasVarPlaceholders && match) {
      const placeholder = match[0]
      const variableName = match[1]

      replaced = replaced.replace(placeholder, '{' + variableName + '}')
    }
  } while (hasVarPlaceholders)

  return replaced
}

function hasBeenAlreadyLoaded(
  i18nInstance: I18n,
  lang: string,
  cluster: string
) {
  const messages = i18nInstance.global.messages as Record<
    string,
    Record<string, string>
  >
  const labels = messages[lang] || {}

  return typeof labels[cluster] !== 'undefined'
}
