<script lang="ts">
import { defineComponent } from 'vue'
import { IonToggle, IonLabel, IonItem, CheckboxCustomEvent } from '@ionic/vue'

export default defineComponent({
  name: 'FeToggle',

  components: { IonToggle, IonItem, IonLabel },

  props: {
    modelValue: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    label: String,

    disabled: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'change'],

  methods: {
    handleToggleChange(event: CheckboxCustomEvent): void {
      const eventTarget = event.target
      this.change(eventTarget.checked)
    },

    handleLabelClick(event: MouseEvent) {
      const toggle = (this.$refs.toggle as typeof IonToggle)?.$el
      if (event.target === toggle) {
        // Since user clicked on the toggle itself, handle the event as usual
        this.handleToggleChange(event as unknown as CheckboxCustomEvent)
      } else {
        this.change(!this.modelValue)
      }
    },

    change(value: boolean): void {
      this.$emit('change', value)
      this.$emit('update:modelValue', value)
    },
  },
})
</script>

<template>
  <ion-item @click.prevent="handleLabelClick">
    <slot v-if="reverse">
      <ion-label v-if="label && reverse">
        {{ label }}
      </ion-label>
    </slot>
    <ion-toggle
      ref="toggle"
      :class="{ right: !reverse && label }"
      :checked="modelValue"
      :disabled="disabled"
      @ion-change="handleToggleChange"
    />
    <slot v-if="!reverse">
      <ion-label v-if="label && !reverse">
        {{ label }}
      </ion-label>
    </slot>
  </ion-item>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';

ion-item {
  --inner-border-width: 0;
  border: none;
  display: flex;
  align-items: center;
  height: 3.5rem;
  margin-bottom: 0;
  flex-direction: row-reverse;
  max-width: 24rem;
}

ion-toggle {
  --background: #{$color-support-grey};
  --background-checked: #{$color-selected-bg};
  --handle-background: #{$color-base};
  --handle-background-checked: #{$color-selected-light};
  --handle-width: 2rem;
  --handle-height: 2rem;
  --handle-max-height: 2rem;
  --handle-spacing: 0;
  --padding-inline: 0;
  padding-left: 0;
  padding-right: 0;
  width: 3.6rem;
  min-width: 3.6rem;
  max-width: 3.6rem;
  height: 0.4rem;
  &.right {
    padding-right: 1.6rem;
  }
  // These properties fix an issue that resulted in toggles without the
  // "handle" part. Required only by WebKit.
  contain: none;
  overflow: visible;
}

ion-label {
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  flex: 0;
  flex-basis: auto;

  .toggle-checked + & {
    --color: #{$color-selected-light};
  }
}
</style>
