import { ActionContext, GetterTree } from 'vuex'
import { RootState } from '@/store/index'
import {
  ApiV1VersamentiAttributes,
  GetBancheAttributes,
  GetCashFlowStatusData,
  GetCashiersAttributes,
  GetMaxAmountAmlData,
  PostVersamenti,
} from '@/api'
import i18nInstance from '@/addons/i18n'
import { depositsApi, storeManagementApi } from '@/addons/axios'
import { NotificationsActions } from '@/store/notifications-store'
import PrintErrorHandler, {
  ErrorDisplayTypes,
} from '@/addons/functions/printErrorHandler'
import { formatToMMFGDate, fromHumanDateStringToDate } from '@/addons/date'

const createEmpty = (): ApiV1VersamentiAttributes => {
  return {
    data_valuta: undefined,
    importo: undefined,
    tipo_versamento: 'GENERICO',
  }
}

enum Mutations {
  RESET_STATE = 'resetState',
  STORE_DEPOSITS = 'storeDeposits',
  STORE_INITIAL_BALANCE = 'storeInitialBalance',
  SET_BANK = 'setBank',
  SET_CASHIER = 'setCashier',
  SET_DEPOSIT_DATE = 'setDepositDate',
  STORE_MAX_MONEY_AMOUNT = 'maxMoneyAmount',
  SET_CONFIRM_DEPOSIT = 'depositConfirmed',
  SET_PRINTED_DEPOSIT = 'printedDeposit',
}

enum Actions {
  CONFIRM_DEPOSIT = 'confirmDeposit',
  PRINT_DEPOSIT = 'printDeposit',
  ADD_DEPOSIT = 'addDeposit',
  REMOVE_DEPOSIT = 'removeDeposit',
  RESET_STATE = 'resetState',
  LOAD_INITIAL_BALANCE = 'loadInitialBalance',
  LOAD_MAX_MONEY_AMOUNT = 'loadMaxMoneyAmount',
}

enum Getters {
  GET_INITIAL_BALANCE = 'getInitialBalance',
  GET_DEPOSIT_TOTAL = 'getDepositTotal',
  GET_RESIDUAL_VALUE = 'getResidualValue',
  GET_DEPOSITS = 'getDeposits',
  CAN_MANAGE_DEPOSITS = 'canManageDepositos',
  CAN_SAVE_DEPOSITS = 'canSaveDeposits',
  GET_CASHIER = 'get-casgier',
  GET_BANK = 'get-bank',
  GET_DEPOSIT_DATE = 'get-deposit-date',
  GET_MAX_MONEY_AMOUNT = 'get-max-money-amount',
  GET_DEPOSIT_CONFIRMED = 'get-deposit-confirmed',
  GET_DEPOSIT_PRINTED = 'get-deposit-printed',
  GET_RESULT_DEPOSIT = 'get-result-deposit',
}

export interface CashMovementsState {
  /**
   * Deposits rows
   */
  deposits: ApiV1VersamentiAttributes[]
  /**
   * Saldo iniziale del negozio
   */
  initialBalance: number
  cashier: GetCashiersAttributes | undefined
  bank: GetBancheAttributes | undefined
  depositDate: string | undefined
  maxMoneyAmount: number
  depositConfirmed: boolean
  depositPrinted: boolean
  resultDeposit: any
}

let initState = (): CashMovementsState => ({
  deposits: [createEmpty()],
  initialBalance: 0,
  cashier: undefined,
  bank: undefined,
  depositDate: i18nInstance.global.d(new Date(), 'date'),
  maxMoneyAmount: 0,
  depositConfirmed: false,
  depositPrinted: false,
  resultDeposit: null,
})

const cashMovementsStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: CashMovementsState): void => {
      if (state.initialBalance !== 0) {
        initState = (): CashMovementsState => ({
          deposits: [createEmpty()],
          initialBalance: state.initialBalance,
          cashier: undefined,
          bank: undefined,
          depositDate: i18nInstance.global.d(new Date(), 'date'),
          maxMoneyAmount: 0,
          depositConfirmed: false,
          depositPrinted: false,
          resultDeposit: null,
        })
      }
      Object.assign(state, initState())
    },
    [Mutations.STORE_DEPOSITS]: (
      state: CashMovementsState,
      deposits: ApiV1VersamentiAttributes[]
    ): void => {
      state.deposits = [...deposits]
    },
    [Mutations.STORE_INITIAL_BALANCE]: (
      state: CashMovementsState,
      initialBalance: number
    ): void => {
      state.initialBalance = initialBalance
    },
    [Mutations.SET_BANK]: (
      state: CashMovementsState,
      bank: GetBancheAttributes
    ): void => {
      state.bank = bank
    },
    [Mutations.SET_CASHIER]: (
      state: CashMovementsState,
      cashier: GetCashiersAttributes
    ): void => {
      state.cashier = cashier
    },
    [Mutations.SET_DEPOSIT_DATE]: (
      state: CashMovementsState,
      date: string
    ): void => {
      state.depositDate = date
    },
    [Mutations.STORE_MAX_MONEY_AMOUNT]: (
      state: CashMovementsState,
      maxMoneyAmount: number
    ): void => {
      state.maxMoneyAmount = maxMoneyAmount
    },
    [Mutations.SET_CONFIRM_DEPOSIT]: (
      state: CashMovementsState,
      resultDeposit
    ): void => {
      state.depositConfirmed = true
      state.resultDeposit = resultDeposit
    },
    [Mutations.SET_PRINTED_DEPOSIT]: (
      state: CashMovementsState,
      isDepositSuccessfullyPrinted: boolean
    ): void => {
      state.depositPrinted = isDepositSuccessfullyPrinted
    },
  },
  actions: {
    [Actions.LOAD_MAX_MONEY_AMOUNT]: async (
      context: ActionContext<CashMovementsState, RootState>
    ): Promise<void> => {
      const response = await depositsApi.apiV1VersamentiMaxAmountAmlGet()
      const data = response.data as GetMaxAmountAmlData
      context.commit(
        Mutations.STORE_MAX_MONEY_AMOUNT,
        data.attributes?.max_money_amount
      )
    },
    [Actions.LOAD_INITIAL_BALANCE]: async (
      context: ActionContext<CashMovementsState, RootState>
    ): Promise<void> => {
      const response =
        await storeManagementApi.apiV1PoswebCashesCashIdStatusGet(
          context.rootState.configs.setup?.cash_id || ''
        )
      const data = response.data as GetCashFlowStatusData[]
      const initalBalance = data.find((t) => t.type === 'store_cash_flow')
        ?.attributes?.saldo_attuale
      context.commit(Mutations.STORE_INITIAL_BALANCE, initalBalance)
    },
    [Actions.RESET_STATE]: (
      context: ActionContext<CashMovementsState, RootState>
    ): void => {
      context.commit(Mutations.RESET_STATE)
      context.dispatch(Actions.LOAD_INITIAL_BALANCE)
    },
    [Actions.CONFIRM_DEPOSIT]: async (
      context: ActionContext<CashMovementsState, RootState>
    ): Promise<void> => {
      const depositsList = context.state.deposits
        .filter((el) => el.data_valuta && el.importo && el.tipo_versamento)
        .map((element) => ({
          ...element,
          data_valuta: element.data_valuta?.replaceAll(/-/g, ''),
          importo: parseFloat(element.importo?.toString() || '0'),
          banca: context.state.bank?.codice,
          cassiera: context.state.cashier?.venditrice,
          data_versamento: context.state.depositDate.replaceAll(/-/g, ''),
          residuo: context.getters[Getters.GET_RESIDUAL_VALUE],
          saldo_negozio: context.getters[Getters.GET_INITIAL_BALANCE],
          totale_versato: context.getters[Getters.GET_DEPOSIT_TOTAL],
        }))
      const postVersamenti: PostVersamenti = {
        data: depositsList.map((d) => ({
          id: undefined,
          type: 'versamento',
          attributes: {
            ...d,
            data_valuta: d.data_valuta
              ? formatToMMFGDate(fromHumanDateStringToDate(d.data_valuta))
              : undefined,
            data_versamento: d.data_versamento
              ? formatToMMFGDate(fromHumanDateStringToDate(d.data_versamento))
              : undefined,
          },
        })),
      }

      if (!context.state.depositConfirmed) {
        const result = await depositsApi.apiV1VersamentiPost(postVersamenti)
        if (result) {
          if (result.data?.errors?.length > 0) {
            result.data?.errors.forEach((el) => {
              context.dispatch(NotificationsActions.NOTIFY_ERROR, el.detail, {
                root: true,
              })
            })
          } else {
            context.commit(Mutations.SET_CONFIRM_DEPOSIT, result.data?.data)
          }
        }
      }
    },
    [Actions.PRINT_DEPOSIT]: async (
      context: ActionContext<CashMovementsState, RootState>,
      payload: object
    ): Promise<void> => {
      try {
        await depositsApi.apiV1StampaVersamentoPost({
          data: {
            type: 'versamento',
            attributes: {
              num_versamento: payload.numDocumento,
              anno: payload.annoVersamento,
            },
          },
        })
        context.commit(Mutations.SET_PRINTED_DEPOSIT, true)
      } catch (error: any) {
        await PrintErrorHandler(
          error?.response?.data,
          false,
          ErrorDisplayTypes.TOAST
        )
      }
    },
    [Actions.ADD_DEPOSIT]: (
      context: ActionContext<CashMovementsState, RootState>
    ): void => {
      context.commit(Mutations.STORE_DEPOSITS, [
        ...context.state.deposits,
        createEmpty(),
      ])
    },
    /**
     * @param context
     * @param index the index of the element in the array to remove
     */
    [Actions.REMOVE_DEPOSIT]: (
      context: ActionContext<CashMovementsState, RootState>,
      index: number
    ): void => {
      context.commit(Mutations.STORE_DEPOSITS, [
        ...context.state.deposits.filter((el, i) => i !== index),
      ])
    },
  },
  getters: {
    [Getters.CAN_MANAGE_DEPOSITS]: (state: CashMovementsState): boolean => {
      return !!state.cashier && state.depositDate !== ''
    },
    [Getters.GET_INITIAL_BALANCE]: (state: CashMovementsState): number => {
      return state.initialBalance
    },
    [Getters.GET_DEPOSIT_TOTAL]: (state: CashMovementsState): number => {
      return state.deposits.reduce((p, c) => p + (c.importo || 0), 0)
    },
    [Getters.GET_RESIDUAL_VALUE]: (
      state: CashMovementsState,
      getters: GetterTree<CashMovementsState, RootState>
    ): number => {
      return (
        state.initialBalance -
        (getters[Getters.GET_DEPOSIT_TOTAL] as unknown as number)
      )
    },
    [Getters.GET_DEPOSITS]: (
      state: CashMovementsState
    ): ApiV1VersamentiAttributes[] => {
      return state.deposits
    },
    [Getters.CAN_SAVE_DEPOSITS]: (state: CashMovementsState): boolean => {
      let result = state.deposits.length > 0
      state.deposits
        .filter(
          (element) =>
            element?.data_versamento &&
            element?.importo &&
            element?.tipo_versamento
        )
        .forEach((element) => {
          result =
            result &&
            element?.data_versamento !== '' &&
            element?.importo !== 0 &&
            element?.tipo_versamento !== ''
        })
      return result
    },
    [Getters.GET_CASHIER]: (
      state: CashMovementsState
    ): GetCashiersAttributes | undefined => state.cashier,
    [Getters.GET_BANK]: (
      state: CashMovementsState
    ): GetBancheAttributes | undefined => state.bank,
    [Getters.GET_DEPOSIT_DATE]: (
      state: CashMovementsState
    ): string | undefined => state.depositDate,
    [Getters.GET_MAX_MONEY_AMOUNT]: (state: CashMovementsState): number =>
      state.maxMoneyAmount,
    [Getters.GET_DEPOSIT_CONFIRMED]: (state: CashMovementsState): boolean =>
      state.depositConfirmed,
    [Getters.GET_DEPOSIT_PRINTED]: (state: CashMovementsState): boolean =>
      state.depositPrinted,
    [Getters.GET_RESULT_DEPOSIT]: (
      state: CashMovementsState
    ): ApiV1VersamentiAttributes[] => state.resultDeposit,
  },
}

export default cashMovementsStore

export const CashMovementsMutations = {
  RESET_STATE: `cashMovements/${Mutations.RESET_STATE}`,
  STORE_DEPOSITS: `cashMovements/${Mutations.STORE_DEPOSITS}`,
  STORE_INITIAL_BALANCE: `cashMovements/${Mutations.STORE_INITIAL_BALANCE}`,
  SET_PRINTED_DEPOSIT: `cashMovements/${Mutations.SET_PRINTED_DEPOSIT}`,
  SET_BANK: `cashMovements/${Mutations.SET_BANK}`,
  SET_CASHIER: `cashMovements/${Mutations.SET_CASHIER}`,
  SET_DEPOSIT_DATE: `cashMovements/${Mutations.SET_DEPOSIT_DATE}`,
}

export const CashMovementsActions = {
  CONFIRM_DEPOSIT: `cashMovements/${Actions.CONFIRM_DEPOSIT}`,
  PRINT_DEPOSIT: `cashMovements/${Actions.PRINT_DEPOSIT}`,
  ADD_DEPOSIT: `cashMovements/${Actions.ADD_DEPOSIT}`,
  REMOVE_DEPOSIT: `cashMovements/${Actions.REMOVE_DEPOSIT}`,
  RESET_STATE: `cashMovements/${Actions.RESET_STATE}`,
  LOAD_INITIAL_BALANCE: `cashMovements/${Actions.LOAD_INITIAL_BALANCE}`,
  LOAD_MAX_MONEY_AMOUNT: `cashMovements/${Actions.LOAD_MAX_MONEY_AMOUNT}`,
}

export const CashMovementsGetters = {
  GET_INITIAL_BALANCE: `cashMovements/${Getters.GET_INITIAL_BALANCE}`,
  GET_DEPOSIT_TOTAL: `cashMovements/${Getters.GET_DEPOSIT_TOTAL}`,
  GET_RESIDUAL_VALUE: `cashMovements/${Getters.GET_RESIDUAL_VALUE}`,
  GET_DEPOSIT_LIST: `cashMovements/${Getters.GET_DEPOSITS}`,
  CAN_MANAGE_DEPOSITS: `cashMovements/${Getters.CAN_MANAGE_DEPOSITS}`,
  CAN_SAVE_DEPOSITS: `cashMovements/${Getters.CAN_SAVE_DEPOSITS}`,
  GET_CASHIER: `cashMovements/${Getters.GET_CASHIER}`,
  GET_BANK: `cashMovements/${Getters.GET_BANK}`,
  GET_DEPOSIT_DATE: `cashMovements/${Getters.GET_DEPOSIT_DATE}`,
  GET_MAX_MONEY_AMOUNT: `cashMovements/${Getters.GET_MAX_MONEY_AMOUNT}`,
  GET_DEPOSIT_CONFIRMED: `cashMovements/${Getters.GET_DEPOSIT_CONFIRMED}`,
  GET_DEPOSIT_PRINTED: `cashMovements/${Getters.GET_DEPOSIT_PRINTED}`,
  GET_RESULT_DEPOSIT: `cashMovements/${Getters.GET_RESULT_DEPOSIT}`,
}
