import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import { RootState } from '..'
import { TypedMapActions, TypedMapMutations, TypedStateStore } from '../_types'
import { ActionNames, Actions, actions } from './_actions'
import { GetterNames, Getters, getters } from './_getters'
import { Mutations, mutations } from './_mutations'
import { State, initState } from './_state'

const SidebarState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'sidebarStore'

export type SidebarStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const SidebarActions = {
  SET_SIDEBAR_MENU: `${NAMESPACE}/${ActionNames.SET_SIDEBAR_MENU}`,
} as const

export const SidebarGetters = {
  GET_SIDEBAR_MENU: `${NAMESPACE}/${GetterNames.GET_SIDEBAR_MENU}`,
} as const

export const SidebarMutations = {} as const

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_actions'
export * from './_getters'
export * from './_mutations'
export * from './index.d'
export type { State as SidebarState } from './_state'
export default SidebarState
