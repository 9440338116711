import { MutationTree } from 'vuex'
import { State } from './_state'
import {
  GetAdvancedFiltersAttributes,
  GetBrandCategoriesAttributes,
  GetSkuDataAttributes,
  GetModelsAttributes,
} from '@/api'
import {
  StepPageE,
  SelectedFiltersItemE,
  SelectedFiltersPayloadE,
  FilterTypeMapE,
  GetSessionElementD,
  ActiveFilterModalE,
  FilterModalElementE,
} from './index.d'
import { mapFilterType } from '@/modules/endless-aisle/helpers/helpers'
import { LedWallEntitiesT, LedWallEntityD } from '@/modules/fling/index.d'
export enum MutationsNames {
  SHOW_ENDLESS_AISLE = 'show-endless-aisle',
  SET_OPENED_DRAWER_OR_MODAL = 'set-opened-drawer-or-modal',
  SET_OPENED_DRESSING_ROOM = 'set-opened-dressing-room',
  SET_SHOW_LOADER_FULL = 'set-show-loader-full',
  SET_SEARCHED_SKU_PLP = 'set-searched-sku-plp',
  SET_STEP_PAGE = 'set-step-page',
  SET_FILTERS = 'set-filters',
  SET_CATEGORIES = 'set-categories',
  SET_OPENED_FILTERS_MODAL = 'set-opened-filters-modal',
  SET_SELECTED_FILTERS = 'set-selected-filters',
  REMOVE_FILTER_TAG = 'remove-filter-tag',
  SET_OPENED_PRODUCT_INFO_MODAL = 'set-opened-product-info-modal',
  SET_ACTIVE_FILTER_MODAL = 'set-active-filter-modal',
  RESET_ACTIVE_FILTER_MODAL = 'reset-active-filter-modal',
  SET_APPLIED_FILTER_MODAL = 'set-applied-filters-modal',
  RESET_ENDLESS_AISLE = 'reset-endless-aisle',
  SET_DRESSING_ROOM_INFO = 'set-dressing-room-info',
  SET_ELEMENT_TO_SELL = 'set-element-to-sell',
  SET_CURRENT_PRODUCT = 'set-current-product',
  SET_PREV_PRODUCT = 'set-prev-product',
  SET_DRESSING_ROOM_ELEMENTS = 'set-dressing-room-elements',
  GET_TEMP_PRODUCTS = 'set-temp-product',
  SET_LEDWALL_INFO = 'set-ledwall-info',
  RESET_LEDWALL_INFO = 'reset-ledwall-info',
}

export type Mutations = {
  [MutationsNames.SHOW_ENDLESS_AISLE](state: State, payload: boolean): void
  [MutationsNames.SET_OPENED_DRAWER_OR_MODAL](
    state: State,
    payload: boolean
  ): void
  [MutationsNames.SET_OPENED_DRESSING_ROOM](
    state: State,
    payload: boolean
  ): void
  [MutationsNames.SET_SHOW_LOADER_FULL](state: State, payload: boolean): void
  [MutationsNames.SET_SEARCHED_SKU_PLP](
    state: State,
    Payload: GetSkuDataAttributes
  ): void
  [MutationsNames.SET_STEP_PAGE](state: State, payload: StepPageE): void
  [MutationsNames.SET_FILTERS](
    state: State,
    payload: Array<GetAdvancedFiltersAttributes>
  ): void
  [MutationsNames.SET_CATEGORIES](
    state: State,
    payload: Array<GetBrandCategoriesAttributes>
  ): void
  [MutationsNames.SET_OPENED_FILTERS_MODAL](
    state: State,
    payload: boolean
  ): void
  [MutationsNames.SET_SELECTED_FILTERS](
    state: State,
    payload: SelectedFiltersPayloadE
  ): void
  [MutationsNames.REMOVE_FILTER_TAG](
    state: State,
    payload: SelectedFiltersItemE
  ): void
  [MutationsNames.SET_OPENED_PRODUCT_INFO_MODAL](
    state: State,
    payload: boolean
  ): void
  [MutationsNames.SET_ACTIVE_FILTER_MODAL](
    state: State,
    payload: { context: string; remove: boolean; value: FilterModalElementE }
  ): void
  [MutationsNames.RESET_ACTIVE_FILTER_MODAL](state: State): void
  [MutationsNames.SET_APPLIED_FILTER_MODAL](
    state: State,
    payload: boolean
  ): void
  [MutationsNames.RESET_ENDLESS_AISLE](state: State): void
  [MutationsNames.SET_DRESSING_ROOM_INFO](
    state: State,
    payload: Array<GetSessionElementD>
  ): void
  [MutationsNames.SET_ELEMENT_TO_SELL](
    state: State,
    payload: Array<string>
  ): void
  [MutationsNames.SET_CURRENT_PRODUCT](
    state: State,
    payload: {
      product: GetModelsAttributes
      variant?: string
    }
  ): void
  [MutationsNames.SET_PREV_PRODUCT](
    state: State,
    payload: GetModelsAttributes
  ): void
  [MutationsNames.SET_DRESSING_ROOM_ELEMENTS](
    state: State,
    payload: Array<GetSessionElementD>
  ): void
  [MutationsNames.SET_LEDWALL_INFO](payload: {
    value: LedWallEntitiesT | LedWallEntityD
    context: string
  }): void
  [MutationsNames.RESET_LEDWALL_INFO](): void
}
export const mutations: MutationTree<State> = {
  [MutationsNames.SHOW_ENDLESS_AISLE](state, payload: boolean): void {
    state.showEndlessAisle = payload
  },
  [MutationsNames.SET_OPENED_DRAWER_OR_MODAL](state, payload: boolean): void {
    state.openedDrawerOrModal = payload
  },
  [MutationsNames.SET_OPENED_DRESSING_ROOM](state, payload: boolean): void {
    state.openedDressingRoom = payload
  },
  [MutationsNames.SET_SHOW_LOADER_FULL](state, payload: boolean): void {
    state.showLoaderFull = payload
  },
  [MutationsNames.SET_SEARCHED_SKU_PLP](
    state,
    payload: GetSkuDataAttributes
  ): void {
    state.searchedSkuPLP = payload
  },
  [MutationsNames.SET_STEP_PAGE](state, payload: StepPageE): void {
    state.stepPage = payload
  },
  [MutationsNames.SET_FILTERS](
    state,
    payload: Array<GetAdvancedFiltersAttributes>
  ): void {
    state.filters = payload
  },
  [MutationsNames.SET_CATEGORIES](
    state,
    payload: Array<GetBrandCategoriesAttributes>
  ): void {
    state.categories = payload
  },
  [MutationsNames.SET_OPENED_FILTERS_MODAL](state, payload: boolean): void {
    state.openedFiltersModal = payload
  },
  [MutationsNames.SET_SELECTED_FILTERS](
    state,
    payload: SelectedFiltersPayloadE
  ): void {
    if (payload.context === 'filters') {
      ;(state.selectedFilters['filters'] || []).push(
        payload.filters as SelectedFiltersItemE
      )
    } else {
      state.selectedFilters[payload.context] = payload.value
    }
  },
  [MutationsNames.REMOVE_FILTER_TAG](
    state,
    payload: SelectedFiltersItemE
  ): void {
    const currFilterI = state.selectedFilters?.filters?.indexOf(
      payload
    ) as number
    if (currFilterI >= 0) {
      state.selectedFilters.filters?.splice(currFilterI, 1)
    }
  },
  [MutationsNames.SET_OPENED_PRODUCT_INFO_MODAL](
    state,
    payload: boolean
  ): void {
    state.openedProductInfoModal = payload
  },
  [MutationsNames.SET_ACTIVE_FILTER_MODAL](
    state,
    payload: { context: string; remove: boolean; value: FilterModalElementE }
  ): void {
    const type = mapFilterType(payload.context)
    if (!payload.remove) {
      if (type === FilterTypeMapE.MULTISELECT) {
        const currContext = state.activeFiltersModal[payload.context]
        if (Array.isArray(currContext) && currContext) {
          ;(
            state.activeFiltersModal[
              payload.context
            ] as Array<FilterModalElementE>
          ).push(
            // @ts-ignore
            payload.value
          )
        } else {
          state.activeFiltersModal[payload.context] = [payload.value]
        }
      } else {
        state.activeFiltersModal[payload.context] = payload.value
      }
    } else {
      if (type === FilterTypeMapE.MULTISELECT) {
        const currContext = state.activeFiltersModal[payload.context]
        if (Array.isArray(currContext) && currContext) {
          const index = (
            state.activeFiltersModal[
              payload.context
            ] as Array<FilterModalElementE>
          ).indexOf(
            // @ts-ignore
            payload.value
          )
          ;(
            state.activeFiltersModal[
              payload.context
            ] as Array<ActiveFilterModalE>
          ).splice(index, 1)
          if (
            !(
              state.activeFiltersModal[
                payload.context
              ] as Array<ActiveFilterModalE>
            ).length
          ) {
            delete state.activeFiltersModal[payload.context]
          }
        }
      } else {
        delete state.activeFiltersModal[payload.context]
      }
    }
  },
  [MutationsNames.RESET_ACTIVE_FILTER_MODAL](state): void {
    state.activeFiltersModal = Object.assign({})
  },
  [MutationsNames.SET_APPLIED_FILTER_MODAL](state, payload: boolean): void {
    state.areAppliedFilters = payload
  },
  [MutationsNames.RESET_ENDLESS_AISLE](state): void {
    // state = { ...initState() }
    // TODO : reset properly
    state.stepPage = StepPageE.INTRO
  },
  [MutationsNames.SET_DRESSING_ROOM_INFO](
    state,
    payload: Array<GetSessionElementD>
  ): void {
    const quantityN = payload.reduce((acc, el) => {
      return acc + el.quantity
    }, 0)
    state.dressingRoomInfo = Object.assign(
      {},
      {
        totalQuantity: isNaN(quantityN) ? 0 : quantityN,
        skus: payload,
      }
    )
  },
  [MutationsNames.SET_ELEMENT_TO_SELL](state, payload: Array<string>): void {
    state.elementToSell = payload
  },
  [MutationsNames.SET_CURRENT_PRODUCT](
    state,
    payload: {
      product: GetModelsAttributes
      variant?: string
    }
  ): void {
    state.currentProduct = payload
  },
  [MutationsNames.SET_PREV_PRODUCT](state, payload: GetModelsAttributes): void {
    state.prevProduct = payload
  },
  [MutationsNames.SET_DRESSING_ROOM_ELEMENTS](
    state,
    payload: GetSessionElementD[]
  ): void {
    state.dressingRoomElements = payload
  },
  [MutationsNames.GET_TEMP_PRODUCTS](
    state,
    payload: Array<GetModelsAttributes>
  ): void {
    state.tempProducts = payload
  },
  [MutationsNames.SET_LEDWALL_INFO](
    state,
    payload: {
      value: LedWallEntitiesT | LedWallEntityD
      context: string
    }
  ): void {
    state.ledWallInfo[payload.context] = payload.value
  },
  [MutationsNames.RESET_LEDWALL_INFO](state): void {
    state.ledWallInfo = {
      list: [],
      selectedLed: {},
    }
  },
}
