import { ApiV1SessionsDataAttributes, GetCashesData } from '@/api'

export interface AuthCallPayload {
  username: string
  password: string
  cashCode: string
  secondFactor?: string
}

export interface State {
  isAuthenticated: boolean
  sid?: string | null
  user?: ApiV1SessionsDataAttributes
  cashes: GetCashesData[]
}
export const initState = (): State => ({
  isAuthenticated: false,
  sid: null,
  user: undefined,
  cashes: [],
})
