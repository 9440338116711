import { MutationTree } from 'vuex'
import { State } from './_state'
import { SidebarMenuItemD, MenuPartsE } from './index.d'
export enum MutationNames {
  SET_SIDEBAR_MENU = 'set-sidebar-menu',
}

export type Mutations = {
  [MutationNames.SET_SIDEBAR_MENU](
    state: State,
    payload: { context: MenuPartsE; item: SidebarMenuItemD }
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationNames.SET_SIDEBAR_MENU](state, payload) {
    state.sidebarMenu[payload?.context]?.push(payload.item)
  },
}
