<script lang="ts">
import { defineComponent } from 'vue'

export interface NavItem {
  name: string
  anchor: string
}

export default defineComponent({
  name: 'FeDrawerNav',

  props: {
    navItems: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-default-prop
    parentElement: {
      type: Object,
    },
    mode: {
      // 'anchor' or 'tab' -> "anchor" behaves like usual scrolling to anchor, "tab" acts as a tab switch
      type: String,
      default: 'anchor',
      validator(value: string) {
        return ['anchor', 'tab'].includes(value)
      },
    },
  },
  emits: ['change-tab'],

  data() {
    return {
      currentAnchor: '',
    }
  },

  computed: {
    scrollableElement(): Element | undefined {
      try {
        return document.querySelector('.drawer-content') || undefined
      } catch {
        return undefined
      }
    },

    allAnchors(): string[] {
      return (this.navItems as NavItem[]).map(
        (navItem: NavItem) => navItem.anchor
      )
    },

    anchorElements(): Element[] {
      if (!this.scrollableElement) {
        return []
      }
      const elementsWithId = this.scrollableElement.querySelectorAll('[id]')
      return [...elementsWithId].filter((element: Element) => {
        return this.allAnchors.indexOf(element.id) > -1
      })
    },
  },

  watch: {
    currentAnchor() {
      const selectedAnchorLink = this.$el.querySelector(
        `[data-anchor="${this.currentAnchor}"]`
      )
      if (selectedAnchorLink) {
        const linkPosition = selectedAnchorLink.offsetLeft
        const linkWidth = selectedAnchorLink.clientWidth
        const navWidth = this.$el.clientWidth

        this.$el.scrollTo(linkPosition - navWidth / 2 + linkWidth / 2, 0)
      }
    },
  },

  mounted() {
    if (!this.scrollableElement && this.mode === 'anchor') {
      return
    }
    this.currentAnchor = this.allAnchors[0]

    if (this.mode === 'anchor') {
      this.scrollableElement?.addEventListener(
        'scroll',
        this.handleParentScroll
      )
    }
  },

  unmounted() {
    if (!this.scrollableElement) {
      return
    }

    this.scrollableElement.removeEventListener(
      'scroll',
      this.handleParentScroll
    )
  },

  methods: {
    scrollTo(anchor: string): void {
      if (!this.scrollableElement) {
        return
      }

      let currentActiveElement = this.scrollableElement.querySelector(
        `#${anchor}`
      )
      if (!currentActiveElement) {
        return
      }
      this.scrollableElement.scrollTo({
        top: (currentActiveElement as HTMLElement).offsetTop - 50,
        behavior: 'smooth',
      })

      this.currentAnchor = anchor
    },

    handleParentScroll(event: Event): void {
      if (!this.anchorElements) {
        return
      }

      const scrolledElement = event?.target as Element
      const scrollTop = scrolledElement.scrollTop
      const scrollableElementHalfHeight = scrolledElement.clientHeight * 0.45
      const inViewScrollPosition = scrollTop + scrollableElementHalfHeight
      let anchorInView = ''

      this.anchorElements.forEach((anchorElement: Element) => {
        if ((anchorElement as HTMLElement).offsetTop < inViewScrollPosition) {
          anchorInView = anchorElement.id
        }
      })

      this.currentAnchor = anchorInView
    },

    handleClick(anchor: string): void {
      if (this.mode === 'tab') {
        this.currentAnchor = anchor
        this.$emit('change-tab', anchor)
      } else {
        this.scrollTo(anchor)
      }
    },
  },
})
</script>

<template>
  <nav>
    <a
      v-for="(navItem, navItemIndex) in navItems"
      :key="navItemIndex"
      :data-anchor="navItem.anchor"
      class="link"
      :class="{ 'link--active': navItem.anchor === currentAnchor }"
      @click.prevent="handleClick(navItem.anchor)"
    >
      <span>
        {{ $t(navItem.name) }}
      </span>
      <div v-if="navItem.counter" class="circle-notification">
        {{ navItem.counter }}
      </div>
    </a>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
.notifications__nav .link {
  display: flex;
  align-items: center;
}

.link--active .circle-notification {
  background: $color-selected-light;
}

.circle-notification {
  background: $color-base;
  min-height: 2.5rem;
  min-width: 2.5rem;
  color: $color-white;
  margin-left: 1rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav {
  display: flex;
  align-items: center;
  overflow-x: auto;
  background: $color-light-grey;
  scrollbar-width: none;
  border-bottom: 0.1rem solid $color-mid-grey;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  display: inline-block;
  padding: 1.2rem 4rem;
  margin: 0;
  color: $color-base;
  border-bottom: 0.4rem solid;
  border-bottom-color: transparent;
  cursor: pointer;

  &--active {
    color: $color-selected-light;
    border-bottom-color: $color-selected-light;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
