/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ActionResult,
  DatiCorrezioneStatoItemRfid,
  ListaDeiCapi,
  ListaDeiCapi1,
  ListaDeiCapiData,
  ListaDeiNuoviTotaliTransazione,
  RFIDApiFactory,
} from '@/api'
import { AxiosPromise } from 'axios'

let generator = rfidSkuGenerator()

const SKUS: ListaDeiCapiData[] = [
  {
    type: 'cart',
    id: '0100401,01,FF00341C32D42CB032C30C4A',
    attributes: {
      cod_negozio: '0100401',
      cod_cassa: '01',
      sku: '30411131020033',
      rfid: 'FF00341C32D42CB032C30C4A',
      stato: 'N',
    },
  },
]

export const mockRFIDApi: ReturnType<typeof RFIDApiFactory> = {
  apiV1CartActionActionPut(
    action: 'start' | 'stop' | 'reset',
    _options?: unknown
  ): AxiosPromise<ActionResult> {
    // Make sure to reset the mocks.
    if (action !== 'reset') {
      generator = rfidSkuGenerator()
    }

    return Promise.resolve({
      data: {
        data: {
          type: 'cart_action',
          attributes: {
            action: action,
            result: '',
          },
        },
      },
    }) as AxiosPromise<ActionResult>
  },

  apiV1CartGet(_options?: unknown): AxiosPromise<ListaDeiCapi> {
    const result = generator.next()
    const value = result.value as ListaDeiCapiData[]

    return Promise.resolve({
      data: {
        data: value,
      },
    }) as AxiosPromise<ListaDeiCapi>
  },

  apiV1CartPut(
    _datiCorrezioneStatoItemRfid?: DatiCorrezioneStatoItemRfid,
    _options?: unknown
  ): AxiosPromise<ListaDeiCapi1> {
    return Promise.resolve({
      data: {
        data: [
          {
            type: 'cart',
            id: '0100401,01,FF00341C32D42CB032C30C4A',
            attributes: {
              cod_negozio: '0100401',
              cod_cassa: '01',
              sku: '30411131020033',
              rfid: 'FF00341C32D42CB032C30C4A',
              stato: 'C',
            },
          },
        ],
      },
    }) as AxiosPromise<ListaDeiCapi>
  },

  apiV1CartIdTransazioneDelete(
    _idTransazione: number,
    _options?: unknown
  ): AxiosPromise<ListaDeiNuoviTotaliTransazione> {
    return Promise.resolve({
      data: {
        data: {},
      },
    }) as AxiosPromise<ListaDeiNuoviTotaliTransazione>
  },
}

function* rfidSkuGenerator(): Generator<ListaDeiCapiData[], void, unknown> {
  let counter = 1
  const pollingToWait = Math.floor(Math.random() * 10)

  // eslint-disable-next-line no-console
  console.log(`Mocking RFID reads. Will wait ${pollingToWait} polling requests`)

  while (true) {
    counter++

    if (counter === pollingToWait) {
      yield SKUS
    }

    yield []
  }
}
