import { LogsInitConfiguration } from '@datadog/browser-logs'
import { RumInitConfiguration } from '@datadog/browser-rum'

export const ABSTRID_EA = 'abstrid-ea'
export const images = {
  baseUrl: 'https://dx0woejilafh2.cloudfront.net/sys-master/m0',
}

export const menu = {
  topLevelOrder: [
    'POS:::dashbord_stock',
    'POS:::dashboard_backoffice',
    'POS:::dashboard_frontoffice',
  ],
  shortcutIds: ['POS:::vendita', 'POS:::resi'],
}

/**
 * A list of pages that are actually just links to pages on BMS.
 */
export const ADDITIONAL_BMS_PAGES = [
  'POS:::KPIB2CRiepilogo',
  'POS:::KPIB2CDettaglio',
  'POS:::KPIGiornalieri',
  'POS:::B2EAvanzamentoOrdini',
  'POS:::resi_b2c_list',
]

export const datadogConfigs: RumInitConfiguration & LogsInitConfiguration = {
  applicationId: '166d7e05-1c89-484c-99b1-3225ca90af31',
  clientToken: 'pube893982f1666cdce9e62cc3dbbee67e5',
  site: 'datadoghq.eu',
  service: 'posweb-frontend',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow',
  forwardConsoleLogs: ['error'],
  forwardReports: 'all',
  trackFrustrations: true,
  trackLongTasks: true,
  trackUserInteractions: true,
}

export const OM_BASE_PATH_PROD = 'https://om.mmfg.it'
export const OM_BASE_PATH_TEST = 'https://test.om.mmfg.it'

export const SID_DURATION = 1000 * 60 * 60

export const SUPER_USER_ROLE = 'POS:::Super'

/*
  [
    {
      param: '',
      module: ''
    }
  ]
* */
export const MODULES = [
  {
    param: '',
    module: 'crm-eventi',
  },
  {
    param: 'ENDLESSAISLE_ENABLED',
    module: 'endless-aisle',
  },
  {
    param: 'PROMO_ENGINE_ENABLED',
    module: 'promo-engine',
  },
  {
    param: 'PETTY_CASH_MODE',
    module: 'petty-cash-v2',
  },
  {
    param: 'PICKING_ENABLED',
    module: 'picking',
  },
  {
    param: 'ADDRESS_VALIDATION',
    module: 'address-validation',
  },
]
