<script lang="ts">
import { DefineComponent, defineComponent, nextTick, PropType } from 'vue'
import { IonInput, IonItem, IonLabel, IonList } from '@ionic/vue'

type AutoFillType = 'on' | 'off'
export default defineComponent({
  name: 'FeSelect',

  components: {
    IonInput,
    IonLabel,
    IonItem,
    IonList,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    selectedOptionLabel: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: null,
    },

    parentClass: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },

    hasAutocomplete: {
      type: Boolean,
      default: false,
    },

    keyboardNavigationEnabled: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'default',
    },

    footerContent: {
      type: String,
      default: null,
    },

    hasClearButton: {
      type: Boolean,
      default: false,
    },
    autoFillInput: {
      type: String as PropType<AutoFillType>,
      default: 'off',
    },
    removeLabelOnOpen: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'search-term-updated',
    'option-selected',
    'update:modelValue',
    'input-lost-focus',
    'on:focus',
    'on:blur',
  ],

  data() {
    return {
      inputValue: '',
      isInputFocused: false,
      currentlyFocused: 0,
      selectValue: false,
      onOpenFocus: this.isTablet(),
    }
  },

  computed: {
    isDropdownVisible(): boolean {
      if (this.hasClearButton) {
        return this.isInputFocused && this.inputValue?.length > 0
      }
      return this.isInputFocused
    },

    isOptionSelected(): boolean {
      return true
    },

    childElementsNumber(): number {
      const childs = this.$el.querySelectorAll('ion-list > ion-item')
      return childs.length || 0
    },
    borderColor(): string {
      if (this.error && this.inputValue?.length <= 0) {
        return true
      }
      return false
    },
  },

  watch: {
    selectedOptionLabel(newValue): void {
      this.inputValue = newValue
    },

    modelValue(): void {
      const elementIonInput: DefineComponent = this.$refs
        .input as DefineComponent
      this.updateFocusStatus(false)
      elementIonInput?.$el?.setBlur()
    },
  },

  mounted() {
    setTimeout(() => {
      if (!this.selectedOptionLabel) {
        return
      }
      this.inputValue = this.selectedOptionLabel
    }, 1)
    document.addEventListener('click', this.handleOutsideClick)
    document.addEventListener('keydown', this.handleKeydown)
  },

  unmounted() {
    this.onOpenFocus = false
    document.removeEventListener('click', this.handleOutsideClick)
    document.removeEventListener('keydown', this.handleKeydown)
  },

  methods: {
    handleKeydown(event: any) {
      if (event.key === 'Tab') {
        this.onOpenFocus = false
        this.updateFocusStatus(false)
      }
    },
    isTablet() {
      return window.innerWidth > 1190 ? this.focused : false
    },
    handleInputChange(): void {
      this.selectValue = false
      this.currentlyFocused = 0
      this.$emit('search-term-updated', this.inputValue)
    },

    updateFocusStatus(isInputFocused: boolean): void {
      if (!this.isInputFocused && !isInputFocused) {
        return
      }
      this.isInputFocused = isInputFocused

      if (!this.hasAutocomplete || (!this.inputValue && !isInputFocused)) {
        return
      }

      if (!isInputFocused) {
        this.$emit('input-lost-focus', this.inputValue)
        this.inputValue = this.selectedOptionLabel || ''
      }
      this.selectValue = false
      this.currentlyFocused = 0
    },

    handleOutsideClick(event: Event): void {
      const isClickInsideSelect = this.$el.contains(event.target)
      if (isClickInsideSelect) {
        return
      }

      this.updateFocusStatus(false)
    },

    async handleOptionClick(
      selectedValue: string | number | boolean
    ): Promise<void> {
      if (this.modelValue === selectedValue) {
        await nextTick()
      }
      this.$emit('update:modelValue', selectedValue)
      this.$emit('option-selected', selectedValue)

      await nextTick()

      this.updateFocusStatus(false)
    },

    keyPress(key: string) {
      if (this.keyboardNavigationEnabled && this.childElementsNumber > 0) {
        if (
          key === 'down' &&
          this.currentlyFocused < this.childElementsNumber
        ) {
          this.currentlyFocused++
        } else if (key === 'up' && this.currentlyFocused > 1) {
          this.currentlyFocused--
        } else if (key === 'enter') {
          this.selectValue = true
        }
      }
    },

    clearInput() {
      this.inputValue = ''
      this.$emit('update:modelValue', '')
      this.$emit('option-selected', '')
    },
  },
})
</script>

<template>
  <div class="select">
    <ion-item
      class="field"
      :class="{
        'item-has-focus': isInputFocused,
        underlineBorderErrorRed: borderColor,
      }"
      :disabled="!!disabled"
    >
      <ion-label v-if="label" :class="parentClass" position="floating">
        {{ label }}<sup v-if="mandatory">*</sup>
      </ion-label>

      <ion-input
        ref="input"
        v-model="inputValue"
        :disabled="!!disabled"
        :autofocus="onOpenFocus"
        :autocomplete="autoFillInput"
        @ion-change="handleInputChange"
        @ion-blur="
          () => {
            $emit('on:blur', inputValue)
          }
        "
        @ion-focus="
          () => {
            $emit('on:focus', inputValue)
            updateFocusStatus(true)
          }
        "
        @keyup.down="keyPress('down')"
        @keyup.up="keyPress('up')"
        @keyup.enter="keyPress('enter')"
        @keydown.tab="keyPress('tab')"
      />

      <fe-icon
        v-show="hasAutocomplete && inputValue?.length > 0"
        class="icon icon_close"
        icon="close"
        @click="clearInput"
      />
      <fe-icon v-show="!hasAutocomplete" class="icon" icon="caret-down" />
      <span v-if="error && inputValue?.length <= 0" class="error">{{
        error
      }}</span>
    </ion-item>

    <button
      v-if="!hasAutocomplete"
      type="button"
      class="overlay-button"
      :disabled="disabled"
      @click.prevent="updateFocusStatus(!isInputFocused)"
    />
    <ion-list
      v-if="isDropdownVisible"
      ref="test"
      class="options"
      :class="{ 'size-small': size === 'small' }"
      @change="handleOptionClick"
    >
      <slot />
      <div v-if="footerContent">
        <div class="options__footer">
          {{ footerContent }}
        </div>
      </div>
      <div v-else>
        <slot name="footer"></slot>
      </div>
    </ion-list>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

@include tablet-landscape {
  .item-has-focus .label-floating.sc-ion-label-ios-h,
  .item-has-value .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 1rem) scale(0.8);
  }

  .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 3rem);
  }
}

@include ipadPro-landscape {
  .item-has-focus .label-floating.sc-ion-label-ios-h,
  .item-has-value .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 1.5rem) scale(0.8);
  }

  .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 3rem);
  }
}

.select {
  position: relative;
}

.field {
  margin-bottom: 0;
  &:deep(ion-input) {
    --padding-end: 2rem;
  }
}

.icon,
.overlay-button {
  @include z-index('overlay');
  position: absolute;
}

.overlay-button {
  top: 0;
  left: 0;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.icon {
  right: 0.8rem;
  bottom: 0.8rem;

  &:deep(ion-icon) {
    color: $color-black;
    font-size: 0.5rem;

    .item-has-focus & {
      color: $color-selected-light;
    }
  }
}

.icon_close {
  right: 0.8rem;
  bottom: 0.8rem;

  &:deep(ion-icon) {
    color: $color-base;
    font-size: 1.5rem;

    .item-has-focus & {
      color: $color-base;
    }
  }
}

.options {
  @include z-index('overlay', 2);
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 100%;
  max-height: 40rem;
  transform: translateY(calc(100% + 0.1rem));
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 0.1rem 0.5rem rgba($color-black, 0.12);
  padding: 0;
  overflow: auto;

  .selected {
    background-color: $color-medium-grey;
  }

  &__footer {
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
    font-size: 1.4rem;
  }
}

.size-small {
  max-height: 20rem !important;
}
.underlineBorderErrorRed {
  --border-color: #f44336;
}
</style>
