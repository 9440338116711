import {
  AbortControllerHelper,
  consumerDataApi,
  isCancel,
} from '@/addons/axios'

import { ActionContext, ActionTree } from 'vuex'
import { TypedActionContext } from '../_types'
import store, { RootState } from '@/store'
import { MutationNames, Mutations } from './_mutations'
import { State } from './_state'
import { AnonymousConsumer } from '@/interfaces/anonymous-consumer'
import {
  ApiV1ConsumersDataAttributes,
  ApiV1ConsumersPkConsumerDataAttributes,
  ConsumerAnonymizationStatus,
  GetConsumerAttributes,
  GetConsumerAttributesUltimeModifiche,
  GetConsumerData,
  PatchConsumerResponseDataAttributes,
  PostConsumerResponseDataAttributes,
} from '@/api'
import { ErrorD } from '@/addons/interface'
import { NotificationsActions } from '@/store/notifications-store'
import { getConsumerType } from '@/addons/functions/sales'
import { ConsumerTypeSearch, ConsumerTypeEnum } from '@/addons/enums'
import { GroupSearchForm } from '@/components/Consumers/drawer/SearchConsumers/consumers-search.d'
import { Statistics } from '@/store/sales/sales-store'
import { selectedConsumerStatus } from '@/store/consumer/index.d'
import { ConsumerState } from '@/store/consumer-store'

export enum ActionNames {
  FETCH_CONSUMERS = 'fetch-consumers',
  FETCH_ANONYMOUS_CONSUMERS = 'fetch-anonymous-consumers',
  CLEAR_CONSUMER = 'clear-consumers',
  RESET_STATE = 'reset-state',
  CHECK_SOGLIA_CONTANTI = 'check-soglia-contanti',
  SEND_CONTACT_PREFERENCES = 'send-contact-preferences',
  PRINT_CONTACT_FORM = 'print-contact-form',
  SET_CURRENT_CONSUMER = 'set-current-consumer',
  RESET_CURRENT_CONSUMER = 'reset-current-consumer',
  GET_EDIT_CUSTOMER_DATA = 'get-edit-customer-data',
  STORE_LAST_CHANGE_CONSUMER = 'store-last-change-consumer',
  CREATE_CONSUMER = 'create-consumer',
  UPDATE_CONSUMER = 'update-consumer',
  SEARCH_CONSUMER_BY_MULTIPLE = 'search-consumer-by-multiple',
  SET_DATA_VISIBILITY = 'set-data-visibility',
  RESET_DATA_VISIBILITY = 'reset-data-visibility',
  SET_SELECTED_CONSUMER_STATUS = 'set-selected-consumer-status',
  GET_CONSUMER_ANONYMIZATION_STATUS = 'get-consumer-anonymization-status',
  SET_IS_JUST_CREATED = 'set-is-just-created',
}

type AugmentedActionContext = TypedActionContext<Mutations, State, RootState>

export interface Actions {
  [ActionNames.FETCH_CONSUMERS](
    context: AugmentedActionContext,
    payload: {
      value: string
      pkSearch: boolean
    }
  ): Promise<void>
  [ActionNames.FETCH_ANONYMOUS_CONSUMERS](
    context: AugmentedActionContext
  ): Promise<void>
  [ActionNames.CLEAR_CONSUMER](context: AugmentedActionContext): void
  [ActionNames.RESET_STATE](context: AugmentedActionContext): void
  [ActionNames.CHECK_SOGLIA_CONTANTI](
    context: AugmentedActionContext
  ): Promise<void>
  [ActionNames.SEND_CONTACT_PREFERENCES](
    context: AugmentedActionContext
  ): Promise<void>
  // TODO: fix right interface
  [ActionNames.PRINT_CONTACT_FORM](
    context: AugmentedActionContext
  ): Promise<any>
  [ActionNames.SET_CURRENT_CONSUMER](
    context: AugmentedActionContext,
    payload: GetConsumerAttributes | string
  ): Promise<void>
  [ActionNames.RESET_CURRENT_CONSUMER](context: AugmentedActionContext): void
  [ActionNames.GET_EDIT_CUSTOMER_DATA](
    context: AugmentedActionContext
  ): Promise<GetConsumerData | undefined>
  [ActionNames.STORE_LAST_CHANGE_CONSUMER](
    context: AugmentedActionContext,
    lastChangeConsumer: GetConsumerAttributesUltimeModifiche[]
  ): void
  [ActionNames.CREATE_CONSUMER](
    context: AugmentedActionContext,
    payload: ApiV1ConsumersDataAttributes
  ): Promise<PostConsumerResponseDataAttributes | undefined>
  [ActionNames.UPDATE_CONSUMER](
    context: AugmentedActionContext,
    payload: ApiV1ConsumersDataAttributes
  ): Promise<PatchConsumerResponseDataAttributes | undefined>
  [ActionNames.SEARCH_CONSUMER_BY_MULTIPLE](
    context: AugmentedActionContext,
    payload: GroupSearchForm
  ): Promise<GetConsumerData[] | undefined>
  [ActionNames.SET_DATA_VISIBILITY](
    context: AugmentedActionContext
  ): Promise<void>
  [ActionNames.RESET_DATA_VISIBILITY](context: AugmentedActionContext): void
  [ActionNames.SET_SELECTED_CONSUMER_STATUS](
    context: AugmentedActionContext,
    payload: selectedConsumerStatus
  ): void
  [ActionNames.GET_CONSUMER_ANONYMIZATION_STATUS](
    context: AugmentedActionContext,
    pk_consumer: string
  ): Promise<ConsumerAnonymizationStatus | undefined>
  [ActionNames.SET_IS_JUST_CREATED](
    context: AugmentedActionContext,
    payload: boolean
  ): void
}

export const actions: ActionTree<State, RootState> = {
  async [ActionNames.FETCH_CONSUMERS](
    context: ActionContext<State, RootState>,
    payload
  ) {
    context.state.abortController =
      AbortControllerHelper.getCombinedControllers()

    let params
    if (payload.value.startsWith('pk-')) {
      params = {
        'filter[pk_consumer_search]': payload.value.replace('pk-', ''),
      }
    } else {
      params = {
        'filter[query]': payload.value,
      }
    }
    if (payload.pkSearch && payload.value) {
      params = {
        'filter[pk_consumer_search]': payload.value,
      }
    }

    try {
      const r = await consumerDataApi.apiV1ConsumersGet(
        'local',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          params,
          signal: context.state.abortController.signal,
        }
      )

      const mappedData =
        r.data.data?.slice(0, 10).map((c) => c.attributes) || []
      if (payload.pkSearch) {
        context.commit(MutationNames.SET_CURRENT_CONSUMER, mappedData[0] || {})
      } else {
        context.commit(MutationNames.STORE_CONSUMER, mappedData)
      }
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail
      )
      if (isCancel(err)) {
        return
      }
      throw err
    }
  },
  async [ActionNames.FETCH_ANONYMOUS_CONSUMERS](
    context: ActionContext<State, RootState>
  ) {
    try {
      const r = await consumerDataApi.apiV1AnonymousConsumerGet()
      if (r.data.data?.attributes) {
        context.commit(
          MutationNames.STORE_ANONYMOUS,
          Object.keys(r.data.data?.attributes || {}).map(
            (k: string) =>
              new AnonymousConsumer(
                k,
                (r.data.data?.attributes as { [key: string]: string })[k]
              )
          )
        )
      }
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail
      )
    }
  },
  [ActionNames.CLEAR_CONSUMER](context: ActionContext<State, RootState>) {
    context.state.abortController?.abort()
    context.commit(MutationNames.STORE_CONSUMER, [])
  },
  [ActionNames.RESET_STATE](context: ActionContext<State, RootState>) {
    context.commit(MutationNames.RESET_STATE)
  },
  async [ActionNames.CHECK_SOGLIA_CONTANTI](
    context: ActionContext<State, RootState>
  ) {
    await consumerDataApi.apiV1ConsumersPkConsumerCheckSogliaContantiPost(
      context.state.currentConsumer?.pk_consumer ?? '',
      {
        data: {
          attributes: {
            consumer: context.rootState.sales.consumer?.pk_consumer,
            check_layby: 0,
          },
        },
      }
    )
  },
  async [ActionNames.SEND_CONTACT_PREFERENCES](
    context: ActionContext<State, RootState>
  ) {
    await consumerDataApi.apiV1ConsumersPkConsumerPreferenzeContattoPost(
      context.state.currentConsumer?.pk_consumer ?? '',
      {
        data: {
          id: '',
          attributes: {
            dati_consumer: {
              salutation: context.state.currentConsumer?.salutation,
              cognome: context.state.currentConsumer?.cognome,
              nome: context.state.currentConsumer?.nome,
              lingua: context.state.currentConsumer?.lingua,
              nazionalita: context.state.currentConsumer?.nazionalita,
              nazione: context.state.currentConsumer?.nazione_iso,
              giorno_nascita: context.state.currentConsumer?.giorno_nascita,
              mese_nascita: context.state.currentConsumer?.mese_nascita,
              anno_nascita: context.state.currentConsumer?.anno_nascita,
              telefono1: context.state.currentConsumer?.telefono1,
              telefono2: context.state.currentConsumer?.telefono2,
              cellulare1: context.state.currentConsumer?.cellulare1,
              cellulare2: context.state.currentConsumer?.cellulare2,
              email_user: context.state.currentConsumer?.email,
              edificio: context.state.currentConsumer?.edificio,
              note_indirizzo: context.state.currentConsumer?.note_indirizzo,
              indirizzo: context.state.currentConsumer?.indirizzo,
              numero_civico: context.state.currentConsumer?.numero_civico,
              localita: context.state.currentConsumer?.localita,
              cap: context.state.currentConsumer?.cap,
              provincia: context.state.currentConsumer?.provincia,
              note: context.state.currentConsumer?.note,
              note_negozio: context.state.currentConsumer?.note_negozio,
              sesso: context.state.currentConsumer?.sesso,
              interessi: context.state.currentConsumer?.interessi,
              professioni: context.state.currentConsumer?.professioni,
              anno_nascita_presunto:
                context.state.currentConsumer?.anno_nascita_presunto,
              pk_consumer: context.state.currentConsumer?.pk_consumer,
              cod_cassiera: context.rootState.sales.currentSale.cod_cassiera,
            },
          },
        },
      }
    )
  },
  async [ActionNames.PRINT_CONTACT_FORM](
    context: ActionContext<State, RootState>
  ) {
    return (
      await consumerDataApi.apiV1ConsumersPkConsumerPrintContactFormGet(
        context.state.currentConsumer?.pk_consumer ?? ''
      )
    ).data?.data?.attributes
  },
  async [ActionNames.SET_CURRENT_CONSUMER](
    context: ActionContext<State, RootState>,
    payload: GetConsumerAttributes | string
  ) {
    if (typeof payload === 'string') {
      await context.dispatch(ActionNames.FETCH_CONSUMERS, {
        value: payload,
        pkSearch: true,
      })
    } else {
      context.commit(MutationNames.SET_CURRENT_CONSUMER, payload)
    }
  },
  [ActionNames.RESET_CURRENT_CONSUMER](
    context: ActionContext<State, RootState>
  ) {
    context.commit(MutationNames.SET_CURRENT_CONSUMER, {})
  },
  async [ActionNames.GET_EDIT_CUSTOMER_DATA](
    context: ActionContext<State, RootState>
  ) {
    try {
      const consumerType = getConsumerType(context.state?.currentConsumer)
      const pkConsumer = context.state?.currentConsumer?.pk_consumer
      const searchConsumerType =
        consumerType === ConsumerTypeEnum.STORE
          ? ConsumerTypeSearch.LOCAL
          : ConsumerTypeSearch.COMPANY
      const r = await consumerDataApi.apiV1ConsumersGet(
        searchConsumerType,
        undefined,
        undefined,
        undefined,
        undefined,
        searchConsumerType === ConsumerTypeSearch.LOCAL
          ? pkConsumer
          : undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        searchConsumerType === ConsumerTypeSearch.COMPANY
          ? pkConsumer
          : undefined,
        undefined
      )
      const responseData: Array<GetConsumerData> | GetConsumerData =
        r.data?.data || {}
      return Array.isArray(responseData) ? responseData[0] : responseData
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error.response?.data?.errors || [])[0].detail
      )
    }
  },
  [ActionNames.STORE_LAST_CHANGE_CONSUMER](
    context: ActionContext<State, RootState>,
    payload: GetConsumerAttributesUltimeModifiche[]
  ) {
    context.commit(MutationNames.STORE_LAST_CHANGE_CONSUMER, payload)
  },
  async [ActionNames.CREATE_CONSUMER](
    context: ActionContext<State, RootState>,
    payload: ApiV1ConsumersDataAttributes
  ) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { grado_anonimato, ...restPayload } = payload
      const response = await consumerDataApi.apiV1ConsumersPost({
        data: {
          type: 'consumer',
          id: undefined,
          attributes: {
            ...restPayload,
            locale: '0',
          },
        },
      })

      if (response.data.data) {
        const consumerData = response.data.data.attributes
        context.commit(MutationNames.SET_CURRENT_CONSUMER, consumerData)
        return consumerData
      }
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail
      )
    }
  },
  async [ActionNames.UPDATE_CONSUMER](
    context: ActionContext<State, RootState>,
    payload: ApiV1ConsumersDataAttributes
  ) {
    try {
      const response = await consumerDataApi.apiV1ConsumersPkConsumerPatch(
        context.state.currentConsumer?.pk_consumer || '',
        {
          data: {
            type: 'consumer',
            id: context.state.currentConsumer?.pk_consumer,
            attributes: payload as ApiV1ConsumersPkConsumerDataAttributes,
          },
        }
      )

      if (response.data.data) {
        const consumerData = response.data.data.attributes
        context.commit(MutationNames.SET_CURRENT_CONSUMER, consumerData)
        return consumerData
      }
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail
      )
    }
  },
  async [ActionNames.SEARCH_CONSUMER_BY_MULTIPLE](
    context: ActionContext<State, RootState>,
    payload: GroupSearchForm
  ) {
    try {
      const consumerApiResult = await consumerDataApi.apiV1ConsumersGet(
        'company',
        undefined,
        payload.loyaltyCard !== 0 ? payload.loyaltyCard : undefined,
        payload.telefono ?? undefined,
        payload.email ?? undefined,
        payload.idCliente ?? undefined,
        payload.nazione_iso ?? undefined,
        payload.localita ?? undefined,
        payload.indirizzo ?? undefined,
        payload.cognome ?? undefined,
        payload.nome ?? undefined,
        undefined,
        undefined,
        undefined
      )
      const consumerApiData = consumerApiResult.data?.data ?? []
      const isConsumerApiDataArray = Array.isArray(consumerApiData)
      return isConsumerApiDataArray ? consumerApiData : [consumerApiData]
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail
      )
    }
  },
  async [ActionNames.SET_DATA_VISIBILITY](
    context: ActionContext<State, RootState>
  ) {
    try {
      const [statisticsResponse, statisticsInsegnaResponse] = await Promise.all(
        [
          consumerDataApi.apiV1ConsumersPkConsumerStatisticsGet(
            context.state?.currentConsumer?.pk_consumer || ''
          ),
          consumerDataApi.apiV1ConsumersPkConsumerStatisticsInsegnaGet(
            context.state?.currentConsumer?.pk_consumer || ''
          ),
        ]
      )

      const payload = {
        statistics: statisticsResponse.data,
        statisticsInsegna: statisticsInsegnaResponse.data,
      } as Statistics

      context.commit(MutationNames.SET_DATA_VISIBILITY, payload)
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail
      )
    }
  },
  [ActionNames.RESET_DATA_VISIBILITY](
    context: ActionContext<State, RootState>
  ) {
    context.commit(MutationNames.RESET_DATA_VISIBILITY)
  },
  [ActionNames.SET_SELECTED_CONSUMER_STATUS](
    context: ActionContext<State, RootState>,
    payload: selectedConsumerStatus
  ) {
    context.commit(MutationNames.SET_SELECTED_CONSUMER_STATUS, payload)
  },
  async [ActionNames.GET_CONSUMER_ANONYMIZATION_STATUS](
    context: ActionContext<ConsumerState, RootState>,
    pk_consumer: string
  ): Promise<ConsumerAnonymizationStatus | undefined> {
    try {
      const resp =
        await consumerDataApi.apiV1ConsumersPkConsumerAnonymizationStatusGet(
          pk_consumer,
          1
        )
      return resp.data?.data?.attributes || undefined
    } catch (err: unknown) {
      const error = err as ErrorD
      if (error.response?.data?.errors[0]?.detail) {
        await store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          error.response?.data?.errors[0]?.detail
        )
      }
    }
  },
  [ActionNames.SET_IS_JUST_CREATED](
    context: ActionContext<ConsumerState, RootState>,
    payload: boolean
  ) {
    context.commit(MutationNames.SET_IS_JUST_CREATED, payload)
  },
}
