import { pettyCashApi, storeManagementApi } from '@/addons/axios'
import {
  ApiV1ReintegriData,
  ApiV1ReintegriDataAttributes,
  GetCashFlowStatusData,
  GetCashiersAttributes,
  GetPettyCashDetail,
  PostPettyCash,
} from '@/api'
import { RootState } from '@/store/index'
import { ActionContext, GetterTree } from 'vuex'
import { AxiosResponse } from 'axios'

enum Mutations {
  RESET_STATE = 'resetState',
  STORE_PETTYCASHES = 'storePettyCashes',
  STORE_INITIAL_BALANCE = 'storeInitialBalance',
  STORE_MAX_MONEY_AMOUNT = 'storeMaxMoneyAmount',
  STORE_CASHIER = 'storeCashier',
  SET_CONFIRM_PETTYCASH = 'setConfirmPettyCash',
  SET_SELECTED_PETTYCASH = 'setSelectedPettyCash',
  SET_ID_DATE_SELECTED = 'setIdDateSelected',
}

enum Actions {
  CONFIRM_PETTYCASH = 'confirmPettyCash',
  PRINT_PETTYCASH = 'printPettyCash',
  ADD_PETTYCASH = 'addPettyCash',
  REMOVE_PETTYCASH = 'removePettyCash',
  CHANGE_BANK = 'changeBank',
  CHANGE_PETTYCASH_DATE = 'changePettyCashDate',
  CHANGE_CASHIER = 'changeCashier',
  RESET_STATE = 'resetState',
  LOAD_INITIAL_BALANCE = 'loadInitialBalance',
  SELECTED_PETTYCASH = 'selectedPettyCash',
  ID_DATE_SELECTED = 'idDateSelected',
}

enum Getters {
  GET_INITIAL_BALANCE = 'getInitialBalance',
  GET_PETTYCASH_TOTAL = 'getPettyCashTotal',
  GET_RESIDUAL_VALUE = 'getResidualValue',
  GET_PETTY_CASHES = 'getPettyCashes',
  CAN_MANAGE_PETTYCASH = 'canManagePettyCash',
  GET_SELECTED_PETTY_CASHES = 'getSelectedPettyCash',
  GET_ID_DATE_SELECTED = 'getIdDateSelected',
  GET_MAX_MONEY_AMOUNT = 'getMaxMoneyAmount',
  GET_CASHIER = 'get-cashier',
  GET_PETTY_CASH_CONFIRMED = 'get-petty-cash-confirmed',
}

const createEmpty = (): ApiV1ReintegriDataAttributes => {
  return {
    cassiera: undefined,
    nominativo: undefined,
    tipo_doc: undefined,
    data_doc: undefined,
    numero_doc: undefined,
    descrizione: undefined,
    importo: undefined,
  }
}
export interface PettyCashState {
  /**
   * PettyCashes rows
   */
  pettyCashes: ApiV1ReintegriDataAttributes[]
  selectedPettyCashes: GetPettyCashDetail | undefined
  /**
   * Saldo iniziale del negozio
   */
  initialBalance: number
  cashier: GetCashiersAttributes | undefined
  maxMoneyAmount: number
  pettyCashConfirmed: boolean
  idDateSelected: string
}

const initState = (): PettyCashState => ({
  pettyCashes: [createEmpty()],
  initialBalance: 0,
  cashier: undefined,
  maxMoneyAmount: 0,
  pettyCashConfirmed: false,
  selectedPettyCashes: undefined,
  idDateSelected: '',
})

const pettyCashStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: PettyCashState): void => {
      Object.assign(state, initState())
    },
    [Mutations.STORE_PETTYCASHES]: (
      state: PettyCashState,
      pettyCashes: ApiV1ReintegriDataAttributes[]
    ): void => {
      state.pettyCashes = pettyCashes
    },
    [Mutations.STORE_CASHIER]: (
      state: PettyCashState,
      cashier: GetCashiersAttributes
    ): void => {
      state.cashier = cashier
    },
    [Mutations.STORE_INITIAL_BALANCE]: (
      state: PettyCashState,
      initialBalance: number
    ): void => {
      state.initialBalance = initialBalance
    },
    [Mutations.STORE_MAX_MONEY_AMOUNT]: (
      state: PettyCashState,
      maxMoneyAmount: number
    ): void => {
      state.maxMoneyAmount = maxMoneyAmount
    },
    [Mutations.SET_SELECTED_PETTYCASH]: (
      state: PettyCashState,
      selectedPettyCashes: GetPettyCashDetail
    ): void => {
      state.selectedPettyCashes = selectedPettyCashes
    },
    [Mutations.SET_ID_DATE_SELECTED]: (
      state: PettyCashState,
      idDateSelected: string
    ): void => {
      state.idDateSelected = idDateSelected
    },
  },
  actions: {
    [Actions.LOAD_INITIAL_BALANCE]: async (
      context: ActionContext<PettyCashState, RootState>
    ): Promise<void> => {
      const response =
        await storeManagementApi.apiV1PoswebCashesCashIdStatusGet(
          context.rootState.configs.setup?.cash_id || ''
        )
      const data = response.data as GetCashFlowStatusData[]
      const initialBalance = data.find((t) => t.type === 'store_cash_flow')
        ?.attributes?.saldo_attuale
      context.commit(Mutations.STORE_INITIAL_BALANCE, initialBalance)
    },
    [Actions.RESET_STATE]: (
      context: ActionContext<PettyCashState, RootState>
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.CONFIRM_PETTYCASH]: async (
      context: ActionContext<PettyCashState, RootState>
    ): Promise<object> => {
      const pettyCashList = context.state.pettyCashes
        .filter((el) => el.tipo_doc && el.importo)
        .map((element) => ({
          ...element,
          importo: element.importo?.toString() || '0',
          cassiera: context.state.cashier?.venditrice,
          residuo: context.getters[Getters.GET_RESIDUAL_VALUE],
        }))
      const postPettyCash: PostPettyCash = {
        data: pettyCashList.map((d) => ({
          type: 'reintegri',
          attributes: d,
        })) as ApiV1ReintegriData,
      }
      context.state.pettyCashConfirmed = true
      return (await pettyCashApi.apiV1ReintegriPost(postPettyCash)).data
    },
    [Actions.PRINT_PETTYCASH]: (
      context: ActionContext<PettyCashState, RootState>,
      idPrint: string
    ): Promise<AxiosResponse> => {
      return pettyCashApi.apiV1ReintegriStampaKeyGet(idPrint)
    },
    [Actions.ADD_PETTYCASH]: (
      context: ActionContext<PettyCashState, RootState>
    ): void => {
      context.commit(Mutations.STORE_PETTYCASHES, [
        ...context.state.pettyCashes,
        createEmpty(),
      ])
    },
    /**
     * @param context
     * @param index the index of the element in the array to remove
     */
    [Actions.REMOVE_PETTYCASH]: (
      context: ActionContext<PettyCashState, RootState>,
      index: number
    ): void => {
      context.commit(Mutations.STORE_PETTYCASHES, [
        ...context.state.pettyCashes.filter((el, i) => i !== index),
      ])
    },

    [Actions.CHANGE_CASHIER]: (
      context: ActionContext<PettyCashState, RootState>,
      cassiera: string
    ): void => {
      context.commit(Mutations.STORE_PETTYCASHES, [
        ...context.state.pettyCashes.map((d) => {
          d.cassiera = cassiera
          return d
        }),
      ])
    },
    [Actions.SELECTED_PETTYCASH]: (
      context: ActionContext<PettyCashState, RootState>,
      selectedPettyCashes: GetPettyCashDetail
    ): void => {
      context.commit(Mutations.SET_SELECTED_PETTYCASH, selectedPettyCashes)
    },
    [Actions.ID_DATE_SELECTED]: (
      context: ActionContext<PettyCashState, RootState>,
      idDateSelected: string
    ): void => {
      context.commit(Mutations.SET_ID_DATE_SELECTED, idDateSelected)
    },
  },
  getters: {
    [Getters.GET_INITIAL_BALANCE]: (state: PettyCashState): number => {
      return state.initialBalance
    },
    [Getters.GET_PETTY_CASHES]: (
      state: PettyCashState
    ): ApiV1ReintegriDataAttributes[] => {
      return state.pettyCashes
    },
    [Getters.GET_PETTYCASH_TOTAL]: (state: PettyCashState): number => {
      return state.pettyCashes.reduce(
        (p, c) => p + parseFloat(c?.importo || '0'),
        0
      )
    },
    [Getters.GET_RESIDUAL_VALUE]: (
      state: PettyCashState,
      getters: GetterTree<PettyCashState, RootState>
    ): number => {
      const pettyCashtotal = getters[
        Getters.GET_PETTYCASH_TOTAL
      ] as unknown as number
      return state.initialBalance - pettyCashtotal
    },
    [Getters.CAN_MANAGE_PETTYCASH]: (state: PettyCashState): boolean => {
      return !!state.cashier
    },
    [Getters.GET_SELECTED_PETTY_CASHES]: (
      state: PettyCashState
    ): GetPettyCashDetail | undefined => {
      return state.selectedPettyCashes
    },
    [Getters.GET_ID_DATE_SELECTED]: (state: PettyCashState): string => {
      return state.idDateSelected
    },
    [Getters.GET_PETTY_CASH_CONFIRMED]: (state: PettyCashState): boolean =>
      state.pettyCashConfirmed,
    [Getters.GET_MAX_MONEY_AMOUNT]: (state: PettyCashState): number =>
      state.maxMoneyAmount,
    [Getters.GET_CASHIER]: (
      state: PettyCashState
    ): GetCashiersAttributes | undefined => state.cashier,
    [Getters.GET_PETTY_CASH_CONFIRMED]: (state: PettyCashState): boolean =>
      state.pettyCashConfirmed,
  },
}

export default pettyCashStore

export const PettyCashMutations = {
  RESET_STATE: `pettyCash/${Mutations.RESET_STATE}`,
  STORE_PETTYCASHES: `pettyCash/${Mutations.STORE_PETTYCASHES}`,
  STORE_CASHIER: `pettyCash/${Mutations.STORE_CASHIER}`,
  STORE_INITIAL_BALANCE: `pettyCash/${Mutations.STORE_INITIAL_BALANCE}`,
  STORE_MAX_MONEY_AMOUNT: `pettyCash/${Mutations.STORE_MAX_MONEY_AMOUNT}`,
  SET_SELECTED_PETTYCASH: `pettyCash/${Mutations.SET_SELECTED_PETTYCASH}`,
  SET_ID_DATE_SELECTED: `pettyCash/${Mutations.SET_ID_DATE_SELECTED}`,
}

export const PettyCashActions = {
  CONFIRM_PETTYCASH: `pettyCash/${Actions.CONFIRM_PETTYCASH}`,
  PRINT_PETTYCASH: `pettyCash/${Actions.PRINT_PETTYCASH}`,
  ADD_PETTYCASH: `pettyCash/${Actions.ADD_PETTYCASH}`,
  REMOVE_PETTYCASH: `pettyCash/${Actions.REMOVE_PETTYCASH}`,
  CHANGE_BANK: `pettyCash/${Actions.CHANGE_BANK}`,
  CHANGE_PETTYCASH_DATE: `pettyCash/${Actions.CHANGE_PETTYCASH_DATE}`,
  CHANGE_CASHIER: `pettyCash/${Actions.CHANGE_CASHIER}`,
  RESET_STATE: `pettyCash/${Actions.RESET_STATE}`,
  LOAD_INITIAL_BALANCE: `pettyCash/${Actions.LOAD_INITIAL_BALANCE}`,
  SELECTED_PETTYCASH: `pettyCash/${Actions.SELECTED_PETTYCASH}`,
  ID_DATE_SELECTED: `pettyCash/${Actions.ID_DATE_SELECTED}`,
}

export const PettyCashGetters = {
  GET_INITIAL_BALANCE: `pettyCash/${Getters.GET_INITIAL_BALANCE}`,
  GET_PETTYCASH_TOTAL: `pettyCash/${Getters.GET_PETTYCASH_TOTAL}`,
  GET_RESIDUAL_VALUE: `pettyCash/${Getters.GET_RESIDUAL_VALUE}`,
  GET_PETTY_CASHES: `pettyCash/${Getters.GET_PETTY_CASHES}`,
  CAN_MANAGE_PETTYCASH: `pettyCash/${Getters.CAN_MANAGE_PETTYCASH}`,
  GET_SELECTED_PETTY_CASHES: `pettyCash/${Getters.GET_SELECTED_PETTY_CASHES}`,
  GET_ID_DATE_SELECTED: `pettyCash/${Getters.GET_ID_DATE_SELECTED}`,
  GET_MAX_MONEY_AMOUNT: `pettyCash/${Getters.GET_MAX_MONEY_AMOUNT}`,
  GET_CASHIER: `pettyCash/${Getters.GET_CASHIER}`,
  GET_PETTY_CASH_CONFIRMED: `pettyCash/${Getters.GET_PETTY_CASH_CONFIRMED}`,
}
