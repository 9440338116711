import { ActionContext, GetterTree } from 'vuex'
import store, { RootState } from '@/store/index'
import { usersApi } from '@/addons/axios'
import {
  ApiV1UtentiData,
  ApiV1UtentiData1,
  ElencoUtentiAttributes,
  RuoliUtenteDataAttributes,
} from '@/api'
import { extractErrorMessage } from '@/addons/functions'
import { NotificationsActions } from '@/store/notifications-store'
export interface UsersState {
  users: ElencoUtentiAttributes[]
  roles: RuoliUtenteDataAttributes[]
}

const initState = (): UsersState => ({
  users: [],
  roles: [],
})

enum Actions {
  RESET_STATE = 'resetState',
  FETCH_USERS = 'fetchUsers',
  FETCH_ROLES = 'fetchRoles',
  UPDATE_USER = 'updateUser',
  CREATE_USER = 'createUser',
  DELETE_USER = 'deleteUser',
  GET_UTENTE_DETAIL = 'getUtenteDetail',
}

enum Mutations {
  RESET_STATE = 'resetState',
  STORE_USERS = 'storeUsers',
  STORE_ROLES = 'storeRoles',
}

enum Getters {
  GET_ROLE_DESCRIPTION = 'getRoleDescription',
  GET_ROLELIST_FOR_CURRENT_ROLE = 'gerRoleByCurrentUser',
  GET_ROLES = 'getRoles',
  GET_USERS = 'getUsers',
}
export default {
  namespaced: true,
  state: initState,
  actions: {
    [Actions.RESET_STATE]: (context: ActionContext<UsersState, RootState>) => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.FETCH_USERS]: (context: ActionContext<UsersState, RootState>) => {
      usersApi
        .apiV1UtentiGet()
        .then((r) => {
          context.commit(
            Mutations.STORE_USERS,
            r.data.data?.map((u) => u.attributes)
          )
        })
        .catch((e) => {
          const msg = e?.response?.data?.errors?.length
            ? extractErrorMessage(e?.response?.data?.errors[0].detail)
            : 'pos_common.generic_error'
          store.dispatch(NotificationsActions.NOTIFY_ERROR, msg, { root: true })
        })
    },
    [Actions.FETCH_ROLES]: (context: ActionContext<UsersState, RootState>) => {
      usersApi.apiV1UtentiRuoliGet().then((r) => {
        context.commit(
          Mutations.STORE_ROLES,
          r.data.data?.attributes?.map((el) => el.attributes)
        )
      })
    },
    [Actions.UPDATE_USER]: async (
      context: ActionContext<UsersState, RootState>,
      user: ApiV1UtentiData
    ): Promise<void> => {
      try {
        await usersApi.apiV1UtentiPut({ data: user })
        return Promise.resolve()
      } catch (e: any) {
        const msg = e?.response?.data?.errors?.length
          ? extractErrorMessage(e?.response?.data?.errors[0].detail)
          : 'pos_common.generic_error'
        await store.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
          root: true,
        })
        return Promise.reject(e)
      }
    },
    [Actions.CREATE_USER]: async (
      context: ActionContext<UsersState, RootState>,
      user: ApiV1UtentiData1
    ) => {
      try {
        await usersApi.apiV1UtentiPost({ data: user })
        return Promise.resolve()
      } catch (e: any) {
        const msg = e?.response?.data?.errors?.length
          ? extractErrorMessage(e?.response?.data?.errors[0].detail)
          : 'pos_common.generic_error'
        await store.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
          root: true,
        })
        return Promise.reject(e)
      }
    },
    [Actions.DELETE_USER]: async (
      context: ActionContext<UsersState, RootState>,
      username: string
    ) => {
      try {
        await usersApi.apiV1UtentiUsernameDelete(username)
        return Promise.resolve()
      } catch (e: any) {
        const msg = e?.response?.data?.errors?.length
          ? extractErrorMessage(e?.response?.data?.errors[0].detail)
          : 'pos_common.generic_error'
        await store.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
          root: true,
        })
        return Promise.reject(e)
      }
    },
    [Actions.GET_UTENTE_DETAIL]: async (
      context: ActionContext<UsersState, RootState>,
      username: string
    ): Promise<ElencoUtentiAttributes | undefined> => {
      try {
        const resp = await usersApi.apiV1UtentiGet(username)
        if (resp.data.data?.length) {
          return Promise.resolve(resp.data.data[0].attributes)
        }
        return Promise.resolve(undefined)
      } catch (e: any) {
        const msg = e?.response?.data?.errors?.length
          ? extractErrorMessage(e?.response?.data?.errors[0].detail)
          : 'pos_common.generic_error'
        await store.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
          root: true,
        })
        return Promise.reject(e)
      }
    },
  },
  mutations: {
    [Mutations.RESET_STATE]: (state: UsersState): void => {
      Object.assign(state, initState())
    },
    [Mutations.STORE_USERS]: (
      state: UsersState,
      users: ElencoUtentiAttributes[]
    ) => {
      state.users = users
    },
    [Mutations.STORE_ROLES]: (
      state: UsersState,
      roles: RuoliUtenteDataAttributes[]
    ) => {
      state.roles = roles
    },
  },
  getters: {
    [Getters.GET_ROLE_DESCRIPTION]: (state: UsersState) => (role: string) => {
      return state.roles.find(
        (el: RuoliUtenteDataAttributes) => el.ruolo === role
      )?.traduzione
    },
    [Getters.GET_ROLELIST_FOR_CURRENT_ROLE]: (
      state: UsersState,
      _getters: GetterTree<UsersState, RootState>,
      rootState: RootState
    ) => {
      const currentUserRole = rootState.auth?.user?.role
      const roleslist =
        state.roles.find(
          (el: RuoliUtenteDataAttributes) => el.ruolo === currentUserRole
        )?.roleslist || []

      return state.roles.filter((el: RuoliUtenteDataAttributes) =>
        roleslist.includes(el.ruolo)
      )
    },
    [Getters.GET_ROLES]: (state: UsersState): RuoliUtenteDataAttributes[] =>
      state.roles,
    [Getters.GET_USERS]: (state: UsersState): ElencoUtentiAttributes[] =>
      state.users,
  },
}

export const UsersActions = {
  RESET_STATE: `users/${Actions.RESET_STATE}`,
  FETCH_USERS: `users/${Actions.FETCH_USERS}`,
  FETCH_ROLES: `users/${Actions.FETCH_ROLES}`,
  UPDATE_USER: `users/${Actions.UPDATE_USER}`,
  CREATE_USER: `users/${Actions.CREATE_USER}`,
  DELETE_USER: `users/${Actions.DELETE_USER}`,
  GET_UTENTE_DETAIL: `users/${Actions.GET_UTENTE_DETAIL}`,
}

export const UsersGetters = {
  GET_ROLE_DESCRIPTION: `users/${Getters.GET_ROLE_DESCRIPTION}`,
  GET_ROLELIST_FOR_CURRENT_ROLE: `users/${Getters.GET_ROLELIST_FOR_CURRENT_ROLE}`,
  GET_ROLES: `users/${Getters.GET_ROLES}`,
  GET_USERS: `users/${Getters.GET_USERS}`,
}
