import { GetterTree } from 'vuex'
import { RootState } from '..'
import { State } from './_state'

export enum GetterNames {
  GET_SUSPENDED_CREDITS_LIST = 'get-suspended-credits-list',
  GET_SUSPENDED_CONSUMERS_LIST = 'get-suspeneded-consumers',
  GET_SUSPENDED_CONSUMER = 'get-suspended-consumer',
  GET_FOOTER_INFOS = 'get-footer-infos',
  GET_ALL_SUSPENDED_CREDITS = 'get-all-suspended-credits',
  GET_ALL_PERFORMED_PAYMENTS = 'get-all-performed-payments',
  GET_NUMERO_BOLLETTINA = 'get-numero-bollettina',
  GET_TOTALS = 'get-totals',
  GET_CASHIER_ID = 'get-cashier-id',
  GET_CASHIER_LABEL = 'get-cashier-label',
  GET_ID_TRANSAZIONE = 'get-id-transazione',
  IS_LOADING_DATA = 'is-loading-data',
  GET_CREATION_DATE = 'get-creation-date',
}

export type Getters = {
  [GetterNames.GET_SUSPENDED_CONSUMERS_LIST](
    state: State
  ): State['consumersList']

  [GetterNames.GET_SUSPENDED_CONSUMER](state: State): State['consumer']

  [GetterNames.GET_FOOTER_INFOS](state: State): State['footerInfos']

  [GetterNames.GET_SUSPENDED_CREDITS_LIST](
    state: State
  ): State['suspendedCreditList']

  [GetterNames.GET_ALL_SUSPENDED_CREDITS](
    state: State
  ): State['allSuspendedCredits']

  [GetterNames.GET_NUMERO_BOLLETTINA](state: State): State['numeroBollettina']

  [GetterNames.GET_ALL_PERFORMED_PAYMENTS](
    state: State
  ): State['allPerformedPayments']

  [GetterNames.GET_CASHIER_ID](state: State): string

  [GetterNames.GET_CASHIER_LABEL](state: State): string

  [GetterNames.GET_TOTALS](state: State): number
  [GetterNames.GET_ID_TRANSAZIONE](state: State): string
  [GetterNames.IS_LOADING_DATA](state: State): boolean
  [GetterNames.GET_CREATION_DATE](state: State): string
}

export const getters: GetterTree<State, RootState> = {
  [GetterNames.GET_SUSPENDED_CONSUMERS_LIST]: (state) => {
    return state.consumersList || []
  },

  [GetterNames.GET_SUSPENDED_CONSUMER]: (state) => {
    return state.consumer
  },

  [GetterNames.GET_FOOTER_INFOS]: (state) => {
    return state.footerInfos
  },

  [GetterNames.GET_SUSPENDED_CREDITS_LIST]: (state) => {
    return state.suspendedCreditList
  },

  [GetterNames.GET_ALL_SUSPENDED_CREDITS]: (state) => {
    return state.allSuspendedCredits
  },

  [GetterNames.GET_NUMERO_BOLLETTINA]: (state) => {
    return state.numeroBollettina
  },

  [GetterNames.GET_ALL_PERFORMED_PAYMENTS]: (state) => {
    return state.allPerformedPayments
  },

  [GetterNames.GET_CASHIER_ID]: (state) => {
    return state.cashier.venditrice || ''
  },

  [GetterNames.GET_CASHIER_LABEL]: (state: State): string => {
    if (Object.keys(state.cashier).length) {
      return `${state.cashier?.venditrice} - ${state.cashier?.descrizione}`
    }
    return ''
  },

  [GetterNames.GET_TOTALS]: (state: State) => {
    return state.suspendedCreditList
      .map((credit) => credit.attributes)
      .filter((credit) => credit.selected)
      .reduce((total, credit) => {
        return total + credit.importo_ricevuto
      }, 0)
  },
  [GetterNames.GET_ID_TRANSAZIONE]: (state: State): string =>
    state.idTransazione,
  [GetterNames.IS_LOADING_DATA]: (state: State): boolean => state.isLoading,
  [GetterNames.GET_CREATION_DATE]: (state: State): string => state.creationDate,
}
