import { PageContexts } from '@/addons/enums'
interface LoaderInterface {
  section: PageContexts
  loaderOnTop?: boolean
}
export default abstract class LoaderController {
  // TODO fare rework : isVisible usato solo qui per segnare lo status del loader ma no si capisce lo scopo
  private static isVisible = false

  static show(loaderOptions: LoaderInterface) {
    if (!LoaderController.isVisible) {
      LoaderController.isVisible = true
      const loaderDiv = document.getElementById('pageloader-container')
      const loader = document.getElementById('pageloader')
      if (loaderOptions.loaderOnTop) {
        document.body.classList.add('loader-on-top')
      }
      if (loader) {
        loader.className = ''
        loader?.classList.add(loaderOptions.section.toLowerCase())
      }
      loaderDiv?.classList.add('show')
      loaderDiv?.classList.remove('hide')
    }
  }

  static hide() {
    const loaderDiv = document.getElementById('pageloader-container')
    const loader = document.getElementById('pageloader')
    const loaderOnTop = document.body.classList.contains('loader-on-top')
    if (loaderOnTop) {
      document.body.classList.remove('loader-on-top')
    }
    if (loaderDiv) {
      loaderDiv.classList.add('hide')
      loaderDiv.classList.remove('show')
    }

    // wait for the css keyframe fadeOut animation to remove loader class
    const i = setInterval(function () {
      if (loader) {
        loader.className = ''
      }
      clearInterval(i)
    }, 1000)
    LoaderController.isVisible = false
  }
}
