import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import {
  TypedMapActions,
  TypedMapMutations,
  TypedStateStore,
} from '@/store/_types'
import { RootState } from '@/store'
import { State, initState } from '@/modules/endless-aisle/store/_state'
import {
  GetterNames,
  Getters,
  getters,
} from '@/modules/endless-aisle/store/_getters'
import {
  ActionsNames,
  Actions,
  actions,
} from '@/modules/endless-aisle/store/_actions'
import { Mutations, mutations } from '@/modules/endless-aisle/store/_mutations'

const EndlessAisleState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'EndlessAisle'

export type CRMEventsStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const EndlessAisleActions = {
  [ActionsNames.SET_SHOW_ENDLESS_AISLE]: `${NAMESPACE}/${ActionsNames.SET_SHOW_ENDLESS_AISLE}`,
  [ActionsNames.SET_OPENED_DRAWER_OR_MODAL]: `${NAMESPACE}/${ActionsNames.SET_OPENED_DRAWER_OR_MODAL}`,
  [ActionsNames.SET_OPENED_DRESSING_ROOM]: `${NAMESPACE}/${ActionsNames.SET_OPENED_DRESSING_ROOM}`,
  [ActionsNames.SET_SESSION_ITEM]: `${NAMESPACE}/${ActionsNames.SET_SESSION_ITEM}`,
  [ActionsNames.REMOVE_SESSION_ITEM]: `${NAMESPACE}/${ActionsNames.REMOVE_SESSION_ITEM}`,
  [ActionsNames.REMOVE_SESSION_ITEM_BULK]: `${NAMESPACE}/${ActionsNames.REMOVE_SESSION_ITEM_BULK}`,
  [ActionsNames.SET_SESSION_ITEM_BULK]: `${NAMESPACE}/${ActionsNames.SET_SESSION_ITEM_BULK}`,
  [ActionsNames.GET_REQUEST_SKU]: `${NAMESPACE}/${ActionsNames.GET_REQUEST_SKU}`,
  [ActionsNames.SET_SHOW_LOADER_FULL]: `${NAMESPACE}/${ActionsNames.SET_SHOW_LOADER_FULL}`,
  [ActionsNames.SET_STEP_PAGE]: `${NAMESPACE}/${ActionsNames.SET_STEP_PAGE}`,
  [ActionsNames.GET_FILTERS]: `${NAMESPACE}/${ActionsNames.GET_FILTERS}`,
  [ActionsNames.GET_CATEGORIES]: `${NAMESPACE}/${ActionsNames.GET_CATEGORIES}`,
  [ActionsNames.GET_MODELS]: `${NAMESPACE}/${ActionsNames.GET_MODELS}`,
  [ActionsNames.SET_OPENED_FILTERS_MODAL]: `${NAMESPACE}/${ActionsNames.SET_OPENED_FILTERS_MODAL}`,
  [ActionsNames.SET_SELECTED_FILTERS]: `${NAMESPACE}/${ActionsNames.SET_SELECTED_FILTERS}`,
  [ActionsNames.REMOVE_FILTER_TAG]: `${NAMESPACE}/${ActionsNames.REMOVE_FILTER_TAG}`,
  [ActionsNames.SET_OPENED_PRODUCT_INFO_MODAL]: `${NAMESPACE}/${ActionsNames.SET_OPENED_PRODUCT_INFO_MODAL}`,
  [ActionsNames.SET_ACTIVE_FILTER_MODAL]: `${NAMESPACE}/${ActionsNames.SET_ACTIVE_FILTER_MODAL}`,
  [ActionsNames.RESET_ACTIVE_FILTER_MODAL]: `${NAMESPACE}/${ActionsNames.RESET_ACTIVE_FILTER_MODAL}`,
  [ActionsNames.SET_APPLIED_FILTER_MODAL]: `${NAMESPACE}/${ActionsNames.SET_APPLIED_FILTER_MODAL}`,
  [ActionsNames.RESET_ENDLESS_AISLE]: `${NAMESPACE}/${ActionsNames.RESET_ENDLESS_AISLE}`,
  [ActionsNames.SET_DRESSING_ROOM_INFO]: `${NAMESPACE}/${ActionsNames.SET_DRESSING_ROOM_INFO}`,
  [ActionsNames.CLOSE_ENDLESS_AISLE]: `${NAMESPACE}/${ActionsNames.CLOSE_ENDLESS_AISLE}`,
  [ActionsNames.SET_ELEMENT_TO_SELL]: `${NAMESPACE}/${ActionsNames.SET_ELEMENT_TO_SELL}`,
  [ActionsNames.SET_TEMP_DRESSING_ROOM]: `${NAMESPACE}/${ActionsNames.SET_TEMP_DRESSING_ROOM}`,
  [ActionsNames.REMOVE_TEMP_DRESSING_ROOM]: `${NAMESPACE}/${ActionsNames.REMOVE_TEMP_DRESSING_ROOM}`,
  [ActionsNames.SET_OPENED_PRODUCT_INFO_MODAL]: `${NAMESPACE}/${ActionsNames.SET_OPENED_PRODUCT_INFO_MODAL}`,
  [ActionsNames.SET_CURRENT_PRODUCT]: `${NAMESPACE}/${ActionsNames.SET_CURRENT_PRODUCT}`,
  [ActionsNames.SET_PREV_PRODUCT]: `${NAMESPACE}/${ActionsNames.SET_PREV_PRODUCT}`,
  [ActionsNames.GET_REQUEST_YMAL_SKU]: `${NAMESPACE}/${ActionsNames.GET_REQUEST_YMAL_SKU}`,
  [ActionsNames.STOCK_REQUEST]: `${NAMESPACE}/${ActionsNames.STOCK_REQUEST}`,
  [ActionsNames.SET_TEMP_PRODUCTS]: `${NAMESPACE}/${ActionsNames.SET_TEMP_PRODUCTS}`,
  [ActionsNames.SET_LEDWALL_INFO]: `${NAMESPACE}/${ActionsNames.SET_LEDWALL_INFO}`,
  [ActionsNames.RESET_LEDWALL_INFO]: `${NAMESPACE}/${ActionsNames.RESET_LEDWALL_INFO}`,
}

export const EndlessAisleMutations = {}

export const EndlessAisleGetters = {
  [GetterNames.SHOW_ENDLESS_AISLE]: `${NAMESPACE}/${GetterNames.SHOW_ENDLESS_AISLE}`,
  [GetterNames.OPENED_DRAWER_OR_MODAL]: `${NAMESPACE}/${GetterNames.OPENED_DRAWER_OR_MODAL}`,
  [GetterNames.OPENED_DRESSING_ROOM]: `${NAMESPACE}/${GetterNames.OPENED_DRESSING_ROOM}`,
  [GetterNames.GET_DRESSING_ROOM_ELEMENTS]: `${NAMESPACE}/${GetterNames.GET_DRESSING_ROOM_ELEMENTS}`,
  [GetterNames.GET_SESSION_ITEM]: `${NAMESPACE}/${GetterNames.GET_SESSION_ITEM}`,
  [GetterNames.GET_SHOW_LOADER_FULL]: `${NAMESPACE}/${GetterNames.GET_SHOW_LOADER_FULL}`,
  [GetterNames.GET_STEP_PAGE]: `${NAMESPACE}/${GetterNames.GET_STEP_PAGE}`,
  [GetterNames.GET_FILTERS]: `${NAMESPACE}/${GetterNames.GET_FILTERS}`,
  [GetterNames.GET_CATEGORIES]: `${NAMESPACE}/${GetterNames.GET_CATEGORIES}`,
  [GetterNames.OPENED_FILTERS_MODAL]: `${NAMESPACE}/${GetterNames.OPENED_FILTERS_MODAL}`,
  [GetterNames.GET_SELECTED_FILTERS]: `${NAMESPACE}/${GetterNames.GET_SELECTED_FILTERS}`,
  [GetterNames.GET_OPENED_PRODUCT_INFO_MODAL]: `${NAMESPACE}/${GetterNames.GET_OPENED_PRODUCT_INFO_MODAL}`,
  [GetterNames.GET_SEARCHED_SKU_DATA]: `${NAMESPACE}/${GetterNames.GET_SEARCHED_SKU_DATA}`,
  [GetterNames.GET_ACTIVE_FILTERS_MODAL]: `${NAMESPACE}/${GetterNames.GET_ACTIVE_FILTERS_MODAL}`,
  [GetterNames.GET_ARE_APPLIED_FILTERS]: `${NAMESPACE}/${GetterNames.GET_ARE_APPLIED_FILTERS}`,
  [GetterNames.GET_DRESSING_ROOM_INFO]: `${NAMESPACE}/${GetterNames.GET_DRESSING_ROOM_INFO}`,
  [GetterNames.GET_IS_WISHLIST_ACTIVE]: `${NAMESPACE}/${GetterNames.GET_IS_WISHLIST_ACTIVE}`,
  [GetterNames.GET_IS_IMAGE_SEARCH_ACTIVE]: `${NAMESPACE}/${GetterNames.GET_IS_IMAGE_SEARCH_ACTIVE}`,
  [GetterNames.GET_ELEMENT_TO_SELL]: `${NAMESPACE}/${GetterNames.GET_ELEMENT_TO_SELL}`,
  [GetterNames.GET_SEARCHED_SKU_DATA]: `${NAMESPACE}/${GetterNames.GET_SEARCHED_SKU_DATA}`,
  [GetterNames.GET_CURRENT_PRODUCT]: `${NAMESPACE}/${GetterNames.GET_CURRENT_PRODUCT}`,
  [GetterNames.GET_PREV_PRODUCT]: `${NAMESPACE}/${GetterNames.GET_PREV_PRODUCT}`,
  [GetterNames.GET_TEMP_PRODUCTS]: `${NAMESPACE}/${GetterNames.GET_TEMP_PRODUCTS}`,
  [GetterNames.GET_LEDWALL_INFO]: `${NAMESPACE}/${GetterNames.GET_LEDWALL_INFO}`,
}

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_mutations'
export * from './_actions'
export * from './_getters'
export * from './index.d'
export type { State as EndlessAisleState } from './_state'
export default EndlessAisleState
