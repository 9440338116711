import store from '.'
import { NotificationsActions } from './notifications-store'

export interface ConnectionState {
  isOnline: boolean
}

enum Mutations {
  SET_OFFLINE = 'setOffline',
  SET_ONLINE = 'setOnline',
}
enum Getters {
  GET_CONNECTION_IS_ONLINE = 'get-connection-is-online',
}
export default {
  namespaced: true,

  state: {
    isOnline: true,
  },

  mutations: {
    [Mutations.SET_ONLINE](state: ConnectionState) {
      // If connection status changed, show a notification
      if (!state.isOnline) {
        store.dispatch(NotificationsActions.NOTIFY_INFO, 'common.online_again')
      }

      state.isOnline = true
    },

    [Mutations.SET_OFFLINE](state: ConnectionState) {
      // If connection status changed, show a notification
      if (state.isOnline) {
        store.dispatch(
          NotificationsActions.NOTIFY_WARN,
          'common.no_connection_available'
        )
      }

      state.isOnline = false
    },
  },
  getters: {
    [Getters.GET_CONNECTION_IS_ONLINE]: (state: ConnectionState): boolean =>
      state.isOnline,
  },
}

export enum ConnectionMutations {
  SET_OFFLINE = `connection/${Mutations.SET_OFFLINE}`,
  SET_ONLINE = `connection/${Mutations.SET_ONLINE}`,
}

export const ConnectionGetters = {
  GET_CONNECTION_IS_ONLINE: `connection/${Getters.GET_CONNECTION_IS_ONLINE}`,
}
