<script lang="ts">
import { defineComponent } from 'vue'
import FeDrawerNav from '@/components/drawer/nav.vue'
import FeButton from '@/components/global/button.vue'
import FeIcon from '@/components/global/icon.vue'
import { checkLinkClick, parseHTML } from '@/addons/functions'
import { mapGetters } from 'vuex'
import { StoreStatusGetters } from '@/store/store-status-store'
import { MenuGetters } from '@/store/menu-store'

export default defineComponent({
  name: 'FeLeftDrawerNotifications',
  components: { FeIcon, FeButton, FeDrawerNav },
  emits: ['close'],
  data() {
    return {
      activeTab: 'automatic_memos',
    }
  },
  computed: {
    ...mapGetters({
      reminders: StoreStatusGetters.GET_REMINDERS,
      cec: StoreStatusGetters.GET_CEC,
      dashboard_elements: StoreStatusGetters.GET_DASHBOARD_ELEMENTS,
      reportB2c: StoreStatusGetters.GET_REPORTB2C,
      allMenuItems: MenuGetters.GET_ALL_ITEMS,
    }),
    menuItems() {
      return this.allMenuItems.flatMap((i) =>
        i.attributes?.submenu?.flatMap((j) =>
          j.attributes?.submenu?.flatMap((k) => k)
        )
      )
    },
    tabItems() {
      const tabItems: any[] = [
        {
          name: this.$t('pos_dashboard.automatic_memos'),
          anchor: 'automatic_memos',
          counter: this.reminders.length,
        },
      ]
      if (this.dashboard_elements?.cec) {
        tabItems.push({
          name: this.$t('pos_dashboard.b2e_report'),
          anchor: 'b2e_report',
          counter: this.cec.length,
        })
      }
      if (this.dashboard_elements?.b2c) {
        tabItems.push({
          name: this.$t('pos_dashboard.b2c_report'),
          anchor: 'b2c_report',
          counter: this.reportB2c.length,
        })
      }
      // TODO: Quando avremo tutte le tab attive e analizzare
      // va usato questo script che semplifica l'init delle tab
      // Object.entries(this.dashboard_elements).reduce((acc, el) => {
      //   if (el[1]) {
      //     tabItems.push({
      //       name: this.$t(`pos_dashboard.${el[0]}`),
      //       anchor: el[0],
      //       counter: this[el[0]]?.length
      //     })
      //   }
      // })
      return tabItems
    },
  },
  methods: {
    parseHTML,
    changeTab(anchor: string) {
      this.activeTab = anchor
    },
    async goTo(idMenu: string) {
      await checkLinkClick(this.menuItems.find((m) => m.id === idMenu) || {})
      this.$emit('close')
    },
  },
})
</script>

<template>
  <div class="notifications">
    <fe-drawer-nav
      mode="tab"
      class="notifications__nav"
      :nav-items="tabItems"
      @change-tab="changeTab"
    ></fe-drawer-nav>
    <div v-if="activeTab === 'automatic_memos'" class="notifications__list">
      <div
        v-for="r of reminders"
        :key="r.messaggio"
        class="notifications__list__item"
      >
        <span class="notifications__list__item--message">{{
          parseHTML(r.messaggio)
        }}</span>
        <fe-button v-if="r.action" color="icon" @click="() => goTo(r.id_menu)">
          <fe-icon icon="go"></fe-icon>
        </fe-button>
      </div>
    </div>
    <div v-if="activeTab === 'b2e_report'" class="notifications__list">
      <div
        v-for="c of cec"
        :key="c.messaggio"
        class="notifications__list__item"
      >
        <span class="notifications__list__item--message">{{
          parseHTML(c.messaggio)
        }}</span>
        <fe-button v-if="c.action" color="icon" @click="() => goTo(c.id_menu)">
          <fe-icon icon="go"></fe-icon>
        </fe-button>
      </div>
    </div>
    <div v-if="activeTab === 'b2c_report'" class="notifications__list">
      <div
        v-for="r of reportB2c"
        :key="r.messaggio"
        class="notifications__list__item"
      >
        <span class="notifications__list__item--message">{{
          parseHTML(r.messaggio)
        }}</span>
        <fe-button v-if="r.action" color="icon" @click="() => goTo(r.id_menu)">
          <fe-icon icon="go"></fe-icon>
        </fe-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/constants';

.notifications {
  padding: 0 2rem;
  &__nav {
    margin-top: 0.9rem;
    background: $color-white !important;

    a {
      width: 100%;
      text-align: center;
    }
  }

  &__list {
    margin: 2rem 1.7rem 0 2.3rem;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.6rem;
      box-shadow: 0 0.3rem 1.6rem rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      padding-left: 1rem;

      &--message {
        font-size: 1.8rem;
        margin: 1rem 0;
      }

      .fe-icon {
        font-size: 1.6rem;
        width: unset !important;
        height: unset !important;
      }
    }
  }
}
</style>
