import { MutationTree } from 'vuex'
import { initState, State } from './_state'
import { GetCashiersAttributes } from '@/api'

export enum MutationNames {
  STORE_CASHIERS = 'storeCashiers',
  RESET_STATE = 'resetState',
}

export type Mutations = {
  [MutationNames.STORE_CASHIERS](
    state: State,
    cashiers: GetCashiersAttributes[]
  ): void
  [MutationNames.RESET_STATE](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationNames.STORE_CASHIERS](state, cashiers) {
    state.cashiers = cashiers
  },
  [MutationNames.RESET_STATE](state) {
    Object.assign(state, initState())
  },
}
