import { GetterTree } from 'vuex'
import store, { RootState } from '@/store'
import { State } from './_state'
import { ConfigGetters } from '@/store/configs-store'

export enum GetterNames {
  GET_SID = 'getSID',
  GET_CURRENT_CASH = 'getCurrentCash',
  CASHES = 'getCashes',
  USER_ROLE = 'getUserRole',
  GET_IS_AUTHENTICATED = 'getIsAuthenticated',
  GET_LOGGED_USER = 'getLoggedUser',
}
export type Getters = {
  [GetterNames.GET_SID](state: State): State['sid']
  [GetterNames.GET_CURRENT_CASH](state: State): string
  [GetterNames.CASHES](state: State): State['cashes']
  [GetterNames.USER_ROLE](state: State): State['user']
  [GetterNames.GET_IS_AUTHENTICATED](state: State): State['isAuthenticated']
  [GetterNames.GET_LOGGED_USER](state: State): State['user']
}

export const getters: GetterTree<State, RootState> = {
  [GetterNames.GET_SID]: (state) => {
    return state.sid
  },
  [GetterNames.GET_CURRENT_CASH]: (state) => {
    const currentCashId = store.getters[ConfigGetters.SELECTED_CASH]

    const matchingCash = state.cashes.find(
      (cash) => cash.attributes?.cod_cassa === currentCashId
    )

    return matchingCash
  },
  [GetterNames.CASHES]: (state) => {
    return state.cashes || []
  },
  [GetterNames.USER_ROLE]: (state) => {
    return state.user?.role
  },
  [GetterNames.GET_IS_AUTHENTICATED]: (state): boolean => {
    return state.isAuthenticated
  },
  [GetterNames.GET_LOGGED_USER]: (state) => {
    return state.user
  },
}
