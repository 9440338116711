<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeAccordion',

  props: {
    title: {
      type: String,
      default: '',
    },

    cta: {
      type: String,
      default: '',
    },

    open: {
      type: Boolean,
      default: undefined,
    },

    hasIcon: {
      type: Boolean,
      default: false,
    },

    isError: {
      type: Boolean,
      default: false,
    },

    isCorrect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['toggle'],

  data() {
    return {
      isInternallyOpen: false,
    }
  },

  computed: {
    isOpen(): boolean {
      return this.open !== undefined ? this.open : this.isInternallyOpen
    },
  },

  methods: {
    toggle(): void {
      const willBeInternallyOpen = !this.isInternallyOpen
      this.isInternallyOpen = willBeInternallyOpen
      this.$emit('toggle', willBeInternallyOpen)
    },
  },
})
</script>

<template>
  <div
    class="accordion"
    :class="{ 'accordion--open': isOpen, disabled: disabled }"
  >
    <button fill="clear" :disabled="disabled" class="header" @click="toggle">
      <div
        class="field__border"
        :class="{
          error: isError,
          correct: isCorrect,
        }"
      ></div>
      <p class="title subtitle">
        {{ title }}
        <fe-icon
          v-if="hasIcon"
          :class="{ 'rotate-180': isOpen }"
          class="edit__icon"
          icon="chevron-down"
        />
      </p>
      <p class="edit">
        {{ cta }}
        <fe-icon v-if="!hasIcon" class="edit__icon" icon="chevron-down" />
      </p>
    </button>

    <div class="content">
      <slot :toggle="toggle" :is-open="isOpen" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';

.accordion {
  background: $color-white;
  border-radius: 0.8rem;
  width: 100%;
  position: relative;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: $color-white;
  padding: 2.2rem 1.6rem;
  box-shadow: 0 0.3rem 1.6rem -0.2rem rgba($color-black, 0.1);
  cursor: pointer;
  border: none;

  &.error {
    border-left: 0.8rem solid $color-error;
  }

  &.correct {
    border-left: 0.8rem solid $color-success;
  }
}

.edit {
  font-family: Switzer, sans-serif;
  margin: 0;
  display: flex;
  font-size: 1.6rem;
  line-height: 1.23em;
  font-weight: bold;
  color: $color-black;
  &:deep(ion-icon) {
    color: $color-black;
    font-size: 0;
  }

  &__icon {
    margin-left: 1.4rem;

    .accordion--open :deep(.icon-inner) {
      transform: rotate(180deg);
    }
  }
}

.content {
  max-height: 0;
  height: auto;
  overflow: hidden;

  .accordion--open & {
    max-height: 9999rem;
  }
}

.rotate-180 :deep(ion-icon) {
  transform: rotate(180deg);
}
</style>
