import { PeriodicTask, PeriodicTaskError } from '@/interfaces/periodic-task'
import store from '@/store'
import { AuthActions, AuthGetters } from '@/store/auth'
import { ConnectionMutations } from '@/store/connection-store'
import { AxiosError } from 'axios'
import { isCancel, isHttpError, systemApi } from './axios'

export class ConnectivityChecksAlreadyStartedError extends PeriodicTaskError {
  constructor(msg = 'Connectivity checks were already started') {
    super(msg)
    Object.setPrototypeOf(this, ConnectivityChecksAlreadyStartedError.prototype)
  }
}

export class ConnectivityChecksNotStartedError extends PeriodicTaskError {
  constructor(
    msg = 'Attempted to stop connectivity checks while they were not started'
  ) {
    super(msg)
    Object.setPrototypeOf(this, ConnectivityChecksNotStartedError.prototype)
  }
}

export class ConnectivityChecker extends PeriodicTask {
  protected static instance: ConnectivityChecker | undefined

  protected INTERVAL_DELAY = 30_000

  protected alreadyStartedErrorFactory = () =>
    new ConnectivityChecksAlreadyStartedError()
  protected notStartedErrorFactory = () =>
    new ConnectivityChecksNotStartedError()

  static getInstance() {
    if (!this.instance) {
      this.instance = new this()
    }

    return this.instance
  }

  protected async check() {
    try {
      const res = await systemApi.apiV1SessionsConnectionStatusGet({
        signal: this.abortController?.signal,
      })

      // XXX: it seems that sometimes, when network is not available,
      //      the data object is undefined.
      // see: https://abstractsrl.atlassian.net/browse/PW-936
      const mutation =
        res.data && res.data.data?.attributes?.global_status === '1'
          ? ConnectionMutations.SET_ONLINE
          : ConnectionMutations.SET_OFFLINE

      store.commit(mutation, undefined, { root: true })

      // Since we have a cookie and since we have used it just a few moments ago, we can assume that
      // the SID will be valid for another period equal to the value of the variable SID_DURATION.
      // So we can safely bump the expiry date by a touch.
      const sid = store?.getters[AuthGetters.GET_SID]
      if (sid) {
        await store.dispatch(AuthActions.SET_SID, sid, { root: true })
      }
    } catch (err) {
      // We can set the offline state if the error we just intercepted is not caused by a cancelled HTTP
      // request and is not caused by a valid HTTP response with a 401 status code.
      if (!isCancel(err) && !isHttpError(err, 401)) {
        store.commit(ConnectionMutations.SET_OFFLINE, undefined, { root: true })
        throw err
      }
    }
  }

  static async isReachable(urlOrIp: string): Promise<boolean> {
    try {
      await fetch(urlOrIp, { mode: 'no-cors' })
      return Promise.resolve(true)
    } catch (rawError) {
      const err = rawError as AxiosError

      if (!err || err.response?.status) {
        return Promise.resolve(true)
      }

      return Promise.resolve(false)
    }
  }
}
