import { SidebarMenuD } from './index.d'

export interface State {
  sidebarMenu: SidebarMenuD
}
export const initState = (): State => ({
  sidebarMenu: {
    top: [],
    center: [],
    bottom: [],
    optional: [],
  },
})
