import { ActionContext } from 'vuex'
import { RootState } from '@/store/index'

enum Mutations {
  STORE_GIFT_BARCODE = 'store-gift-barcode',
  STORE_ROW_GIFT_BARCODE = 'store-row-gift-barcode',
  STORE_GIFT_BARCODE_PAYMENT = 'store-gift-barcode-payment',
  STORE_ROW_GIFT_BARCODE_PAYMENT = 'store-row-gift-barcode-payment',
  STORE_OPEN_EXTRASERVICE_GIFTCARD = 'store-open-extraservice-gift',
  RESET_STATE = 'reset-state',
  GIFTCARD_PAYMENT_ONGOING = 'gift-card-payment-ongoing',
}

enum Actions {
  SET_GIFT_BARCODE = 'sales-set-gift-barcode',
  SET_ROW_GIFT_BARCODE = 'sales-set-row-gift-barcode',
  SET_GIFT_BARCODE_PAYMENT = 'sales-set-gift-barcode-payment',
  SET_ROW_GIFT_BARCODE_PAYMENT = 'sales-set-row-gift-barcode-payment',
  RESET_STATE = 'reset-state',
  SET_OPEN_EXTRASERVICE_GIFTCARD = 'sales-set-open-extraservice-gift',
}

enum Getters {
  GET_IS_GIFT_PAYMENT_ONGOING = 'sales-gift-payment-ongoing',
  GET_GIFT_BARCODE = 'sales-get-gift-barcode',
  GET_ROW_GIFT_BARCODE = 'sales-get-row-gift-barcode',
  GET_OPEN_EXTRASERVICE_GIFTCARD = 'sales-get-open-extraservice-gift',
  GET_GIFT_BARCODE_PAYMENT = 'sales-get-gift-barcode-payment',
}

export interface GiftState {
  giftBarcode: string
  rowGiftBarcode: number | null
  giftBarcodePayment: string
  rowGiftBarcodePayment: number | null
  isExtraServiceGiftcardOpened: boolean
  isGiftCardPaymentOngoing: boolean
}

const initState = (): GiftState => ({
  giftBarcode: '',
  rowGiftBarcode: null,
  giftBarcodePayment: '',
  rowGiftBarcodePayment: null,
  isExtraServiceGiftcardOpened: false,
  isGiftCardPaymentOngoing: false,
})

const giftStore = {
  namespaced: true,
  state: initState(),
  mutations: {
    [Mutations.STORE_GIFT_BARCODE]: (state: GiftState, barcode: string) => {
      state.giftBarcode = barcode
    },
    [Mutations.STORE_ROW_GIFT_BARCODE]: (state: GiftState, row: number) => {
      state.rowGiftBarcode = row
    },
    [Mutations.STORE_GIFT_BARCODE_PAYMENT]: (
      state: GiftState,
      barcode: string
    ) => {
      state.giftBarcodePayment = barcode
    },
    [Mutations.STORE_ROW_GIFT_BARCODE_PAYMENT]: (
      state: GiftState,
      row: number
    ) => {
      state.rowGiftBarcodePayment = row
    },
    [Mutations.RESET_STATE]: (state: GiftState) => {
      Object.assign(state, initState())
    },
    [Mutations.STORE_OPEN_EXTRASERVICE_GIFTCARD]: (
      state: GiftState,
      value: boolean
    ) => {
      state.isExtraServiceGiftcardOpened = value
    },
    [Mutations.GIFTCARD_PAYMENT_ONGOING]: (
      state: GiftState,
      value: boolean
    ) => {
      state.isGiftCardPaymentOngoing = value
    },
  },
  actions: {
    [Actions.RESET_STATE]: (context: ActionContext<GiftState, RootState>) => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.SET_GIFT_BARCODE]: (
      context: ActionContext<GiftState, RootState>,
      barcode: string
    ) => {
      context.commit(Mutations.STORE_GIFT_BARCODE, barcode)
    },
    [Actions.SET_ROW_GIFT_BARCODE]: (
      context: ActionContext<GiftState, RootState>,
      row: number
    ) => {
      context.commit(Mutations.STORE_ROW_GIFT_BARCODE, row)
    },
    [Actions.SET_GIFT_BARCODE_PAYMENT]: (
      context: ActionContext<GiftState, RootState>,
      barcode: string
    ) => {
      context.commit(Mutations.STORE_GIFT_BARCODE_PAYMENT, barcode)
    },
    [Actions.SET_ROW_GIFT_BARCODE_PAYMENT]: (
      context: ActionContext<GiftState, RootState>,
      row: number
    ) => {
      context.commit(Mutations.STORE_ROW_GIFT_BARCODE_PAYMENT, row)
    },
    [Actions.SET_OPEN_EXTRASERVICE_GIFTCARD]: (
      context: ActionContext<GiftState, RootState>,
      value: boolean
    ) => {
      context.commit(Mutations.STORE_OPEN_EXTRASERVICE_GIFTCARD, value)
    },
  },
  getters: {
    [Getters.GET_GIFT_BARCODE]: (state: GiftState): string => {
      return state.giftBarcode
    },
    [Getters.GET_ROW_GIFT_BARCODE]: (state: GiftState): number | null => {
      return state.rowGiftBarcode
    },
    [Getters.GET_OPEN_EXTRASERVICE_GIFTCARD]: (state: GiftState): boolean => {
      return state.isExtraServiceGiftcardOpened
    },
    [Getters.GET_GIFT_BARCODE_PAYMENT]: (state: GiftState): number | null => {
      return state.rowGiftBarcodePayment
    },
    [Getters.GET_IS_GIFT_PAYMENT_ONGOING]: (state: GiftState): boolean => {
      return state.isGiftCardPaymentOngoing
    },
  },
}

export default giftStore
export const GiftActions = {
  SET_GIFT_BARCODE: `gift/${Actions.SET_GIFT_BARCODE}`,
  SET_ROW_GIFT_BARCODE: `gift/${Actions.SET_ROW_GIFT_BARCODE}`,
  SET_GIFT_BARCODE_PAYMENT: `gift/${Actions.SET_GIFT_BARCODE_PAYMENT}`,
  SET_ROW_GIFT_BARCODE_PAYMENT: `gift/${Actions.SET_ROW_GIFT_BARCODE_PAYMENT}`,
  RESET_STATE: `gift/${Actions.RESET_STATE}`,
  SET_OPEN_EXTRASERVICE_GIFTCARD: `gift/${Actions.SET_OPEN_EXTRASERVICE_GIFTCARD}`,
}
export const GiftMutations = {
  GIFTCARD_PAYMENT_ONGOING: `gift/${Mutations.GIFTCARD_PAYMENT_ONGOING}`,
}
export const GiftGetters = {
  GET_GIFT_BARCODE: `gift/${Getters.GET_GIFT_BARCODE}`,
  GET_IS_GIFT_PAYMENT_ONGOING: `gift/${Getters.GET_IS_GIFT_PAYMENT_ONGOING}`,
  GET_ROW_GIFT_BARCODE: `gift/${Getters.GET_ROW_GIFT_BARCODE}`,
  GET_OPEN_EXTRASERVICE_GIFTCARD: `gift/${Getters.GET_OPEN_EXTRASERVICE_GIFTCARD}`,
  GET_GIFT_BARCODE_PAYMENT: `gift/${Getters.GET_GIFT_BARCODE_PAYMENT}`,
}
