import { NumberFormat } from '@intlify/core-base'

export const numberFormats: Record<string, (...args: any[]) => NumberFormat> = {
  currency: (currency: string, decimals: string) =>
    ({
      style: 'currency',
      currency,
      notation: 'standard',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    } as NumberFormat),
  decimal: (decimals: string) =>
    ({
      style: 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    } as NumberFormat),
  percent: () => ({ style: 'percent', useGrouping: false } as NumberFormat),
}

export const commonNumberFormats = {
  decimal: numberFormats.decimal(),
  percent: numberFormats.percent(),
  currency: numberFormats.currency('EUR'),
}
