import { images } from '@/configs'
import { GetSkuDataAttributes } from '@/api'

export default function getImageUrl(
  product: GetSkuDataAttributes,
  type: 'model' | 'color'
): string {
  let path = ''

  if (type === 'model') {
    path = product?.model_img || product?.media?.listing || ''
  } else {
    path = product?.color_img || ''
  }

  return getImageDetailUrl(path)
}

export function getImageDetailUrl(path: string) {
  if (path.startsWith('/') && !path.startsWith('//')) {
    return images.baseUrl + path
  }

  return path
}
