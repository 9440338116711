import {
  GetAdvancedFiltersAttributes,
  GetBrandCategoriesAttributes,
  GetModelsAttributes,
  GetSkuDataAttributes,
} from '@/api'
import {
  StepPageE,
  SelectedFiltersE,
  ActiveFilterModalE,
  DressingRoomInfoE,
  GetSessionElementD,
} from './index.d'
import { LedWallEntitiesT, LedWallEntityD } from '@/modules/fling/index.d'
export interface State {
  showEndlessAisle: boolean
  openedDrawerOrModal: boolean
  openedDressingRoom: boolean
  dressingRoomElements: Array<GetSessionElementD>
  showLoaderFull: boolean
  searchedSkuPLP: GetSkuDataAttributes
  stepPage: StepPageE
  filters: Array<GetAdvancedFiltersAttributes>
  categories: Array<GetBrandCategoriesAttributes>
  openedFiltersModal: boolean
  selectedFilters: SelectedFiltersE
  openedProductInfoModal: boolean
  activeFiltersModal: ActiveFilterModalE
  areAppliedFilters: boolean
  dressingRoomInfo: DressingRoomInfoE
  isWishlistActive: boolean
  isImageSearchActive: boolean
  elementToSell: Array<string>
  currentProduct: {
    product?: GetModelsAttributes
    variant?: string
  }
  prevProduct: GetModelsAttributes
  tempProducts: Array<GetModelsAttributes>
  ledWallInfo: {
    list: LedWallEntitiesT
    selectedLed: LedWallEntityD
  }
}
export const initState = (): State => ({
  showEndlessAisle: false,
  openedDrawerOrModal: false,
  openedDressingRoom: false,
  dressingRoomElements: [],
  showLoaderFull: false,
  searchedSkuPLP: {},
  stepPage: StepPageE.INTRO,
  filters: [],
  categories: [],
  openedFiltersModal: false,
  selectedFilters: {},
  openedProductInfoModal: false,
  activeFiltersModal: {},
  areAppliedFilters: false,
  dressingRoomInfo: {},
  isWishlistActive: false,
  isImageSearchActive: false,
  elementToSell: [],
  currentProduct: {},
  prevProduct: {},
  tempProducts: [],
  ledWallInfo: {
    list: [],
    selectedLed: {},
  },
})
