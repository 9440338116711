/* tslint:disable */
/* eslint-disable */
/**
 * OM Stock to Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorErrors
 */
export interface ErrorErrors {
    /**
     * 
     * @type {string}
     * @memberof ErrorErrors
     */
    'detail'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {Array<ErrorErrors>}
     * @memberof ModelError
     */
    'errors'?: Array<ErrorErrors>;
}
/**
 * 
 * @export
 * @interface SecondFactorAuth
 */
export interface SecondFactorAuth {
    /**
     * 
     * @type {string}
     * @memberof SecondFactorAuth
     */
    'force_login_sms'?: string;
}
/**
 * 
 * @export
 * @interface SuccessfulAuthentication
 */
export interface SuccessfulAuthentication {
    /**
     * 
     * @type {string}
     * @memberof SuccessfulAuthentication
     */
    'status': SuccessfulAuthenticationStatusEnum;
    /**
     * 
     * @type {SuccessfulAuthenticationPayload}
     * @memberof SuccessfulAuthentication
     */
    'payload': SuccessfulAuthenticationPayload;
}

/**
    * @export
    * @enum {string}
    */
export enum SuccessfulAuthenticationStatusEnum {
    Ok = 'OK'
}

/**
 * 
 * @export
 * @interface SuccessfulAuthenticationPayload
 */
export interface SuccessfulAuthenticationPayload {
    /**
     * 
     * @type {string}
     * @memberof SuccessfulAuthenticationPayload
     */
    'sid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulAuthenticationPayload
     */
    'MMFG_2fa_uuid'?: string;
}

/**
 * PortalAuthenticationApi - axios parameter creator
 * @export
 */
export const PortalAuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authenticates user
         * @summary Centralized login
         * @param {string} username 
         * @param {string} password 
         * @param {string} module 
         * @param {string} program 
         * @param {string} loginProject 
         * @param {string} returnType 
         * @param {string} [mMFG2faUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2Post: async (username: string, password: string, module: string, program: string, loginProject: string, returnType: string, mMFG2faUuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('apiV2Post', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiV2Post', 'password', password)
            // verify required parameter 'module' is not null or undefined
            assertParamExists('apiV2Post', 'module', module)
            // verify required parameter 'program' is not null or undefined
            assertParamExists('apiV2Post', 'program', program)
            // verify required parameter 'loginProject' is not null or undefined
            assertParamExists('apiV2Post', 'loginProject', loginProject)
            // verify required parameter 'returnType' is not null or undefined
            assertParamExists('apiV2Post', 'returnType', returnType)
            const localVarPath = `/api/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (module !== undefined) { 
                localVarFormParams.set('module', module as any);
            }
    
            if (program !== undefined) { 
                localVarFormParams.set('program', program as any);
            }
    
            if (loginProject !== undefined) { 
                localVarFormParams.set('login_project', loginProject as any);
            }
    
            if (returnType !== undefined) { 
                localVarFormParams.set('return_type', returnType as any);
            }
    
            if (mMFG2faUuid !== undefined) { 
                localVarFormParams.set('MMFG_2fa_uuid', mMFG2faUuid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortalAuthenticationApi - functional programming interface
 * @export
 */
export const PortalAuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortalAuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Authenticates user
         * @summary Centralized login
         * @param {string} username 
         * @param {string} password 
         * @param {string} module 
         * @param {string} program 
         * @param {string} loginProject 
         * @param {string} returnType 
         * @param {string} [mMFG2faUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2Post(username: string, password: string, module: string, program: string, loginProject: string, returnType: string, mMFG2faUuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulAuthentication | SecondFactorAuth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2Post(username, password, module, program, loginProject, returnType, mMFG2faUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortalAuthenticationApi - factory interface
 * @export
 */
export const PortalAuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortalAuthenticationApiFp(configuration)
    return {
        /**
         * Authenticates user
         * @summary Centralized login
         * @param {string} username 
         * @param {string} password 
         * @param {string} module 
         * @param {string} program 
         * @param {string} loginProject 
         * @param {string} returnType 
         * @param {string} [mMFG2faUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2Post(username: string, password: string, module: string, program: string, loginProject: string, returnType: string, mMFG2faUuid?: string, options?: any): AxiosPromise<SuccessfulAuthentication | SecondFactorAuth> {
            return localVarFp.apiV2Post(username, password, module, program, loginProject, returnType, mMFG2faUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortalAuthenticationApi - object-oriented interface
 * @export
 * @class PortalAuthenticationApi
 * @extends {BaseAPI}
 */
export class PortalAuthenticationApi extends BaseAPI {
    /**
     * Authenticates user
     * @summary Centralized login
     * @param {string} username 
     * @param {string} password 
     * @param {string} module 
     * @param {string} program 
     * @param {string} loginProject 
     * @param {string} returnType 
     * @param {string} [mMFG2faUuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalAuthenticationApi
     */
    public apiV2Post(username: string, password: string, module: string, program: string, loginProject: string, returnType: string, mMFG2faUuid?: string, options?: AxiosRequestConfig) {
        return PortalAuthenticationApiFp(this.configuration).apiV2Post(username, password, module, program, loginProject, returnType, mMFG2faUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


