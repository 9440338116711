import { returnsApi, salesApi } from '@/addons/axios'
import {
  BurnGiftCard,
  FullResoData,
  FullReturnData,
  GiftCardInformationDataAttributes,
  SaleData,
  ApiV1ReturnData1AttributesPagamentiReso,
  GetCashiersAttributes,
  ApiV1SalesDataAttributesCapi,
  ApiV1SalesDataAttributesPagamenti,
  ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributesTipoStornoEnum as TipoStornoEnum,
  ReturnBurnGiftCard1DataAttributes,
} from '@/api'
import { ActionContext } from 'vuex'
import store, { RootState } from '../index'
import { NotificationsActions } from '../notifications-store'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import { extractErrorMessage } from '@/addons/functions'
import { unformatMMFGDate } from '@/addons/date'
import i18n from '@/addons/i18n'
import { SalesActions } from '@/store/sales/sales-store'
import { PaymentsActions, PaymentsType } from '@/store/payments-store'
import { LaybyState } from '@/store/layby-store'
import { ConfigGetters } from '@/store/configs-store'
import PrintErrorHandler, {
  ErrorDisplayTypes,
} from '@/addons/functions/printErrorHandler'
import { AxiosError } from 'axios'

enum Getters {
  GET_RETURNS_LIST = 'getReturnsList',
  GET_RETURNS_FORM = 'getReturnsForm',
  GET_RETURNS_RESPONSE_DATA = 'getReturnsResponseData',
  GET_RETURNS_TOTAL = 'getReturnsTotal',
  GET_RETURNS_FIDELITY_POINTS_TOTAL = 'getReturnsFidelityPointsTotal',
  GET_SELECTED_ITEM = 'getSelectedItem',
  GET_SELECTED_INDEX = 'getSelectedIndex',
  GET_CASHIER = 'getCashier',
  GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION = 'getHandleOpenCreditCardAccordion',
  GET_INSERT_CREDIT_CARD = 'getInsertCreditCard',
  IS_LOADING_DATA = 'is-loading-data',
  GET_TRANSACTION_ID = 'getTransactionId',
  GET_CUSTOMER_LABEL = 'getConsumerLabel',
}

enum Actions {
  SET_RETURNS_LIST = 'setReturnsList',
  SET_RETURNS_FORM = 'setReturnsForm',
  SET_RETURNS_DELETE = 'setReturnsDelete',
  SET_RETURNS_BARCODE = 'setReturnsBarcode',
  RETURN_GIFT_CARD = 'returnGiftCard',
  SET_SELECTED_ITEM = 'setSelectedItem',
  SET_RETURNS = 'setReturns',
  UPDATE_RETURNS = 'updateReturns',
  UPDATE_RETURNS_DATI_DOCUMENTI = 'updateReturnsDatiDocumenti',
  DELETE_RETURNS = 'deleteReturns',
  UPDATE_SELECTED_ITEM = 'updateSelectedItem',
  UPDATE_SELECTED_ITEM_FULL_SALE = 'updateSelectedItemFullSale',
  UPDATE_SELECTED_ITEM_CAPI = 'updateSelectedItemCapi',
  UPDATE_SELECTED_ITEM_EXTRA = 'updateSelectedItemExtra',
  RESET_SELECTED_ITEM = 'resetSelectedItem',
  SET_CASHIER = 'setCashier',
  DELETE_SELECTED_RETURNS = 'deleteSelectedReturns',
  SET_TOTAL = 'setTotal',
  OPEN_CREDIT_CARD_MODAL = 'openCreditCardModal',
  CLOSE_CREDIT_CARD_MODAL = 'closeCreditCardModal',
  RESET_STATE = 'resetState',
  SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION = 'setHandleOpenCrediCardAccordion',
  RETURNS_OLD_CREDIT_CARD = 'returnOldCrediCard',
  SET_INSERT_CREDIT_CARD = 'setInsertCreditCard',
  IS_LOADING = 'isLoading',
  IS_LOADED = 'isLoaded',
  RESET_RETURN_TRANSACTION_ID = 'resetReturnTransactionID',
}

enum Mutations {
  UPDATE_RETURNS_LIST = 'updateReturnsList',
  UPDATE_RETURNS_FORM = 'updateReturnsForm',
  RESET_RETURNS_LIST = 'resetReturnsList',
  UPDATE_RESET_STATE = 'resetState',
  SET_SELECTED_ITEM = 'setSelectedItem',
  SET_SELECTED_INDEX = 'setSelectedIndex',
  UPDATE_SELECTED_ITEM = 'updateSelectedItem',
  UPDATE_SELECTED_ITEM_FULL_SALE = 'updateSelectedItemFullSale',
  UPDATE_SELECTED_ITEM_CAPI = 'updateSelectedItemCapi',
  UPDATE_SELECTED_ITEM_EXTRA = 'updateSelectedItemExtra',
  RESET_SELECTED_ITEM = 'resetSelectedItem',
  UPDATE_FULL_RESPONSE_DATA = 'updateFullResponseData',
  UPDATE_CASHIER = 'updateCashier',
  SET_TOTAL = 'setTotal',
  SET_MODAL_CREDIT_CARD_INSTANCE = 'setModalCrediCardInstance',
  SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION = 'setHandleOpenCreditCardAccordion',
  SET_INSERT_CREDIT_CARD = 'setInsertCreditCard',
  IS_LOADING = 'isLoading',
  IS_LOADED = 'isLoaded',
  SET_RETURN_TRANSACTION_ID = 'setReturnTransactionID',
  RESET_RETURN_TRANSACTION_ID = 'resetReturnTransactionID',
}

export const ReturnsGetters = {
  GET_RETURNS_LIST: `returns/${Getters.GET_RETURNS_LIST}`,
  GET_RETURNS_FORM: `returns/${Getters.GET_RETURNS_FORM}`,
  GET_RETURNS_TOTAL: `returns/${Getters.GET_RETURNS_TOTAL}`,
  GET_RETURNS_FIDELITY_POINTS_TOTAL: `returns/${Getters.GET_RETURNS_FIDELITY_POINTS_TOTAL}`,
  GET_RETURNS_RESPONSE_DATA: `returns/${Getters.GET_RETURNS_RESPONSE_DATA}`,
  GET_SELECTED_ITEM: `returns/${Getters.GET_SELECTED_ITEM}`,
  GET_SELECTED_INDEX: `returns/${Getters.GET_SELECTED_INDEX}`,
  GET_CASHIER: `returns/${Getters.GET_CASHIER}`,
  GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION: `returns/${Getters.GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION}`,
  GET_INSERT_CREDIT_CARD: `returns/${Getters.GET_INSERT_CREDIT_CARD}`,
  IS_LOADING_DATA: `returns/${Getters.IS_LOADING_DATA}`,
  GET_TRANSACTION_ID: `returns/${Getters.GET_TRANSACTION_ID}`,
  GET_CUSTOMER_LABEL: `returns/${Getters.GET_CUSTOMER_LABEL}`,
}

export const ReturnsActions = {
  SET_RETURNS_LIST: `returns/${Actions.SET_RETURNS_LIST}`,
  SET_RETURNS: `returns/${Actions.SET_RETURNS}`,
  SET_RETURNS_FORM: `returns/${Actions.SET_RETURNS_FORM}`,
  SET_RETURNS_DELETE: `returns/${Actions.SET_RETURNS_DELETE}`,
  SET_RETURNS_BARCODE: `returns/${Actions.SET_RETURNS_BARCODE}`,
  SET_SELECTED_ITEM: `returns/${Actions.SET_SELECTED_ITEM}`,
  UPDATE_SELECTED_ITEM: `returns/${Actions.UPDATE_SELECTED_ITEM}`,
  UPDATE_SELECTED_ITEM_FULL_SALE: `returns/${Actions.UPDATE_SELECTED_ITEM_FULL_SALE}`,
  UPDATE_SELECTED_ITEM_CAPI: `returns/${Actions.UPDATE_SELECTED_ITEM_CAPI}`,
  UPDATE_SELECTED_ITEM_EXTRA: `returns/${Actions.UPDATE_SELECTED_ITEM_EXTRA}`,
  RESET_SELECTED_ITEM: `returns/${Actions.RESET_SELECTED_ITEM}`,
  SET_CASHIER: `returns/${Actions.SET_CASHIER}`,
  SET_TOTAL: `returns/${Actions.SET_TOTAL}`,
  OPEN_CREDIT_CARD_MODAL: `returns/${Actions.OPEN_CREDIT_CARD_MODAL}`,
  UPDATE_RETURNS: `returns/${Actions.UPDATE_RETURNS}`,
  UPDATE_RETURNS_DATI_DOCUMENTI: `returns/${Actions.UPDATE_RETURNS_DATI_DOCUMENTI}`,
  DELETE_SELECTED_RETURNS: `returns/${Actions.DELETE_SELECTED_RETURNS}`,
  CLOSE_CREDIT_CARD_MODAL: `returns/${Actions.CLOSE_CREDIT_CARD_MODAL}`,
  RESET_STATE: `returns/${Actions.RESET_STATE}`,
  SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION: `returns/${Actions.SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION}`,
  RETURNS_OLD_CREDIT_CARD: `returns/${Actions.RETURNS_OLD_CREDIT_CARD}`,
  RETURN_GIFT_CARD: `returns/${Actions.RETURN_GIFT_CARD}`,
  SET_INSERT_CREDIT_CARD: `returns/${Actions.SET_INSERT_CREDIT_CARD}`,
  IS_LOADING: `returns/${Actions.IS_LOADING}`,
  IS_LOADED: `returns/${Actions.IS_LOADED}`,
  RESET_RETURN_TRANSACTION_ID: `returns/${Actions.RESET_RETURN_TRANSACTION_ID}`,
}

export const ReturnsMutations = {
  UPDATE_RETURNS_LIST: `returns/${Mutations.UPDATE_RETURNS_LIST}`,
  RESET_RETURNS_LIST: `returns/${Mutations.RESET_RETURNS_LIST}`,
  UPDATE_RETURNS_FORM: `returns/${Mutations.UPDATE_RETURNS_FORM}`,
  SET_TOTAL: `returns/${Mutations.SET_TOTAL}`,
}

export interface ReturnsForm {
  filterDateFrom?: string
  filterBarcode?: string
  filterDateTo?: string
  filterPkConsumer?: string
  filterSku?: string
  filterNum?: number
  filterStore?: string
  filterB2e?: '' | '1' | '0'
  filterIsAnonima?: 1 | 0
}

type modalCreditCardInstanceType = {
  confirm(): void
  close(): void
}

export interface ReturnsState {
  returnsForm: ReturnsForm
  returnsList: SaleData[]
  returnsSelectedItem: SaleData & {
    attributes: {
      to_return: boolean
      importo_sospeso: number
      rendibile_per_intero: boolean
    }
  }
  returnsResponseData: FullReturnData
  returnsTotal: number
  fidelityPointsTotal: number
  cod_cassiera: GetCashiersAttributes | null
  modalCreditCardInstance: modalCreditCardInstanceType | undefined
  handleOpenCreditCard: undefined | boolean
  insertCreditCard: undefined | boolean
  isLoading: boolean
  selectedIndex: number | undefined
  returnTransactionId: number | undefined
}

const initState = (): ReturnsState => ({
  returnsForm: {},
  returnsList: [],
  returnsSelectedItem: {},
  returnsResponseData: {},
  cod_cassiera: null,
  returnsTotal: 0,
  fidelityPointsTotal: 0,
  modalCreditCardInstance: undefined,
  handleOpenCreditCard: undefined,
  insertCreditCard: false,
  isLoading: false,
  returnTransactionId: undefined,
})

const returnsStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.UPDATE_FULL_RESPONSE_DATA]: (
      state: ReturnsState,
      returnsResponseData: FullResoData
    ): void => {
      state.returnsResponseData = returnsResponseData.data?.data?.attributes
    },
    [Mutations.UPDATE_CASHIER]: (
      state: ReturnsState,
      cod_cassiera: GetCashiersAttributes | null
    ): void => {
      state.cod_cassiera = cod_cassiera
    },
    [Mutations.UPDATE_RETURNS_LIST]: (
      state: ReturnsState,
      returnsList: SaleData[]
    ): void => {
      state.returnsList = returnsList
    },
    [Mutations.RESET_RETURNS_LIST]: (state: ReturnsState): void => {
      state.returnsList = []
    },
    [Mutations.UPDATE_RETURNS_FORM]: (
      state: ReturnsState,
      returnsForm: ReturnsForm
    ): void => {
      state.returnsForm = returnsForm
    },
    [Mutations.UPDATE_RESET_STATE]: (state: ReturnsState): void => {
      Object.assign(state, initState())
    },
    [Mutations.SET_SELECTED_ITEM]: (
      state: ReturnsState,
      payload: SaleData
    ): void => {
      state.returnsSelectedItem = payload
    },
    [Mutations.SET_SELECTED_INDEX]: (
      state: ReturnsState,
      payload: number
    ): void => {
      state.selectedIndex = payload
    },
    [Mutations.UPDATE_SELECTED_ITEM]: (
      state: ReturnsState,
      payload: SaleData
    ): void => {
      state.returnsSelectedItem = payload
    },
    [Mutations.UPDATE_SELECTED_ITEM_FULL_SALE]: (
      state: ReturnsState,
      payload: boolean
    ): void => {
      state.returnsSelectedItem.attributes.pagamenti?.map((item) => {
        if (item.to_return) {
          item.to_return = false
        }
        return item
      })
      state.returnsSelectedItem.attributes.capi?.map((item) => {
        if (item.to_return) {
          item.to_return = false
        }
        return item
      })
      state.returnsSelectedItem.attributes.to_return = payload
    },
    [Mutations.UPDATE_SELECTED_ITEM_CAPI]: (
      state: ReturnsState,
      payload: {
        value: boolean
        causaleReso: string
        index: number
      }
    ): void => {
      const returnsSelectedItem = state.returnsSelectedItem
      const { capi = [] } = returnsSelectedItem.attributes
      const currentCapo = capi[payload.index] ?? {}
      if (Object.keys(currentCapo).length) {
        capi[payload.index] = Object.assign({}, currentCapo, {
          to_return: !returnsSelectedItem.attributes?.to_return
            ? payload.value
            : false,
          causale_reso: payload.causaleReso,
        })
      }
    },
    [Mutations.UPDATE_SELECTED_ITEM_EXTRA]: (
      state: ReturnsState,
      payload: {
        value: boolean
        index: number
      }
    ): void => {
      const returnsSelectedItem = state.returnsSelectedItem
      const { pagamenti = [] } = returnsSelectedItem.attributes
      const currentPagamento = pagamenti[payload.index]
      if (Object.keys(currentPagamento).length) {
        pagamenti[payload.index] = Object.assign({}, currentPagamento, {
          to_return: payload.value,
        })
      }
    },
    [Mutations.RESET_SELECTED_ITEM]: (state: ReturnsState): void => {
      state.returnsSelectedItem = Object.assign(
        {},
        initState().returnsSelectedItem
      )
      state.selectedIndex = undefined
    },
    [Mutations.SET_TOTAL]: (state: ReturnsState, payload: object): void => {
      state.returnsTotal = payload.total
      state.fidelityPointsTotal = payload.fidelityPointsTotal
    },
    [Mutations.SET_MODAL_CREDIT_CARD_INSTANCE]: (
      state: ReturnsState,
      payload: modalCreditCardInstanceType
    ): void => {
      state.modalCreditCardInstance = payload
    },
    [Mutations.SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION]: (
      state: ReturnsState,
      payload: boolean | undefined
    ): void => {
      state.handleOpenCreditCard = payload
    },
    [Mutations.SET_INSERT_CREDIT_CARD]: (
      state: ReturnsState,
      payload: boolean | undefined
    ): void => {
      state.insertCreditCard = payload
    },
    [Mutations.IS_LOADING]: (state: LaybyState): void => {
      state.isLoading = true
    },
    [Mutations.IS_LOADED]: (state: LaybyState): void => {
      state.isLoading = false
    },
    [Mutations.SET_RETURN_TRANSACTION_ID]: (
      state: ReturnsState,
      payload: number | undefined
    ): void => {
      state.returnTransactionId = payload
    },
    [Mutations.RESET_RETURN_TRANSACTION_ID]: (state: ReturnsState): void => {
      state.returnTransactionId = undefined
    },
  },
  actions: {
    [Actions.SET_RETURNS]: async (
      context: ActionContext<ReturnsState, RootState>
    ): Promise<boolean> => {
      context.commit(Mutations.SET_INSERT_CREDIT_CARD, true)
      const isOnlyCash = store.state.payments.selectedPayments.every(
        (p) => p.cod_operazione === PaymentsType.CONTANTI
      )
      try {
        const fullDataRes = await returnsApi.apiV1ReturnPost({
          data: {
            attributes: {
              ...context.state.returnsSelectedItem.attributes,
              cod_cassiera: context.state?.cod_cassiera?.venditrice,
              pagamenti_reso: [...store.state.payments.selectedPayments],
            },
          },
        })

        const returnResponseData = fullDataRes
        returnResponseData.data.data.attributes.dati_documenti =
          fullDataRes?.data?.data?.attributes?.dati_documenti
        await context.dispatch(Actions.CLOSE_CREDIT_CARD_MODAL)
        context.commit(Mutations.UPDATE_FULL_RESPONSE_DATA, fullDataRes)
        context.commit(
          Mutations.SET_RETURN_TRANSACTION_ID,
          fullDataRes.data.data?.attributes?.sale_return_data?.id_transazione
        )
        context.commit(Mutations.SET_INSERT_CREDIT_CARD, !isOnlyCash)
        return true
      } catch (error: any) {
        let errorMessage = i18n.global.t('pos_common.generic_error')
        if (error?.response?.data?.errors?.length) {
          const details = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
          if (details) {
            errorMessage += `:\n${details}`
          }
        }
        context.commit(Mutations.SET_INSERT_CREDIT_CARD, !isOnlyCash)
        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
        return false
      }
    },
    [Actions.UPDATE_RETURNS_DATI_DOCUMENTI]: (
      context: ActionContext<ReturnsState, RootState>,
      datiDocumenti: any
    ) => {
      const returnsResponseDataDatiDocumenti = JSON.parse(
        context.state.returnsResponseData?.dati_documenti
      )

      Object.entries(datiDocumenti).forEach(([key, value]) => {
        returnsResponseDataDatiDocumenti.dati_documento[key] = value
      })

      context.state.returnsResponseData.dati_documenti = JSON.stringify(
        returnsResponseDataDatiDocumenti
      )
    },
    [Actions.UPDATE_RETURNS]: async (
      context: ActionContext<ReturnsState, RootState>,
      currentPaymentMethod: Array<ApiV1ReturnData1AttributesPagamentiReso>
    ): Promise<boolean> => {
      const pagamentiReso = currentPaymentMethod
        ? [currentPaymentMethod]
        : [...store.state.payments.selectedPayments]
      const returnsSelectedItemData = JSON.parse(
        context.state.returnsSelectedItem?.attributes.dati_documenti || '{}'
      )
      const returnsResponseDataData = JSON.parse(
        context.state.returnsResponseData?.dati_documenti
      )

      const datiDocumentiReso: {
        flag_no_iva?: number
        numero_fattura?: number
        chiave_contatore?: string
        dati_documento?: string
      } = {}
      datiDocumentiReso.flag_no_iva = 0
      datiDocumentiReso.numero_fattura = returnsSelectedItemData.numero_fattura
      datiDocumentiReso.chiave_contatore =
        returnsSelectedItemData.chiave_contatore

      datiDocumentiReso.dati_documento = returnsResponseDataData.dati_documento

      try {
        const resp = await returnsApi.apiV1ReturnPatch({
          data: {
            attributes: {
              ...context.state.returnsResponseData,
              pagamenti_reso: pagamentiReso,
              cod_documento_reso:
                context.state.returnsResponseData?.cod_documento,
              dati_documenti_reso: JSON.stringify(datiDocumentiReso),
            },
          },
        } as FullResoData)
        await PrintErrorHandler(resp.data.data, false, ErrorDisplayTypes.MODAL)
        store.dispatch(PaymentsActions.REMOVE_ALL_SELECTED_PAYMENT)
        return true
      } catch (error: any) {
        await PrintErrorHandler(
          error?.response?.data,
          false,
          ErrorDisplayTypes.TOAST
        )
        return false
      }
    },
    [Actions.DELETE_SELECTED_RETURNS]: (
      context: ActionContext<ReturnsState, RootState>
    ): void => {
      try {
        returnsApi.apiV1ReturnDelete({
          data: {
            attributes: context.state.returnsResponseData,
          },
        } as FullReturnData)
        store.dispatch(PaymentsActions.REMOVE_ALL_SELECTED_PAYMENT)
        context.dispatch(Actions.RESET_SELECTED_ITEM)
        context.dispatch(Actions.RESET_RETURN_TRANSACTION_ID)
      } catch (error: any) {
        let errorMessage = i18n.global.t('pos_common.generic_error')
        if (error?.response?.data?.errors?.length) {
          const details = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
          if (details) {
            errorMessage += `:\n${details}`
          }
        }
        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
      }
    },
    [Actions.SET_CASHIER]: (
      context: ActionContext<ReturnsState, RootState>,
      cashier: string
    ): void => {
      context.commit(Mutations.UPDATE_CASHIER, cashier)
    },
    [Actions.DELETE_RETURNS]: async (
      context: ActionContext<ReturnsState, RootState>
    ): Promise<void> => {
      await returnsApi.apiV1ReturnDelete(
        context.state.returnsResponseData as FullReturnData
      )
    },
    [Actions.SET_RETURNS_FORM]: (
      context: ActionContext<ReturnsState, RootState>,
      returnsForm?: ReturnsForm
    ): void => {
      context.commit(Mutations.UPDATE_RETURNS_FORM, returnsForm)
    },
    [Actions.RETURN_GIFT_CARD]: async (
      context: ActionContext<ReturnsState, RootState>,
      payload: {
        giftCard: GiftCardInformationDataAttributes
        amount: number
        progressivo: number
      }
    ): Promise<boolean> => {
      const { returnTransactionId } = context.state
      if (!returnTransactionId) {
        await context.dispatch(Actions.SET_RETURNS)
      }
      const idTransazione =
        store.getters[ReturnsGetters.GET_TRANSACTION_ID] || 0
      const saleId = `${
        context.rootState.configs.currentStore?.STORE_CODE
      },${String(idTransazione)}`
      const selectedPayments = store.state.payments.selectedPayments?.filter(
        (p) => p.cod_operazione !== PaymentsType.GIFT_CARD_CORPORATE
      )
      const burnGiftCard: BurnGiftCard = {
        data: {
          type: 'burn_gift_card',
          id: saleId,
          attributes: {
            ...context.state.returnsSelectedItem.attributes,
            pagamenti_reso: selectedPayments,
            importo: payload.amount,
            barcode: payload.giftCard.CardCode,
            row_num: 1,
          },
        },
      }
      try {
        const response =
          await salesApi.apiV1ReturnsSaleIdPaymentsGiftCardGiftCodePost(
            saleId,
            payload.giftCard.CardCode || '',
            burnGiftCard
          )
        if (response.data.data) {
          const esito = response.data.data
            ?.attributes as ReturnBurnGiftCard1DataAttributes & {
            message?: string
            Status?: string
          }
          store.dispatch(PaymentsActions.SET_DATI_OPERAZIONE, {
            progressivo: payload.progressivo,
            dati_operazione: esito.card_data,
          })
          store.dispatch(SalesActions.UPDATE_PAYMENT_VALIDATION, {
            progressivo: payload.progressivo,
            value: true,
          })
          // TODO: aggiungere dati_operazione al payload della patch

          if (esito?.Status === 'ERROR') {
            context.dispatch(
              NotificationsActions.NOTIFY_ERROR,
              esito?.message,
              { root: true }
            )
            return false
          }
          return true
        }
      } catch (error) {
        let errorMessage = i18n.global.t('pos_common.generic_error')

        if (error?.response?.data?.errors?.length) {
          const details = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
          if (details) {
            errorMessage += `:\n${details}`
          }
        }
        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
        return false
      }
      return false
    },
    [Actions.SET_RETURNS_BARCODE]: async (
      context: ActionContext<ReturnsState, RootState>,
      barcode: string
    ): Promise<void> => {
      try {
        if (barcode) {
          const response = await returnsApi.apiV1ReturnBarcodeBarcodeGet(
            barcode
          )
          const data_documento =
            response.data.data?.attributes?.data_documento ?? ''
          const format_data_documento: string = i18n.global.d(
            unformatMMFGDate(data_documento),
            'date'
          )
          context.commit(Mutations.UPDATE_RETURNS_FORM, {
            filterBarcode: barcode,
            filterNum: response.data.data?.attributes?.numero_documento,
            filterDateTo: format_data_documento,
            filterDateFrom: format_data_documento,
            filterStore: response.data.data?.attributes?.codice_negozio,
          })
        }
      } catch (axiosError) {
        const error = axiosError as AxiosError
        let errorMessage = i18n.global.t('pos_common.generic_error')
        if (error?.response?.data?.errors?.length) {
          const details = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
          if (details) {
            errorMessage += `:\n${details}`
          }
        } else {
          errorMessage += `:\n${error.message}`
        }
        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
      }
    },
    [Actions.SET_RETURNS_LIST]: async (
      context: ActionContext<ReturnsState, RootState>
    ): Promise<void> => {
      try {
        await context.dispatch(Actions.IS_LOADING)
        const currentConsumer = context.rootState.consumers.currentConsumer
        const response = await returnsApi.apiV1ReturnGet(
          context.state.returnsForm.filterDateFrom,
          context.state.returnsForm.filterDateTo,
          currentConsumer.pk_consumer,
          context.state.returnsForm.filterSku,
          context.state.returnsForm.filterNum,
          context.state.returnsForm.filterStore,
          context.state.returnsForm.filterB2e,
          context.state.returnsForm.filterIsAnonima ? 1 : 0
        )
        if (response.data.data) {
          if (response.data.data.length === 0) {
            context.dispatch(
              NotificationsActions.NOTIFY_INFO,
              i18n.global.t('pos_sale.gb_search_status_ko'),
              {
                root: true,
              }
            )
          } else {
            context.commit(Mutations.UPDATE_RETURNS_LIST, response.data.data)
          }
        }
      } catch (error: any) {
        let errorMessage = i18n.global.t('pos_common.generic_error')

        if (error?.response?.data?.errors?.length) {
          const details = extractErrorMessage(
            error?.response?.data?.errors[0].detail || ''
          )
          if (details) {
            errorMessage += `:\n${details}`
          }
        }
        context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
      }
      await context.dispatch(Actions.IS_LOADED)
    },
    [Actions.SET_RETURNS_DELETE]: async (
      context: ActionContext<ReturnsState, RootState>
    ): Promise<void> => {
      context.commit(Mutations.UPDATE_RETURNS_FORM, {
        filterDateFrom: '',
        filterDateTo: '',
        filterBarcode: '',
        filterPkConsumer: '',
        filterSku: '',
        filterNum: '',
        filterStore: store.getters[ConfigGetters.STORE_CODE],
        filterB2e: '',
        filterIsAnonima: 0,
      })
      await store.dispatch(SalesActions.RESET_ANONYMOUS_CONSUMER)
      await context.dispatch(Actions.RESET_RETURN_TRANSACTION_ID)
    },
    [Actions.SET_SELECTED_ITEM]: async (
      context: ActionContext<ReturnsState, RootState>,
      payload: { item: SaleData; index: number }
    ): Promise<void> => {
      await store.dispatch(PaymentsActions.REMOVE_ALL_SELECTED_PAYMENT)
      await context.dispatch(Actions.RESET_SELECTED_ITEM)
      await context.dispatch(Actions.RESET_RETURN_TRANSACTION_ID)
      context.commit(
        Mutations.SET_SELECTED_ITEM,
        Object.assign({}, context.state.returnsSelectedItem, payload.item)
      )
      context.commit(Mutations.SET_SELECTED_INDEX, payload.index)
    },
    [Actions.UPDATE_SELECTED_ITEM_FULL_SALE]: async (
      context: ActionContext<ReturnsState, RootState>,
      payload: boolean
    ): Promise<void> => {
      context.commit(Mutations.UPDATE_SELECTED_ITEM_FULL_SALE, payload)
      await context.dispatch(Actions.SET_TOTAL)
    },
    [Actions.UPDATE_SELECTED_ITEM_CAPI]: async (
      context: ActionContext<ReturnsState, RootState>,
      payload: {
        value: boolean
        causaleReso: string
        index: number
      }
    ): Promise<void> => {
      context.commit(Mutations.UPDATE_SELECTED_ITEM_CAPI, payload)
      await context.dispatch(Actions.SET_TOTAL)
    },
    [Actions.UPDATE_SELECTED_ITEM_EXTRA]: async (
      context: ActionContext<ReturnsState, RootState>,
      payload: {
        value: boolean
        index: number
      }
    ): Promise<void> => {
      context.commit(Mutations.UPDATE_SELECTED_ITEM_EXTRA, payload)
      await context.dispatch(Actions.SET_TOTAL)
    },
    [Actions.RESET_SELECTED_ITEM]: async (
      context: ActionContext<ReturnsState, RootState>
    ): Promise<void> => {
      context.commit(Mutations.RESET_SELECTED_ITEM)
      await context.dispatch(Actions.SET_TOTAL)
    },
    [Actions.SET_TOTAL]: (
      context: ActionContext<ReturnsState, RootState>
    ): void => {
      const returnsSelectedItem = context.state.returnsSelectedItem

      let total = 0
      let fidelityPointsTotal = 0

      if (
        returnsSelectedItem.attributes?.importo_sospeso !== 0 ||
        returnsSelectedItem.attributes?.to_return
      ) {
        total = returnsSelectedItem.attributes?.importo_rendibile
        fidelityPointsTotal =
          returnsSelectedItem.attributes?.capi?.reduce(
            (points, item: ApiV1SalesDataAttributesCapi) => {
              if (item?.returnable && !item?.to_return && item?.punti) {
                points = points + item.punti.punti
              }
              return points
            },
            0
          ) ?? 0
      } else {
        const totalCapi =
          returnsSelectedItem.attributes.capi?.reduce(
            (acc, item: ApiV1SalesDataAttributesCapi) => {
              if (item?.returnable && item?.to_return) {
                acc = acc + item.prezzo_ricalcolato
              }
              return acc
            },
            0
          ) ?? 0
        const totalExtra =
          returnsSelectedItem.attributes.pagamenti?.reduce(
            (acc, item: ApiV1SalesDataAttributesPagamenti) => {
              if (!PaymentsType[item.cod_operazione]) {
                if (item?.returnable && item?.to_return) {
                  acc = acc + item.prezzo_ricalcolato
                }
              }
              return acc
            },
            0
          ) ?? 0
        total = totalCapi + totalExtra

        fidelityPointsTotal =
          returnsSelectedItem.attributes?.capi?.reduce(
            (points, item: ApiV1SalesDataAttributesCapi) => {
              if (item?.returnable && item?.to_return && item?.punti) {
                points = points + item.punti.punti
              }
              return points
            },
            0
          ) ?? 0
      }
      const mutationPayload = {
        total: -total,
        fidelityPointsTotal: -fidelityPointsTotal,
      }
      context.commit(Mutations.SET_TOTAL, mutationPayload)
    },
    [Actions.OPEN_CREDIT_CARD_MODAL]: (
      context: ActionContext<ReturnsState, RootState>
    ): Promise<TipoStornoEnum | undefined> => {
      return new Promise((resolve) => {
        const instance = modalController.open({
          type: ModalTypes.INFO,
          component: 'FeModalCreditCard',
          title: i18n.global.t('pos_common.attention'),
          message: i18n.global.t('pos_returns.modal_credit_card_message'),
          confirmActionButton: false,
          customFooter: true,
          customClass: 'fe-custom-modal-returns',
          customProps: {
            refundType: 'RETURNS',
          },
          customEventHandlers: {
            returnTransaction: () => resolve(TipoStornoEnum.Storno),
            creditAmount: () => resolve(TipoStornoEnum.Accredito),
          },
          closedAction: () => resolve(undefined),
        })
        context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, instance)
      })
    },
    [Actions.CLOSE_CREDIT_CARD_MODAL]: (
      context: ActionContext<ReturnsState, RootState>
    ): void => {
      if (context.state.modalCreditCardInstance) {
        context.state.modalCreditCardInstance.close()
        context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, undefined)
      }
    },
    [Actions.RESET_STATE]: (
      context: ActionContext<ReturnsState, RootState>
    ): void => {
      context.commit(Mutations.UPDATE_RESET_STATE)
      context.commit(Mutations.RESET_RETURN_TRANSACTION_ID)
    },
    [Actions.SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION]: (
      context: ActionContext<ReturnsState, RootState>,
      payload: boolean | undefined
    ): void => {
      context.commit(Mutations.SET_HANDLE_OPEN_CREDIT_CARD_ACCORDION, payload)
    },
    [Actions.SET_INSERT_CREDIT_CARD]: (
      context: ActionContext<ReturnsState, RootState>,
      payload: boolean | undefined
    ): void => {
      context.commit(Mutations.SET_INSERT_CREDIT_CARD, payload)
    },
    [Actions.IS_LOADING]: (
      context: ActionContext<LaybyState, RootState>
    ): void => {
      context.commit(Mutations.IS_LOADING)
    },
    [Actions.IS_LOADED]: (
      context: ActionContext<LaybyState, RootState>
    ): void => {
      context.commit(Mutations.IS_LOADED)
    },
    [Actions.RESET_RETURN_TRANSACTION_ID]: (
      context: ActionContext<LaybyState, RootState>
    ): void => {
      context.commit(Mutations.RESET_RETURN_TRANSACTION_ID)
    },
  },
  getters: {
    [Getters.GET_RETURNS_FORM]: (state: ReturnsState): ReturnsForm =>
      state.returnsForm,
    [Getters.GET_RETURNS_LIST]: (state: ReturnsState): SaleData[] =>
      state.returnsList,
    [Getters.GET_RETURNS_TOTAL]: (state: ReturnsState): number =>
      state.returnsTotal,
    [Getters.GET_RETURNS_FIDELITY_POINTS_TOTAL]: (
      state: ReturnsState
    ): number => state.fidelityPointsTotal,
    [Getters.GET_SELECTED_ITEM]: (state: ReturnsState): SaleData =>
      state.returnsSelectedItem,
    [Getters.GET_SELECTED_INDEX]: (state: ReturnsState): number | undefined =>
      state.selectedIndex,
    [Getters.GET_RETURNS_RESPONSE_DATA]: (
      state: ReturnsState
    ): FullReturnData => state.returnsResponseData,
    [Getters.GET_CASHIER]: (state: ReturnsState): any => state.cod_cassiera,
    [Getters.GET_HANDLE_OPEN_CREDIT_CARD_ACCORDION]: (
      state: ReturnsState
    ): boolean | undefined => state.handleOpenCreditCard,
    [Getters.GET_INSERT_CREDIT_CARD]: (
      state: ReturnsState
    ): boolean | undefined => state.insertCreditCard,
    [Getters.IS_LOADING_DATA]: (state: ReturnsState): boolean =>
      state.isLoading,
    [Getters.GET_TRANSACTION_ID]: (state: ReturnsState): number | undefined =>
      state.returnTransactionId,
    [Getters.GET_CUSTOMER_LABEL]: (state: ReturnsState) => {
      const consumerAttributes = state.returnsResponseData?.consumer
      if (!consumerAttributes || !consumerAttributes?.cognome) {
        return
      }
      return `${consumerAttributes?.cognome} ${consumerAttributes?.nome || ''}`
    },
  },
}

export default returnsStore
