import { MutationTree } from 'vuex'
import { State, initState } from './_state'
import {
  ConsumerCardData,
  GetConsumerAffiliationsData,
  GetPoswebPromotionTypesAttributes,
  SaleDataAttributes,
} from '@/api'
import { CustomStepsD, PointsBalanceData } from './index.d'

export enum MutationsNames {
  RESET_STATE = 'reset-loyalty-store',
  SET_LOYALTY_CARDS = 'set-loyalty-cards',
  UPDATE_LOYALTY_ERROR = 'set-loyalty-error',
  SET_PROMOTION_TYPE = 'set-promotion-type',
  SET_CUSTOM_STEPS = 'set-custom-steps',
  SET_SELECTED_DISCOUNT = 'set-selected-discount',
  RESET_DISCOUNTS = 'reset-discounts',
  SET_AFFILIATE_PROGRAMS = 'set-affiliate-programs',
  RESET_AFFILIATE_PROGRAMS = 'reset-affiliate-programs',
  UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE = 'set-points-balance',
  SET_CURRENT_PROGRAM = 'set-current-program',
  SET_ALL_PROMOTION_TYPE = 'set-all-promotion-type',
}

export type Mutations = {
  [MutationsNames.RESET_STATE](state: State): void
  [MutationsNames.SET_LOYALTY_CARDS](
    state: State,
    payload: Array<ConsumerCardData> | undefined
  ): void
  [MutationsNames.UPDATE_LOYALTY_ERROR](state: State, payload: boolean): void
  [MutationsNames.SET_SELECTED_DISCOUNT](
    state: State,
    payload: SaleDataAttributes | string | undefined
  ): void
  [MutationsNames.RESET_DISCOUNTS](state: State): void
  [MutationsNames.SET_PROMOTION_TYPE](
    state: State,
    payload: GetPoswebPromotionTypesAttributes
  ): void
  [MutationsNames.SET_AFFILIATE_PROGRAMS](
    state: State,
    payload: Array<GetConsumerAffiliationsData>
  ): void
  [MutationsNames.RESET_AFFILIATE_PROGRAMS](state: State): void
  [MutationsNames.SET_ALL_PROMOTION_TYPE](
    state: State,
    payload: Array<GetPoswebPromotionTypesAttributes>
  ): void
}
export const mutations: MutationTree<State> & Mutations = {
  [MutationsNames.RESET_STATE](state) {
    Object.assign(state, initState())
  },
  [MutationsNames.SET_LOYALTY_CARDS](
    state,
    payload: Array<ConsumerCardData> | undefined
  ) {
    if (Array.isArray(payload)) {
      state.cards = payload.map((el) => el?.attributes || {}) || []
      state.errorLoyalty = false
    } else {
      state.cards = []
      state.errorLoyalty = true
    }
  },
  [MutationsNames.SET_PROMOTION_TYPE](
    state,
    payload: GetPoswebPromotionTypesAttributes
  ) {
    state.promotionType = payload
  },
  [MutationsNames.SET_CUSTOM_STEPS](state, payload: CustomStepsD) {
    state.customSteps = payload
  },
  [MutationsNames.SET_AFFILIATE_PROGRAMS](
    state,
    payload: Array<GetConsumerAffiliationsData>
  ) {
    state.affiliatePrograms = payload
  },
  [MutationsNames.UPDATE_LOYALTY_ERROR](state, payload: boolean) {
    state.errorLoyalty = payload
  },
  [MutationsNames.RESET_AFFILIATE_PROGRAMS](state) {
    state.affiliatePrograms = []
  },
  [MutationsNames.SET_SELECTED_DISCOUNT](state, payload: SaleDataAttributes) {
    state.selectedDiscount = payload
  },
  [MutationsNames.RESET_DISCOUNTS](state) {
    state.selectedDiscount = {}
  },
  [MutationsNames.UPDATE_CURRENT_SALE_FIDELITY_POINTS_BALANCE](
    state,
    payload: PointsBalanceData
  ) {
    state.pointsBalance = payload
  },
  [MutationsNames.SET_CURRENT_PROGRAM](state: State, payload: string) {
    state.currentProgram = payload
  },
  [MutationsNames.SET_ALL_PROMOTION_TYPE](
    state: State,
    payload: Array<GetPoswebPromotionTypesAttributes>
  ) {
    state.allPromotionType = payload
  },
}
