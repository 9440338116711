import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import { SidebarMenuD } from './index.d'
export enum GetterNames {
  GET_SIDEBAR_MENU = 'get-custom-menu',
}
export type Getters = {
  [GetterNames.GET_SIDEBAR_MENU](state: State): State['sidebarMenu']
}
export const getters: GetterTree<State, RootState> = {
  [GetterNames.GET_SIDEBAR_MENU]: (state): SidebarMenuD => {
    return state.sidebarMenu || []
  },
}
