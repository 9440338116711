<script lang="ts" setup>
import { computed, defineProps, withDefaults } from 'vue'
import {
  ActionsNames,
  EndlessAisleActions,
  EndlessAisleGetters,
  GetterNames,
} from '@/modules/endless-aisle/store/index'
import FeButton from '@/components/global/button.vue'
import { LedWallEntitiesT, LedWallEntityD } from '@/modules/fling/index.d'
import store from '@/store'

const props = withDefaults(
  defineProps<{
    image: string
  }>(),
  {
    image: '',
  }
)
const listWall = computed<{
  list: LedWallEntitiesT
  selectedLed: LedWallEntityD
}>(() => store.getters[EndlessAisleGetters[GetterNames.GET_LEDWALL_INFO]])

async function select(item: LedWallEntityD): Promise<void> {
  await store.dispatch(EndlessAisleActions[ActionsNames.SET_LEDWALL_INFO], {
    value: Object.assign({}, item, { image: props.image }),
    context: 'selectedLed',
  })
}
</script>

<template>
  <div class="fling-modal-list">
    <template v-for="(item, i) in listWall.list" :key="i">
      <fe-button
        v-if="item.flingAvailable"
        class="fling-modal-list__element"
        color=""
        @click="() => select(item)"
      >
        {{ item.positionDesc }}
      </fe-button>
    </template>
  </div>
</template>

<style scoped lang="scss">
.fling-modal-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
  &__element {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
    letter-spacing: 0.03em;
    box-shadow: none;
    --background: transparent;
    min-width: 45%;
    margin-top: 1rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
  }
}
</style>
