<script setup lang="ts">
import { computed, defineProps, onMounted } from 'vue'
import { useStore } from 'vuex'
import {
  ContextPageActions,
  ContextPageGetters,
} from '@/store/context-page/context-page'
import { useRoute } from 'vue-router'
import { RootState } from '@/store'

const store = useStore<RootState>()
const route = useRoute()

const props = defineProps({
  context: {
    type: String,
    default: null,
  },

  title: {
    type: String,
    default: '',
  },
})

const getContextPage = computed(
  () => store?.getters[ContextPageGetters.GET_CONTEXT_PAGE] ?? props.context
)
const inferredContext = computed(() => getContextPage.value as string)

onMounted(() => {
  const context = (route?.meta?.context as string) || props.context
  store?.dispatch(ContextPageActions.SET_CONTEXT_PAGE, context)
})
</script>

<template>
  <header
    class="header"
    :class="{ [`header--${inferredContext?.toLowerCase()}`]: inferredContext }"
  >
    <h6 v-if="title" class="title">{{ title }}</h6>

    <span class="tabs">
      <slot />
    </span>
  </header>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  grid-row: 1;
  @include tablet-landscape {
    & {
      height: 4.8rem;

      &--stock {
        min-height: 4.8rem;
      }
    }
  }

  @include desktop {
    & {
      height: 6.4rem;
    }
  }

  &--stock {
    background: $color-stock;
  }

  &--backoffice {
    background: $color-backoffice;
  }

  &--frontoffice {
    background: $color-frontoffice;
  }
  &--custom_date {
    background: $color-custom-date;
  }

  & .tabs {
    height: 100%;
  }
}

.title {
  width: 100%;
  margin: 0;
  padding: 0 4rem;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  color: $color-white;
}
</style>
