import { SessionKeys } from '@/addons/enums'
import { GetStockItemDataData } from '@/api'

export interface addSetSessionItemD {
  session_id: SessionKeys
  removeAll: boolean
  pk_consumer: string
}

export interface SetSessionItemD extends addSetSessionItemD {
  sku: string
}
export interface SetSessionItemBulkD extends addSetSessionItemD {
  sku: Array<{ sku: string }>
  product: Array<GetSessionElementD>
}
export interface GetSessionElementD {
  sku: string
  quantity: number
}
export interface GetSessionItemD {
  [pk: string]: GetSessionElementD
}

export enum StepPageE {
  INTRO = 'intro',
  GUIDED_SEARCH = 'guided-search',
  CATEGORY_SEARCH = 'category-search',
  IMAGE_SEARCH = 'iamage-search',
}

export enum FilterTypeE {
  FILTER = 'filter',
  MODEL = 'model',
  CAT = 'cat',
  SUB = 'sub',
  END = 'end',
}

export enum FilterCodeE {
  COLLECTION = 'collection',
  MAIN_LINE = 'mainline',
  MODEL_B2C = 'modelB2C',
  NEW_ARRIVALS = 'newArrivals',
  ON_SALE = 'onSale',
  PRICE = 'price',
  SIZES = 'sizes',
  STOCK = 'stock',
  YEARSEASON = 'yearSeason',
  STYLE_FILTER = 'styleFilter',
}

export interface GetModelsFiltersE {
  filterSku?: string
  filterPkConsumer?: string
  filterBrand?: string
  filterCategoria?: string
  filterRighe?: number
  filterPagina?: number
  filterModel?: string
  filterModelvariant?: string
  filterSizes?: string
  filterStock?: 0 | 1
  filterName?: string
  filterEan?: string
  filterLikeMode?: 0 | 1
  filterRfidStore?: string
  filterCodCategoria?: string
  filterCodSottocategoria?: string
  filterCollection?: string
  filterMainline?: string
  filterModelB2C?: string
  filterNewArrivals?: string
  filterOnSale?: string
  filterPrice?: string
  filterYearSeason?: string
  filterStyleFilter?: string
  filterBodyLengthFilter?: string
  filterColorFilter?: string
  filterMaterialFilter?: string
  filterTypefilter?: string
  filterSleeveLengthFilter?: string
  filterMinprice?: number
  filterMaxprice?: number
  filterOccasionFilter?: string
}

export type AcceptedSelectedFiltersT = 'category' | 'subCategory' | 'filters'
export interface SelectedFiltersItemE {
  context?: string
  code?: null | string | number
  desc?: string
  filterDesc?: string
  lower?: number
  upper?: number
  value?: number | string
}
export interface SelectedFiltersE {
  category?: string
  subCategory?: string
  filters?: Array<SelectedFiltersItemE>
}

export interface SelectedFiltersPayloadE {
  context: AcceptedSelectedFiltersT
  value: string
  filters: SelectedFiltersItemE
}

export enum FilterTypeMapE {
  RANGE = 'range',
  TOGGLE = 'toggle',
  SELECT = 'select',
  MULTISELECT = 'multiSelect',
}

export interface RangeDataE {
  upper: number
  lower: number
}

export type FilterModalElementE = string | SelectedFiltersItemE | RangeDataE
export interface ActiveFilterModalE {
  [key: string]: Array<FilterModalElementE> | FilterModalElementE
}
export interface DressingRoomInfoE {
  totalQuantity?: number
  skus?: Array<GetSessionElementD>
}

export interface GetStockItemDataDataObject {
  b2e: number
  b2e_zone: number
  chain: number
  square: number
  square_manual: number
  store: number
  store_showroom: number
}

export interface StockD extends GetStockItemDataData {
  sku?: string
}
