import { ActionTree } from 'vuex'
import { TypedActionContext } from '../_types'
import { RootState } from '@/store'
import { MutationNames, Mutations } from './_mutations'
import { State } from './_state'
import { MenuPartsE, SidebarMenuItemD } from './index.d'
export enum ActionNames {
  SET_SIDEBAR_MENU = 'set-sidebar-menu',
}

type AugmentedActionContext = TypedActionContext<Mutations, State, RootState>

export interface Actions {
  [ActionNames.SET_SIDEBAR_MENU](
    state: AugmentedActionContext,
    payload: { context: MenuPartsE; item: SidebarMenuItemD }
  ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionNames.SET_SIDEBAR_MENU](context, payload): void {
    context.commit(MutationNames.SET_SIDEBAR_MENU, payload)
  },
}
