import { AbortControllerHelper } from '@/addons/axios'

export class PeriodicTaskError extends Error {}

export class PeriodicTaskAlreadyStartedError extends PeriodicTaskError {
  constructor(msg = 'Connectivity checks were already started') {
    super(msg)
    Object.setPrototypeOf(this, PeriodicTaskAlreadyStartedError.prototype)
  }
}

export class PeriodicTaskNotStartedError extends PeriodicTaskError {
  constructor(
    msg = 'Attempted to stop connectivity checks while they were not started'
  ) {
    super(msg)
    Object.setPrototypeOf(this, PeriodicTaskNotStartedError.prototype)
  }
}

export abstract class PeriodicTask {
  protected INTERVAL_DELAY = 30_000
  protected intervalId: number | undefined
  protected abortController: AbortController | undefined

  protected alreadyStartedErrorFactory: () => PeriodicTaskError = () =>
    new PeriodicTaskAlreadyStartedError()
  protected notStartedErrorFactory: () => PeriodicTaskError = () =>
    new PeriodicTaskNotStartedError()

  start() {
    if (this.intervalId && this.abortController) {
      throw this.alreadyStartedErrorFactory()
    }

    this.abortController = AbortControllerHelper.getCombinedControllers()

    // Trigger an immediate check and schedule the next one
    this.check()
    this.intervalId = window.setInterval(() => {
      this.check()
    }, this.INTERVAL_DELAY)
  }

  stop() {
    if (!this.intervalId || !this.abortController) {
      throw this.notStartedErrorFactory()
    }

    clearInterval(this.intervalId)
    this.abortController.abort()
    this.intervalId = undefined
  }

  restart() {
    this.stop()
    this.start()
  }

  protected abstract check(): Promise<void>
}
