export enum CookieKeys {
  SID = 'PoswebSession',
  DEVICE_2FA_UUID = 'DEVICE_2FA_UUID',
}

export enum SessionKeys {
  CURRENT_SALE = 'CURRENT_SALE',
  PENDING_SALES = 'PENDING_SALES',
  LOCALE = 'LOCALE',
  SELECTED_CASH = 'SELECTED_CASH',
  LAYBY_RESUME = 'LAYBY_RESUME',
  CUSTOM_DATE = 'CUSTOM_DATE',
  DRESSING_ROOM = 'DRESSING_ROOM',
  WISHLIST = 'WISHLIST',
  TEMP_DRESSING_ROOM = 'TEMP_DRESSING_ROOM',
}

export enum LocalStorageKeys {
  SELECTED_CASH = 'SELECTED_CASH',
}

export enum MobileDeviceSettingKeys {
  POSWEB_URL = 'poswebUrl',
  OM_URL = 'omUrl',
}

export enum PageContexts {
  LOGIN = 'LOGIN',
  GENERIC = 'GENERIC',
  STOCK = 'STOCK',
  BACKOFFICE = 'BACKOFFICE',
  FRONTOFFICE = 'FRONTOFFICE',
  CUSTOM_DATE = 'CUSTOM_DATE',
  DOCUMENTS = 'DOCUMENTS',
}

export enum QueryStringFlags {
  ENABLE_RFID_MOCKS = 'enableRfidMocks',
}

export enum TipiVendita {
  SCARICO_OMAGGIO = 'SCARICO_OMAGGIO',
  STORNO = 'STORNO',
  STORNO_TESSUTO = 'STORNO_TESSUTO',
  VENDITA = 'VENDITA',
  VENDITA_TESSUTO = 'VENDITA_TESSUTO',
  RESO = 'RESO',
  LAYBY = 'LAYBY',
}

export enum TipologieMerci {
  TEXTILE = 'TEXTILE',
  GARMENTS = 'GARMENTS',
}

export enum TipiMovimenti {
  CONTABILITA_ABBUONO = 'CONTABILITA_ABBUONO',
  CONTABILITA_COUPON = 'CONTABILITA_COUPON',
  CONTABILITA_PAGAMENTO = 'CONTABILITA_PAGAMENTO',
  CONTABILITA_PREPAGAMENTO = 'CONTABILITA_PREPAGAMENTO',
  CONTABILITA_PROMOZIONE = 'CONTABILITA_PROMOZIONE',
  CONTABILITA_SARTORIA = 'CONTABILITA_SARTORIA',
  CONTABILITA_SARTORIA_OMAGGIO = 'CONTABILITA_SARTORIA_OMAGGIO',
  CONTABILITA_SCONTO = 'CONTABILITA_SCONTO',
  CONTABILITA_TASSA = 'CONTABILITA_TASSA',
  CONTABILITA_COSTI_EXTRA = 'CONTABILITA_COSTI_EXTRA',
  CONTABILITA_GIFT = 'CONTABILITA_GIFT',
  CONTABILITA_SPEDIZIONE = 'CONTABILITA_SPEDIZIONE',
  CONTABILITA_IMPEGNO_PAGAMENTO = 'CONTABILITA_IMPEGNO_PAGAMENTO',
  CONTABILITA_SHOPPING_BAG = 'CONTABILITA_SHOPPING_BAG',
  VENDITA = 'VENDITA',
}

export enum TipiVersamenti {
  FATTURA = 'RESTORE_INVOICE',
  SCONTRINO = 'RESTORE_TICKET',
  RICEVUTA_FISCALE = 'RESTORE_RECEIPT',
  RIMBORSO_SPESE = 'RESTORE_REFUND',
  BOLLETTINO_POSTALE = 'RESTORE_POSTAL_BULLETIN',
  SPESE_POSTALI = 'RESTORE_POSTAL_EXPENSES',
  GIORNALI_FRANCOBOLLI = 'RESTORE_STAMPS',
  D_D_T = 'RESTORE_TRANSPORT_DOC',
  ALTRO = 'RESTORE_OTHER',
}

export enum AnonymousConsumers {
  SUPER_ANONYMOUS = '1',
}

export enum TipiListinoLabel {
  V = 'price_type_normal',
  S = 'price_type_sales',
  C = 'price_type_private_sales',
  P = 'price_type_pre_sales',
  E = 'price_type_event',
  M = 'price_type_employee',
  R = 'price_type_promotion',
}

export enum CodiceStatoVendita {
  CURRENT = 'CURRENT',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
}

export enum LanguagesIso {
  it = 'ITAL',
  en = 'INGL',
  fr = 'FRAN',
  es = 'SPAG',
  pt = 'PORT',
  de = 'TEDE',
  jp = 'JAPA',
  zh = 'CHIN',
  nl = 'OLAN',
  da = 'DANE',
  hu = 'UNGH',
}

export enum LanguagesPosweb {
  ITAL = 'it',
  INGL = 'en',
  FRAN = 'fr',
  SPAG = 'es',
  PORT = 'pt',
  TEDE = 'de',
  JAPA = 'jp',
  CHIN = 'zh',
  OLAN = 'nl',
  DANE = 'da',
  UNGH = 'hu',
}

export enum TipiDocumento {
  SALE_TICKET = 'SALE_TICKET',
  SALE_INVOICE = 'SALE_INVOICE',
  SALE_INVOICE_EU = 'SALE_INVOICE_EU',
  SALE_INVOICE_AMB = 'SALE_INVOICE_AMB',
  SALE_TAXFREE = 'SALE_TAXFREE',
}

export enum SaleStoreStatus {
  IN_STORE = 0,
  MIXED = 1,
  OUT_STORE = 2,
}

export enum CodOperazione {
  CARTA = 'CARTA',
  CONTANTI = 'CONTANTI',
}

export enum SourceType {
  B2E = 'B2E',
}

export enum LaybyCartTypeID {
  STANDARD = '001',
  ACCONTO = '002',
  REMOTE_SALE = '003',
}

export enum LaybyStatusType {
  RECAP = 'recap',
  RECAP_BASE = 'recap-base',
  RECAP_RESUME = 'recap-resume',
  RECAP_VIEW = 'recap-view',
  RECAP_VIEW_RESUME = 'recap-view-resume',
  RECAP_VIEW_RESUME_DELETE = 'recap-view-resume-delete',
  RECAP_DELETE = 'recap-delete',
}

export enum DocumentType {
  NONE = '000',
}

export enum ConsumerTypeEnum {
  GROUP = 'group',
  BRAND = 'brand',
  STORE = 'store',
}

export enum ClientType {
  COMPANY_PEOPLE = 'PG',
  INDIVIDUAL_COMPANY = 'PF',
  NON_COMMERCIAL_ENTITY = 'PE',
}

export enum CountryCodes {
  JAPAN = 'JP',
  HONG_KONG = 'HK',
  CHINA = 'CN',
  ITALY = 'IT',
}

export enum PaymentsModules {
  SUSPENDED_CREDIT = 'SUSPENDED_CREDIT',
  RETURNS = 'RETURNS',
  B2EREFUNDS = 'B2EREFUNDS',
}

export enum ToolsAdvanceConfigurationStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum ResponseStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum PaymentKeys {
  CASH = 'cash',
  CHEQUE = 'cheque',
}

export enum PettyCashStatus {
  OPEN = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum DrawerName {
  DRAWER_LAYBY_ADVANCED_PAYMENT = 'fe-drawer-layby-advanced-payments',
  DRAWER_DATA_VISIBILITY_CUSTOMER = 'fe-drawer-data-visibility-customer',
  DRAWER_DETAILS_DEPOSIT = 'fe-drawer-details-deposit',
  DRAWER_DETAILS_PETTY_CASH = 'fe-drawer-petty-cash',
  DRAWER_EDIT_ARTICLE = 'fe-drawer-edit-article',
  DRAWER_EDIT_CUSTOMER = 'fe-drawer-edit-customer',
  DRAWER_EDIT_CUSTOMER_EA = 'fe-drawer-edit-customer-ea',
  DRAWER_EXTRA_SERVICES = 'fe-drawer-extra-services',
  DRAWER_GROUP_SEARCH = 'fe-drawer-group-search',
  DRAWER_SEARCH_ITEM = 'fe-drawer-search-item',
  DRAWER_NOTA_VENDITA = 'fe-drawer-nota-vendita',
  DRAWER_PRINT_SALE = 'fe-drawer-print-sale',
  DRAWER_SAVE_PETTY_CASH = 'fe-drawer-save-petty-cash',
  DRAWER_SAVE_PETTY_CASH_V2 = 'fe-drawer-save-petty-cash-v2',
  DRAWER_SUSPENDED_SALES = 'fe-drawer-suspended-sales',
  DRAWER_UPDATE_PRICES_SELECT_PRINT = 'fe-drawer-update-prices-select-print',
  DRAWER_CASH_CLOSING_STATUS = 'fe-drawer-cash-closing-status',
  DRAWER_POST_SALE = 'fe-drawer-post-sale',
  DRAWER_STORE_STATUS = 'fe-drawer-store-status',
  DRAWER_B2E_PAYMENTS = 'fe-drawer-b2e-payments',
  DRAWER_RETURNS_PAYMENTS = 'fe-drawer-returns-payments',
  DRAWER_SUSPENDED_SALE_PAYMENTS = 'fe-drawer-suspended-sale-payments',
  DRAWER_FREE_RETURNS = 'fe-drawer-free-returns',
  DRAWER_PAYMENTS = 'fe-drawer-payments',
  DRAWER_USER = 'fe-drawer-user',
  FIDELITY_DRAWER = 'fe-fidelity-drawer',
  FIDELITY_AFFILIATE_PROGRAM_DRAWER = 'fe-fidelity-affiliate-program-drawer',
  DRAWER_HOSPITALITY = 'fe-drawer-hospitality',
  DRAWER_CRM_DOWNLOAD_FILE = 'fe-drawer-crm-download-file',
  DRAWER_PROMO_ENGINE = 'fe-drawer-promo-engine',
}

export enum StockIcon {
  EMPTY = '',
  NOLISTDATA = 'no-list-data',
  ADDEDLISTDATA = 'added-list-data',
  TRUCK = 'truck',
  PERSON = 'person',
  PIE = 'pie',
  TRIANGLE = 'triangle',
  NODATASTOCK = 'no-data-stock',
  PENDING = 'pending',
  STOCKNOTRETRIEVABLE = 'stock-not-retrievable',
  STOCKLOADING = 'stock-loading',
  ONGOINGINVENTORY = 'ongoing-inventory',
  PROMONOEDIT = 'promo-no-edit',
}

export enum StoreStatus {
  STOPPED = 'STOPPED',
  STARTED = 'STARTED',
  IDLE = 'IDLE',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
  OFF = 'OFF',
  LOADING = 'LOADING',
}

export enum DataTypeReq {
  ASYNC = 'async',
  SYNC = 'sync',
}

export enum StatusType {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum Roles {
  ASSISTENZA = 'POS:::Super',
  GERENTE = 'POS:::Gerente',
  CASSIERA = 'POS:::Cassiera',
}

export enum CashType {
  MASTER = 'MASTER',
  CASH = 'CASH',
}
export enum ConsumerTypeSearch {
  LOCAL = 'local',
  COMPANY = 'company',
}
export enum ShipDestinationType {
  CONSUMER = 'CONSUMER',
  STORE = 'STORE',
}

export enum DocumentTypeCheque {
  CHEQUE_NONE = 'CHEQUE_NONE',
  CHEQUE_IDENTITY_CARD = 'CHEQUE_IDENTITY_CARD',
  CHEQUE_DRIVER_LICENSE = 'CHEQUE_DRIVER_LICENSE',
  CHEQUE_PASSPORT = 'CHEQUE_PASSPORT',
}
