import { paymentApi, sospesiApi } from '@/addons/axios'
import { ISO8601DateTimeStandard } from '@/addons/date'
import { PaymentKeys, ResponseStatus, PageContexts } from '@/addons/enums'
import store, { RootState } from '@/store'
import {
  extractErrorMessage,
  getNewTransactionId,
  printFile,
} from '@/addons/functions'
import PrintErrorHandler, {
  ErrorDisplayTypes,
} from '@/addons/functions/printErrorHandler'
import i18n from '@/addons/i18n'
import {
  BurnGiftCard,
  GetCashiersAttributes,
  InformazioniConsumatriciTrovateConSospesiDataAttributes,
  ReturnBurnGiftCard1DataAttributes,
} from '@/api'
import { addMinutes, format } from 'date-fns'
import { ActionContext, ActionTree } from 'vuex'
import { TypedActionContext } from '../_types'
import { DrawerActions } from '../drawer-store'
import { NotificationsActions } from '../notifications-store'
import { PaymentsActions } from '../payments-store'
import { GetterNames } from './_getters'
import { MutationNames, Mutations } from './_mutations'
import { State, SuspendedCredit, BurnGiftCardType } from './_state'
import { ConfigGetters } from '@/store/configs-store'
import { SalesActions } from '../sales/sales-store'
import LoaderController from '@/components/loader/LoaderController'

export enum ActionNames {
  RESET_STATE = 'resetState',
  SEARCH_SUSPENDED_CREDITS = 'search-suspended-credits',
  SEARCH_SUSPENDED_CONSUMERS = 'search-suspended-consumers',
  UPDATE_SUSPENDED_CONSUMERS_LIST = 'update-suspended-consumers-list',
  SET_SUSPENDED_CONSUMER = 'set-suspended-consumer',
  UPDATE_SUSPENDED_CREDIT = 'update-suspended-credit',
  LOAD_ALL_SUSPENDED_CREDITS = 'load-all-suspended-credits',
  PRINT_SUSPENDED_CREDITS = 'print-suspended-credits',
  GET_ALL_PERFORMED_PAYMENTS = 'get-all-performed-payments',
  PRINT_PAYMENT = 'print-payment',
  UPDATE_NUMERO_BOLETTINA = 'update-numero-bollettina',
  UPDATE_DROPDOWN_SELECTION = 'update-dropdown-selection',
  PERFORM_PAYMENT = 'perform-payment',
  SET_CASHIER = 'set-cashier',
  PREPARE_ID_TRANSAZIONE = 'prepare-id-transazione',
  IS_LOADING = 'is-loading',
  BURN_GIFT_CARD = 'burn-gift-card',
}

type AugmentedActionContext = TypedActionContext<Mutations, State, RootState>

export interface Actions {
  [ActionNames.RESET_STATE](context: AugmentedActionContext): void

  [ActionNames.SEARCH_SUSPENDED_CREDITS](
    context: AugmentedActionContext
  ): Promise<void>

  [ActionNames.SEARCH_SUSPENDED_CONSUMERS](
    context: AugmentedActionContext,
    searchTerm: string
  ): Promise<void>

  [ActionNames.UPDATE_SUSPENDED_CONSUMERS_LIST](
    context: AugmentedActionContext,
    consumers: InformazioniConsumatriciTrovateConSospesiDataAttributes[]
  ): void

  [ActionNames.SET_SUSPENDED_CONSUMER](
    context: AugmentedActionContext,
    consumer: any
  ): void

  [ActionNames.UPDATE_SUSPENDED_CREDIT](
    context: AugmentedActionContext,
    payload: { id: number; attribute: string; value: string }
  ): void

  [ActionNames.LOAD_ALL_SUSPENDED_CREDITS](
    context: AugmentedActionContext
  ): Promise<void>

  [ActionNames.PRINT_SUSPENDED_CREDITS](
    _context: AugmentedActionContext,
    printType: string
  ): void

  [ActionNames.GET_ALL_PERFORMED_PAYMENTS](
    context: AugmentedActionContext
  ): Promise<void>

  [ActionNames.PRINT_PAYMENT](
    context: AugmentedActionContext,
    transactionId: string
  ): Promise<void>

  [ActionNames.UPDATE_NUMERO_BOLETTINA](
    context: AugmentedActionContext,
    numeroBollettina: string
  ): void

  [ActionNames.UPDATE_DROPDOWN_SELECTION](
    context: AugmentedActionContext,
    payload: {
      id: number
      dropdownType: string
      dropdownValue: string
    }
  ): void

  [ActionNames.PERFORM_PAYMENT](context: AugmentedActionContext): Promise<void>

  [ActionNames.SET_CASHIER](
    context: ActionContext<State, RootState>,
    cashier: GetCashiersAttributes
  ): void

  [ActionNames.PREPARE_ID_TRANSAZIONE](
    context: ActionContext<State, RootState>
  ): Promise<void>
  [ActionNames.BURN_GIFT_CARD](
    context: ActionContext<State, RootState>,
    payload: BurnGiftCardType
  ): Promise<boolean>
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionNames.RESET_STATE]({ commit }) {
    commit(MutationNames.RESET_STATE)
  },

  [ActionNames.SEARCH_SUSPENDED_CREDITS]: async (context) => {
    LoaderController.show({
      section: PageContexts.FRONTOFFICE,
    })

    await context.dispatch(ActionNames.IS_LOADING, true)

    try {
      const numeroBollettina = context.state.numeroBollettina
        ? `${context.state.numeroBollettina}`
        : ''
      const response = await sospesiApi.apiV1SospesiRicercaGet(
        numeroBollettina,
        context.state.consumer.pk_consumer
      )
      const { data } = response.data as any
      const error = data?.some((e: any) => !!e.attributes?.messaggio)
      if (error) {
        if (data) {
          await context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            data[0].attributes?.messaggio,
            { root: true }
          )
        }
      } else {
        const creditiSospesiAttrs = data?.filter(
          (element: SuspendedCredit) => element.type === 'sospeso_ricerca'
        ) as SuspendedCredit[]
        const footerInfosAttrs = data?.find(
          (element: SuspendedCredit) => element.type === 'sospesi-info-footer'
        ) as SuspendedCredit
        context.commit(
          MutationNames.SET_SUSPENDED_CREDITS_LIST,
          creditiSospesiAttrs
        )
        context.commit(
          MutationNames.SET_FOOTER_INFOS,
          footerInfosAttrs.attributes
        )
      }
    } catch {
      await context.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        i18n.global.t('pos_common.no_result'),
        { root: true }
      )
    } finally {
      LoaderController.hide()
    }

    await context.dispatch(ActionNames.IS_LOADING, false)
  },

  [ActionNames.SEARCH_SUSPENDED_CONSUMERS]: async (context, searchTerm) => {
    const searchQuery = { data: { query: searchTerm } }
    const response = await sospesiApi.apiV1SospesiRicercaConsumatriciPost(
      searchQuery
    )
    const consumatrici = response.data.data?.attributes ?? []
    context.commit(MutationNames.SET_SUSPENDED_CONSUMERS_LIST, consumatrici)
  },

  [ActionNames.UPDATE_SUSPENDED_CONSUMERS_LIST]: (context, consumers) => {
    context.commit(MutationNames.SET_SUSPENDED_CONSUMER, consumers)
  },

  [ActionNames.SET_SUSPENDED_CONSUMER]: (context, consumer) => {
    context.commit(MutationNames.SET_SUSPENDED_CONSUMER, consumer)
  },

  [ActionNames.UPDATE_SUSPENDED_CREDIT]: (
    context: AugmentedActionContext,
    payload: { id: number; attribute: string; value: string }
  ): void => {
    context.commit(MutationNames.UPDATE_SUSPENDED_CREDIT, payload)
  },

  [ActionNames.LOAD_ALL_SUSPENDED_CREDITS]: async (
    context: AugmentedActionContext
  ): Promise<void> => {
    const filterDataA = store.getters[ConfigGetters.GET_CUSTOM_DATE]
      ? store.getters[ConfigGetters.GET_CUSTOM_DATE]
      : undefined

    const response = await sospesiApi.apiV1SospesiListaSospesiGet(filterDataA)
    const data = response?.data.data

    if (data) {
      const matchingTotale: any = data.find(
        (attribute: any) => attribute.type === 'importo_totale_sospesi'
      )
      const matchingCredit: any = data.find(
        (attribute: any) => attribute.type === 'crediti-sospesi'
      )

      const importoTotaleSospesi = matchingTotale?.attributes
      const crediti = matchingCredit?.attributes

      const payload = {
        importo_totale: importoTotaleSospesi?.importo_totale,
        crediti,
      }
      context.commit(MutationNames.UPDATE_ALL_SUSPENDED_CREDITS_LIST, payload)
    }
  },
  [ActionNames.PRINT_SUSPENDED_CREDITS]: async (
    _context: AugmentedActionContext,
    printType: string
  ) => {
    const document = await sospesiApi.apiV1SospesiStampaGet(printType)
    if (document.data.data.attributes.pdf_content) {
      printFile(document.data.data.attributes)
    }
  },
  [ActionNames.GET_ALL_PERFORMED_PAYMENTS]: async (
    context: AugmentedActionContext
  ) => {
    const response = await sospesiApi.apiV1SospesiListaPagamentiGet()
    const allPerformedPayments = response.data.data?.attributes

    if (allPerformedPayments) {
      context.commit(
        MutationNames.UPDATE_ALL_PERFORMED_PAYMENTS,
        allPerformedPayments
      )
    }
  },
  [ActionNames.PRINT_PAYMENT]: async (
    context: ActionContext<State, RootState>,
    transactionId: string
  ) => {
    try {
      await sospesiApi.apiV1SospesiStampaPagamentoIdTransazioneGet(
        transactionId
      )
    } catch (e: any) {
      e.response?.data?.errors?.forEach(({ detail }: { detail: string }) => {
        context.dispatch(NotificationsActions.NOTIFY_ERROR, detail, {
          root: true,
        })
      })
    }
  },
  [ActionNames.UPDATE_NUMERO_BOLETTINA]: (
    context: ActionContext<State, RootState>,
    numeroBollettina: string
  ) => {
    context.commit(MutationNames.UPDATE_NUMERO_BOLLETTINA, numeroBollettina)
  },
  [ActionNames.UPDATE_DROPDOWN_SELECTION]: (
    context: ActionContext<State, RootState>,
    payload: {
      id: number
      dropdownType: string
      dropdownValue: string
    }
  ) => {
    const { id, dropdownType, dropdownValue } = payload
    const credit = context.state.suspendedCreditList?.find(
      (credit) => credit.id === id
    )
    const dataDocumentonumDocumentocodNegozio = `${credit?.attributes?.data_documento},${credit?.attributes?.numero_documento},${credit?.attributes?.cod_negozio}`

    const data = {
      id: dataDocumentonumDocumentocodNegozio,
      type: 'colonne_update',
      attributes: {
        pagamento_remoto_preferito: '',
        tipo_prodotto: '',
      },
    }
    if (data.attributes) {
      if (dropdownType === 'tipo') {
        data.attributes.tipo_prodotto = dropdownValue
      } else if (dropdownType === 'pagamento_remoto_preferito') {
        data.attributes.pagamento_remoto_preferito = dropdownValue
      }
      sospesiApi.apiV1SospesiDataDocumentonumDocumentocodNegozioPatch(
        dataDocumentonumDocumentocodNegozio,
        { data }
      )
    }
  },
  [ActionNames.PERFORM_PAYMENT]: async (
    context: ActionContext<State, RootState>
  ) => {
    const id_transazione = context.state.idTransazione
    const anagrafica_cassiera = context.state.cashier?.venditrice ?? ''
    const anagrafica_consumatrice = context.state.consumer?.pk_consumer ?? ''
    const pagamento_totale = context.getters[GetterNames.GET_TOTALS]
    const selectedPayments = context.rootState.payments.selectedPayments
    const pagamenti = selectedPayments.map((payment) => ({
      progressivo: payment.progressivo,
      tipo: payment.cod_operazione,
      valore: `${payment.importo_finale}`,
      altriDati: payment?.dati_operazione || '{}',
    }))
    const selectedSuspendedCredits = context.state.suspendedCreditList.filter(
      (credit: any) => credit.attributes.selected
    )
    const bollettine = selectedSuspendedCredits.map((credit: any) => ({
      cod_negozio: credit.attributes.cod_negozio,
      numero_documento: credit.attributes.numero_documento,
      data_documento: credit.attributes.data_documento,
      pk_consumer: credit.attributes.pk_consumer,
      tipo: credit.attributes.tipo,
      valore_codice: credit.attributes.valore_codice,
      importo: credit.attributes.importo,
      cognome: credit.attributes.cognome,
      nome: credit.attributes.nome,
      tipo_prodotto: credit.attributes.tipo_prodotto,
      pagamento_remoto_preferito: credit.attributes.pagamento_remoto_preferito,
      importo_pagato: credit.attributes.importo_ricevuto,
    }))

    const request = {
      data: {
        type: 'pagamento_sospesi',
        attributes: {
          id_transazione,
          anagrafica_cassiera,
          anagrafica_consumatrice,
          pagamento_totale,
          pagamenti,
          bollettine,
        },
      },
    }

    try {
      const response = await sospesiApi.apiV1SospesiPagamentoPost(request)

      if (response.data.data?.attributes?.status === 'SUCCESS') {
        await PrintErrorHandler(
          response.data.data,
          false,
          ErrorDisplayTypes.MODAL
        )
        await context.dispatch(DrawerActions.CLOSE, null, { root: true })
        await context.dispatch(ActionNames.RESET_STATE)
        await context.dispatch(
          PaymentsActions.RESET_SELECTED_PAYMENTS,
          undefined,
          { root: true }
        )
        await store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {})
        await store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
          paymentForm: {},
          paymentKey: PaymentKeys.CHEQUE,
        })
        await context.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {})
        await context.dispatch(
          NotificationsActions.NOTIFY_SUCCESS,
          response.data.data.attributes.message,
          { root: true }
        )
      } else if (response.data.data?.attributes?.errors?.length) {
        await context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          response.data.data?.attributes?.errors[0].message,
          { root: true }
        )
      }
    } catch (e: any) {
      if (e?.response?.data?.errors?.length) {
        await context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          e.response.data.errors[0]?.detail,
          { root: true }
        )
      }
      await context.dispatch(DrawerActions.CLOSE, null, { root: true })
      await context.dispatch(ActionNames.RESET_STATE)
    }
  },
  [ActionNames.SET_CASHIER]: (
    context: ActionContext<State, RootState>,
    cashier: GetCashiersAttributes
  ) => {
    context.commit(MutationNames.SET_CASHIER, cashier)
  },

  [ActionNames.PREPARE_ID_TRANSAZIONE]: async (
    context: ActionContext<State, RootState>
  ) => {
    context.commit(
      MutationNames.STORE_ID_TRANSAZIONE,
      await getNewTransactionId()
    )
    context.commit(
      MutationNames.STORE_CREATION_DATE,
      format(
        addMinutes(new Date(), new Date().getTimezoneOffset()),
        ISO8601DateTimeStandard
      )
    )
  },

  [ActionNames.IS_LOADING]: (
    context: ActionContext<State, RootState>,
    isLoading: boolean
  ): void => {
    context.commit(MutationNames.IS_LOADING, isLoading)
  },
  [ActionNames.BURN_GIFT_CARD]: async (
    context: ActionContext<State, RootState>,
    payload: BurnGiftCardType
  ): Promise<boolean> => {
    const idTransazione = context.state.idTransazione || 0
    const saleId = `${
      context.rootState.configs.currentStore?.STORE_CODE
    },${String(idTransazione)}`
    const burnGiftCard: BurnGiftCard = {
      data: {
        type: 'burn_gift_card',
        id: saleId,
        attributes: {
          importo: payload.amount,
          barcode: payload.giftCard.CardCode,
          is_store_credit: 0,
          id_transazione: idTransazione as number,
        },
      },
    }
    try {
      const response =
        await paymentApi.apiV1SuspendedSaleIdPaymentsGiftCardGiftCodePost(
          saleId,
          payload.giftCard.CardCode || '',
          burnGiftCard
        )
      if (response.data.data) {
        const esito = response.data.data
          ?.attributes as ReturnBurnGiftCard1DataAttributes & {
          message?: string
          Status?: string
        }
        await store.dispatch(PaymentsActions.SET_DATI_OPERAZIONE, {
          progressivo: payload.progressivo,
          dati_operazione: { dati_gift_card: esito.card_data },
        })
        await store.dispatch(SalesActions.UPDATE_PAYMENT_VALIDATION, {
          progressivo: payload.progressivo,
          value: true,
        })
        if (esito?.Status === ResponseStatus.ERROR) {
          await store.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            esito?.message,
            {
              root: true,
            }
          )
          return false
        }
        return true
      }
    } catch (error: any) {
      let errorMessage = i18n.global.t('pos_common.generic_error')

      if (error?.response?.data?.errors?.length) {
        const details = extractErrorMessage(
          error?.response?.data?.errors[0].detail || ''
        )
        if (details) {
          errorMessage += `:\n${details}`
        }
      }
      await context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
        root: true,
      })
      return false
    }
    return false
  },
}
