import { ActionContext, useStore } from 'vuex'
import { RootState } from '@/store'
import { PageContexts } from '@/addons/enums'
import { ConfigGetters } from '@/store/configs-store'

enum Mutations {
  SET_CONTEXT_PAGE = 'set-context-page',
  SET_CUSTOM_DATE = 'set-custom-date',
  RESET_CONTEXT_PAGE = 'reset-context-page',
}

enum Actions {
  SET_CONTEXT_PAGE = 'set-context-page',
  RESET_CONTEXT_PAGE = 'reset-context-page',
}

enum Getters {
  GET_CONTEXT_PAGE = 'get-context-page',
}

export interface ContextPageState {
  contextPage: string
  customDate: string | unknown
}

const initState = (): ContextPageState => ({
  contextPage: '',
  customDate: undefined,
})

const contextPageStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.SET_CONTEXT_PAGE]: (
      state: ContextPageState,
      payload: string
    ): void => {
      state.contextPage = payload
    },
    [Mutations.RESET_CONTEXT_PAGE]: (state: ContextPageState): void => {
      state.contextPage = ''
    },
  },
  actions: {
    [Actions.SET_CONTEXT_PAGE]: (
      context: ActionContext<ContextPageState, RootState>,
      payload: string
    ) => {
      const store = useStore()
      if (store.getters[ConfigGetters.GET_CUSTOM_DATE]) {
        context.commit(Mutations.SET_CONTEXT_PAGE, PageContexts.CUSTOM_DATE)
      } else if (payload) {
        context.commit(Mutations.SET_CONTEXT_PAGE, payload)
      }
    },
    [Actions.RESET_CONTEXT_PAGE]: (
      context: ActionContext<ContextPageState, RootState>
    ) => {
      context.commit(Mutations.RESET_CONTEXT_PAGE)
    },
  },
  getters: {
    [Getters.GET_CONTEXT_PAGE]: (state: ContextPageState): string =>
      state.contextPage,
  },
}

export default contextPageStore

export const ContextPageActions = {
  SET_CONTEXT_PAGE: `contextPage/${Actions.SET_CONTEXT_PAGE}`,
  RESET_CONTEXT_PAGE: `contextPage/${Actions.RESET_CONTEXT_PAGE}`,
}

export const ContextPageGetters = {
  GET_CONTEXT_PAGE: `contextPage/${Getters.GET_CONTEXT_PAGE}`,
}
