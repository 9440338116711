import {
  Module,
  mapActions as vuexMapActions,
  mapMutations as vuexMapMutations,
} from 'vuex'
import { RootState } from '..'
import { TypedMapActions, TypedMapMutations, TypedStateStore } from '../_types'
import { ActionNames, Actions, actions } from './_actions'
import { GetterNames, Getters, getters } from './_getters'
import { MutationNames, Mutations, mutations } from './_mutations'
import { State, initState } from './_state'

const authState: Module<State, RootState> = {
  namespaced: true,
  state: initState,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export const NAMESPACE = 'auth'

export type AuthStore<State> = TypedStateStore<
  typeof NAMESPACE,
  State,
  Mutations,
  Actions,
  Getters
>

export const AuthActions = {
  SET_SID: `${NAMESPACE}/${ActionNames.SET_SID}`,
  GOTOLOGIN: `${NAMESPACE}/${ActionNames.GOTOLOGIN}`,
  LOGIN: `${NAMESPACE}/${ActionNames.LOGIN}`,
  LOGOUT: `${NAMESPACE}/${ActionNames.LOGOUT}`,
  AUTHENTICATE: `${NAMESPACE}/${ActionNames.AUTHENTICATE}`,
  UPDATE_AUTH_STATUS: `${NAMESPACE}/${ActionNames.UPDATE_AUTH_STATUS}`,
  FETCH_CASHES: `${NAMESPACE}/${ActionNames.FETCH_CASHES}`,
  SELECT_CASH: `${NAMESPACE}/${ActionNames.SELECT_CASH}`,
  START_POST_LOGIN_ACTIONS: `${NAMESPACE}/${ActionNames.START_POST_LOGIN_ACTIONS}`,
  RESET_STATE: `${NAMESPACE}/${ActionNames.RESET_STATE}`,
  RESET_STORAGE: `${NAMESPACE}/${ActionNames.RESET_STORAGE}`,
} as const

export const AuthGetters = {
  GET_SID: `${NAMESPACE}/${GetterNames.GET_SID}`,
  GET_CURRENT_CASH: `${NAMESPACE}/${GetterNames.GET_CURRENT_CASH}`,
  CASHES: `${NAMESPACE}/${GetterNames.CASHES}`,
  USER_ROLE: `${NAMESPACE}/${GetterNames.USER_ROLE}`,
  GET_IS_AUTHENTICATED: `${NAMESPACE}/${GetterNames.GET_IS_AUTHENTICATED}`,
  GET_LOGGED_USER: `${NAMESPACE}/${GetterNames.GET_LOGGED_USER}`,
} as const

export const AuthMutations = {
  RESET_STATE: `${NAMESPACE}/${MutationNames.RESET_STATE}`,
} as const

export const mapActions = vuexMapActions as TypedMapActions<
  typeof NAMESPACE,
  Actions
>

export const mapMutations = vuexMapMutations as TypedMapMutations<
  typeof NAMESPACE,
  Mutations
>

export * from './_actions'
export * from './_getters'
export * from './_mutations'
export * from './_errors'
export type { AuthCallPayload, State as AuthState } from './_state'
export default authState
