import {
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  ConsumerCardDataAttributes,
  GetConsumerAffiliationsData,
  GetPoswebPromotionTypesAttributes,
} from '@/api'
import { CustomStepsD, PointsBalanceData } from './index.d'
export interface State {
  cards: Array<ConsumerCardDataAttributes>
  promotionType: GetPoswebPromotionTypesAttributes
  customSteps: CustomStepsD
  errorLoyalty: boolean
  selectedDiscount:
    | ApiV1SalesDataAttributesPagamenti
    | ApiV1SalesSaleIdDataAttributesPagamenti
  affiliatePrograms: Array<GetConsumerAffiliationsData>
  pointsBalance: PointsBalanceData
  currentProgram: string
  allPromotionType: Array<GetPoswebPromotionTypesAttributes>
}
export const initState = (): State => ({
  cards: [],
  promotionType: {},
  customSteps: {},
  errorLoyalty: false,
  selectedDiscount: {
    importo_iniziale: 0,
    importo_finale: 0,
  },
  affiliatePrograms: [],
  pointsBalance: {},
  currentProgram: '',
  allPromotionType: [],
})
