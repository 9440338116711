import { MODULES } from '@/configs'
import { isConfigTruthy } from '@/addons/functions/configs'
import { app } from '@/main'
import { GetStoreConfigDataAttributes } from '@/api'

export function initModules() {
  MODULES.forEach(
    async (item: {
      module: string
      param: keyof GetStoreConfigDataAttributes
    }) => {
      try {
        const { module, param } = item
        if (isConfigTruthy(param) || param === 'NO_PARAM') {
          const moduleImport = await import(
            /* @vite-ignore */ `@/modules/${module}/index.ts`
          )
          await moduleImport.init(app)
        }
      } catch {
        /* empty */
      }
    }
  )
}
