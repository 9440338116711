import store from '@/store'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import { NotificationsActions } from '@/store/notifications-store'
import { extractErrorMessage } from '@/addons/functions'
import i18n from '@/addons/i18n'
import { salesApi } from '@/addons/axios'
import { ApiV1SalesSaleIdData, PrintRetry, PrintRetry1Data } from '@/api'
import { AxiosPromise } from 'axios'

export enum ErrorDisplayTypes {
  MODAL = 'modal',
  TOAST = 'toast',
}

type ResolveType<T> = (
  value: PromiseLike<AxiosPromise<T>> | AxiosPromise<T>
) => void
let resolveMain: ResolveType<undefined> | (() => void)

export type GenericRequest = PrintRetry1Data &
  ApiV1SalesSaleIdData & { attributes: { id: number } }
export default function PrintErrorHandler(
  data: GenericRequest,
  retry?: boolean,
  errorDisplayType?: ErrorDisplayTypes
): Promise<AxiosPromise> {
  return new Promise((resolve) => {
    if (!retry) {
      resolveMain = resolve
    }
    // eslint-disable-next-line default-case
    switch (errorDisplayType) {
      case ErrorDisplayTypes.MODAL:
        {
          const print_error_message = data.attributes?.message
          const print_error_result = data.attributes?.print_error_result
          const prints_to_retry = data.attributes?.prints_to_retry
          if (prints_to_retry?.length || print_error_result) {
            if (prints_to_retry?.length) {
              modalController.open({
                type: ModalTypes.ERROR,
                message: print_error_result ?? '',
                title: i18n.global.t('pos_common.print_error'),
                confirmLabel: i18n.global.t('pos_common.retry'),
                confirmActionButton: true,
                noCancelButton: !retry,
                buttonAlign: !retry ? 'center' : 'left',
                textAlign: 'center',
                customClass: 'prints-errors',
                closedAction() {
                  resolveMain({} as AxiosPromise)
                },
                async confirmAction() {
                  const request: PrintRetry = createRequestObj(data)
                  const r = await salesApi.apiV1SalesPrintRetryPost(request)
                  if (r.data.data?.attributes?.status === 'ERROR') {
                    const data = r.data.data as GenericRequest
                    await PrintErrorHandler(data, true, ErrorDisplayTypes.MODAL)
                  } else {
                    resolveMain({} as AxiosPromise)
                  }
                },
              })
            } else {
              modalController.open({
                type: ModalTypes.WARNING,
                message:
                  (print_error_message ??
                    i18n.global.t('pos_sale.printer_error')) +
                  (print_error_result ? '<br>' + print_error_result : ''),
                title: i18n.global.t(
                  'pos_sale.operation_successfully_completed'
                ),
                confirmLabel: i18n.global.t('pos_common.close'),
                confirmActionButton: true,
                noCancelButton: true,
                customButtons: {
                  confirm: {
                    icons: 'close',
                  },
                },
                buttonAlign: 'center',
                textAlign: 'center',
                customClass: '',
                closedAction() {
                  resolveMain({} as AxiosPromise)
                },
                confirmAction() {
                  resolveMain({} as AxiosPromise)
                },
              })
            }
          } else {
            resolveMain({} as AxiosPromise)
          }
        }
        break
      case ErrorDisplayTypes.TOAST:
        {
          let errorMessage = i18n.global.t(
            'pos_common.following_error_occurred'
          )
          if (data?.errors?.length) {
            const details = extractErrorMessage(data?.errors[0].detail || '')
            if (details) {
              errorMessage += `\n${details}`
            }
          }

          store.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
            root: true,
          })
          resolveMain({} as AxiosPromise)
        }
        break
    }
  })
}

function createRequestObj(data: GenericRequest): PrintRetry {
  return {
    data: {
      type: data.type,
      attributes: {
        id_transazione: data.attributes?.id_transazione ?? data.attributes?.id,
        prints_to_retry: JSON.stringify(data.attributes?.prints_to_retry),
      },
    },
  }
}
