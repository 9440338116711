/* eslint-disable no-await-in-loop */

import { AbortControllerHelper, isCancel, rfidApi } from '@/addons/axios'
import { QueryStringFlags } from '@/addons/enums'
import { getPoswebOrigin } from '@/addons/functions'
import {
  isQueryStringFlagTruthy,
  isConfigTruthy,
} from '@/addons/functions/configs'
import { DatiCorrezioneStatoItemRfid, ListaDeiCapiData } from '@/api'
import store, { RootState } from '@/store'
import { ConfigGetters } from '@/store//configs-store'
import { AuthGetters } from '@/store/auth'
import { NotificationsActions } from '@/store/notifications-store'
import { SalesActions } from '@/store/sales/sales-store'
import { ActionContext } from 'vuex'
import { mockRFIDApi } from './_mock'

enum Mutations {
  UPDATE_RFID_STATUS = 'rfid-update-rfid-status',
  RESET_STATE = 'rfid-reset-state',
  IS_SEARCHING = 'rfid-set-is-searching',
}

enum Actions {
  RESET_RFID = 'rfid-reset-rfid-status',
  UPDATE_RFID_STATUS = 'update-rfid-status',
  ENABLE_RFID = 'rfid-enable',
  DISABLE_RFID = 'rfid-disable',
  DISABLE_RFID_KEEP_ALIVE = 'rfid-disable-beacon',
  OPEN_POPOVER = 'rfid-open-popover',
  CLOSE_POPOVER = 'rfid-close-popover',
  SET_SEARCHING = 'rfid-set-is-searching',
  RESET_STATE = 'rfid-reset-state',
  POLL_RFID = 'rfid-poll',
}

enum Getters {
  IS_ENABLED = 'get-rfid-is-enabled',
  POPOVER_IS_VISIBLE = 'get-popover-is-visible',
  IS_SEARCHING = 'get-is-searching',
}

export interface RfidState {
  enabled: boolean
  popoverIsVisible: boolean
  enableClientSideMocks: boolean
  is_searching: boolean
  abortController?: AbortController
}

const mapDatiCorrezioneStatoRfid = (
  products: Array<ListaDeiCapiData>
): DatiCorrezioneStatoItemRfid => {
  const ok = products
    .filter((product) => product.attributes?.stato === 'N')
    .map((product) => product.attributes?.rfid || '')
  const ko = products
    .filter((product) => product.attributes?.stato === 'E')
    .map((product) => product.attributes?.rfid || '')

  return {
    data: {
      type: 'manage_cart_items',
      attributes: {
        ok,
        ko,
      },
    },
  }
}

const initState = (): RfidState => ({
  enabled: false,
  popoverIsVisible: false,
  is_searching: false,
  abortController: undefined,
  enableClientSideMocks: isQueryStringFlagTruthy(
    QueryStringFlags.ENABLE_RFID_MOCKS
  ),
})

const rfidStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.UPDATE_RFID_STATUS]: (state: RfidState, rfid: RfidState) => {
      if (typeof rfid.enabled !== 'undefined') {
        // If we are activating the RFID antenna, make sure to instance an
        // AbortController so that we can cancel requestes if needed.
        if (rfid.enabled) {
          rfid.abortController = AbortControllerHelper.getCombinedControllers()
        } else {
          // Since we have to disable the antenna, cancel any pending polling request.
          state.abortController?.abort()
          state.abortController = undefined
        }
      }

      Object.assign(state, rfid)
    },
    [Mutations.RESET_STATE]: (state: RfidState): void => {
      Object.assign(state, initState())
    },
    [Mutations.IS_SEARCHING]: (state: RfidState, payload: boolean): void => {
      state.is_searching = payload
    },
  },
  actions: {
    [Actions.RESET_STATE]: ({
      commit,
    }: ActionContext<RfidState, RootState>) => {
      commit(Mutations.RESET_STATE)
    },
    [Actions.ENABLE_RFID]: async (
      context: ActionContext<RfidState, RootState>
    ) => {
      try {
        await context.dispatch(Actions.SET_SEARCHING, true)
        const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi
        const rfidStatus = await api.apiV1CartActionActionPut('start')
        await context.dispatch(Actions.SET_SEARCHING, false)

        context.commit(Mutations.UPDATE_RFID_STATUS, { enabled: !!rfidStatus })
        await context.dispatch(Actions.POLL_RFID)
      } catch (e: any) {
        await context.dispatch(Actions.SET_SEARCHING, false)
        if (!isCancel(e) && e.response?.data?.errors?.length) {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            e.response.data.errors[0].detail,
            { root: true }
          )
        }
      }
    },
    [Actions.POLL_RFID]: async (
      context: ActionContext<RfidState, RootState>
    ) => {
      while (context.state.enabled && context.state.abortController) {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        // Keep a reference to the API we have to use.
        const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi

        const response = await api.apiV1CartGet({
          signal: context.state.abortController.signal,
        })
        const products = response.data
        if (products?.data?.length) {
          const datiCorrezioneStatoItemRfid = mapDatiCorrezioneStatoRfid(
            products.data
          )
          const response2 = await api.apiV1CartPut(
            datiCorrezioneStatoItemRfid,
            {
              signal: context.state.abortController.signal,
            }
          )
          const filteredProducts = response2.data.data?.filter(
            (product) => product.attributes?.stato !== 'E'
          )
          if (filteredProducts) {
            for (const product of filteredProducts) {
              const dataModelPayload: ApiV1SalesSaleIdDataAttributesCapi = {
                sku: product.attributes?.sku,
              }
              if (isConfigTruthy('SKU_GRUPPO_ENABLED')) {
                dataModelPayload.rfid = product.attributes?.rfid
              }
              await context.dispatch(SalesActions.ADD_SKU, dataModelPayload, {
                root: true,
              })
            }
          }
          await context.dispatch(Actions.SET_SEARCHING, false)
        }
      }
    },
    [Actions.DISABLE_RFID]: async (
      context: ActionContext<RfidState, RootState>
    ) => {
      // Cancel any pending polling request.
      context.state.abortController?.abort()

      try {
        const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi
        const rfidDisabled = await api.apiV1CartActionActionPut('stop')

        if (rfidDisabled) {
          context.commit(Mutations.UPDATE_RFID_STATUS, { enabled: false })
        }
      } catch (e: any) {
        if (e.response?.data?.errors?.length) {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            e.response.data.errors[0].detail,
            { root: true }
          )
        }
      }
      await context.dispatch(Actions.SET_SEARCHING, false)
    },
    [Actions.DISABLE_RFID_KEEP_ALIVE]: async (
      context: ActionContext<RfidState, RootState>
    ) => {
      if (context.state.enableClientSideMocks) {
        // If we are using client side mocks, we can stop RFID as usual.
        await mockRFIDApi.apiV1CartActionActionPut('stop')
      } else {
        const sid = store.getters[AuthGetters.GET_SID]
        const origin =
          store.getters[ConfigGetters.GET_SELECTED_CASH_URL] ||
          (await getPoswebOrigin())

        // Otherwise we craft a request with the `keepalive` attribute set to `true`.
        // This ensures browser will send request even if window gets closed.
        await fetch(`${origin}/api/v1/cart/action/stop`, {
          method: 'PUT',
          keepalive: true,
          headers: {
            Authorization: `Bearer ${sid}`,
          },
        })
      }

      // Cancel any pending polling request.
      context.state.abortController?.abort()

      context.commit(Mutations.UPDATE_RFID_STATUS, { enabled: false })
      await context.dispatch(Actions.SET_SEARCHING, false)
    },
    [Actions.OPEN_POPOVER]: (context: ActionContext<RfidState, RootState>) => {
      context.commit(Mutations.UPDATE_RFID_STATUS, { popoverIsVisible: true })
    },
    [Actions.CLOSE_POPOVER]: (context: ActionContext<RfidState, RootState>) => {
      context.commit(Mutations.UPDATE_RFID_STATUS, { popoverIsVisible: false })
    },

    [Actions.UPDATE_RFID_STATUS]: (
      context: ActionContext<RfidState, RootState>,
      rfid: RfidState
    ) => {
      context.commit(Mutations.UPDATE_RFID_STATUS, rfid)
    },
    [Actions.RESET_RFID]: async (
      context: ActionContext<RfidState, RootState>
    ) => {
      const api = context.state.enableClientSideMocks ? mockRFIDApi : rfidApi
      const reset = await api.apiV1CartActionActionPut('reset')

      if (reset) {
        context.commit(Mutations.RESET_STATE)
      }
    },
    [Actions.SET_SEARCHING]: (
      context: ActionContext<RfidState, RootState>,
      payload: boolean
    ) => {
      context.commit(Mutations.IS_SEARCHING, payload)
    },
  },
  getters: {
    [Getters.IS_ENABLED]: (state: RfidState): boolean => {
      return state.enabled
    },
    [Getters.POPOVER_IS_VISIBLE]: (state: RfidState): boolean => {
      return state.popoverIsVisible
    },
    [Getters.IS_SEARCHING]: (state: RfidState): boolean => state.is_searching,
  },
}

export default rfidStore

export const RfidActions = {
  RESET_RFID: `rfid/${Actions.RESET_RFID}`,
  ENABLE_RFID: `rfid/${Actions.ENABLE_RFID}`,
  DISABLE_RFID: `rfid/${Actions.DISABLE_RFID}`,
  DISABLE_RFID_KEEP_ALIVE: `rfid/${Actions.DISABLE_RFID_KEEP_ALIVE}`,
  OPEN_POPOVER: `rfid/${Actions.OPEN_POPOVER}`,
  CLOSE_POPOVER: `rfid/${Actions.CLOSE_POPOVER}`,
  RESET_STATE: `rfid/${Actions.RESET_STATE}`,
}

export const RfidGetters = {
  IS_ENABLED: `rfid/${Getters.IS_ENABLED}`,
  POPOVER_IS_VISIBLE: `rfid/${Getters.POPOVER_IS_VISIBLE}`,
  IS_SEARCHING: `rfid/${Getters.IS_SEARCHING}`,
}
