import { PeriodicTask, PeriodicTaskError } from '@/interfaces/periodic-task'
import { omTokenApi } from './axios'

export class OMAuthHelperAlreadyStartedError extends PeriodicTaskError {
  constructor(msg = 'OM authentication helper was already started') {
    super(msg)
    Object.setPrototypeOf(this, OMAuthHelperAlreadyStartedError.prototype)
  }
}

export class OMAuthHelperNotStartedError extends PeriodicTaskError {
  constructor(
    msg = 'Attempted to stop OM authentication helper while it was not started'
  ) {
    super(msg)
    Object.setPrototypeOf(this, OMAuthHelperNotStartedError.prototype)
  }
}

export class OMAuthenticationHelper extends PeriodicTask {
  protected static instance: OMAuthenticationHelper | undefined
  protected static _token: string

  private static REFRESH_SLACK = 0.5 * 60 * 1000

  // Intervals are in ms, the "exp" claim is in minutes. Thus, we have to multiply by 100.
  // In this case, we will refresh the token each 10 minutes.
  protected INTERVAL_DELAY =
    10 * 60 * 1000 - OMAuthenticationHelper.REFRESH_SLACK

  protected alreadyStartedErrorFactory = () =>
    new OMAuthHelperAlreadyStartedError()
  protected notStartedErrorFactory = () => new OMAuthHelperNotStartedError()

  static getInstance() {
    if (!this.instance) {
      this.instance = new this()
    }

    return this.instance
  }

  static getToken() {
    return this._token
  }

  protected async check() {
    const res = await omTokenApi.apiV1OmAuthTokenGet({
      signal: this.abortController?.signal,
    })

    const { access_token: accessToken, expires_in: expiresIn } =
      res.data.data?.attributes || {}

    if (accessToken) {
      OMAuthenticationHelper._token = accessToken

      // If the expirancy of the token is not what we were expecting, we reschedule the token refresh process.
      if (expiresIn && this.shouldUpdateRefreshInterval(expiresIn)) {
        this.stop()
        this.INTERVAL_DELAY = this.getRefreshInterval(expiresIn)
        this.start()
      }
    }
  }

  private getRefreshInterval(expiresIn: number) {
    const expInMilliseconds = expiresIn * 1000
    const expWithSlack =
      expInMilliseconds - OMAuthenticationHelper.REFRESH_SLACK

    return expWithSlack
  }

  private shouldUpdateRefreshInterval(expiresIn: number) {
    const expWithSlack = this.getRefreshInterval(expiresIn)
    return expWithSlack !== this.INTERVAL_DELAY
  }
}
