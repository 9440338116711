import { format, parse } from 'date-fns'
export const ISO8601DateStandard = 'yyyy-MM-dd'
export const ISO8601DateTimeStandard = `${ISO8601DateStandard} HH:mm:ss`
export const localeFormat = 'dd/MM/yyyy'
export const localeFormatTwoDigitYear = '20yy-MM-dd'
export const pyToJSDateFormats = Object.freeze({
  '%A': 'dddd', // Weekday as locale’s full name: (In English: Sunday, .., Saturday)(Auf Deutsch: Sonntag, .., Samstag)
  '%a': 'ddd', // Weekday abbreivated: (In English: Sun, .., Sat)(Auf Deutsch: So, .., Sa)
  '%B': 'MMMM', // Month name: (In English: January, .., December)(Auf Deutsch: Januar, .., Dezember)
  '%b': 'MMM', // Month name abbreviated: (In English: Jan, .., Dec)(Auf Deutsch: Jan, .., Dez)
  '%c': 'ddd MMM DD HH:mm:ss YYYY', // Locale’s appropriate date and time representation: (English: Sun Oct 13 23:30:00 1996)(Deutsch: So 13 Oct 22:30:00 1996)
  '%d': 'dd', // Day 0 padded: (01, .., 31)
  '%f': 'SSS', // Microseconds 0 padded: (000000, .., 999999)
  '%H': 'HH', // Hour (24-Hour) 0 padded: (00, .., 23)
  '%I': 'hh', // Hour (12-Hour) 0 padded: (01, .., 12)
  '%j': 'DDDD', // Day of Year 0 padded: (001, .., 366)
  '%M': 'mm', // Minute 0 padded: (01, .. 59)
  '%m': 'MM', // Month 0 padded: (01, .., 12)
  '%p': 'A', // Locale equivalent of AM/PM: (EN: AM, PM)(DE: am, pm)
  '%S': 'ss', // Second 0 padded: (00, .., 59)
  '%U': 'ww', // Week # of Year (Sunday): (00, .., 53)  All days in a new year preceding the first Sunday are considered to be in week 0.
  '%W': 'ww', // Week # of Year (Monday): (00, .., 53)  All days in a new year preceding the first Monday are considered to be in week 0.
  '%w': 'd', // Weekday as #: (0, 6)
  '%X': 'HH:mm:ss', // Locale's appropriate time representation: (EN: 23:30:00)(DE: 23:30:00)
  '%x': 'mm/dd/yyyy', // Locale's appropriate date representation: (None: 02/14/16)(EN: 02/14/16)(DE: 14.02.16)
  '%Y': 'yyyy', // Year as #: (1970, 2000, 2038, 292,277,026,596)
  '%y': 'yy', // Year without century 0 padded: (00, .., 99)
  '%Z': 'z', // Time zone name: ((empty), UTC, EST, CST) (empty string if the object is naive).
  '%z': 'ZZ', // UTC offset in the form +HHMM or -HHMM: ((empty), +0000, -0400, +1030) Empty string if the the object is naive.
  '%%': '%', // A literal '%' character: (%)
})

export function convertPYDateFormatToJS(formatStr: string) {
  for (const key in pyToJSDateFormats) {
    formatStr = formatStr.split(key).join(pyToJSDateFormats[key])
  }
  return formatStr
}

export function formatToMMFGDate(date: Date): string {
  return format(date, 'yyyyMMdd')
}

export function formatToMMFGTime(date: Date): string {
  return format(date, 'HHmmss')
}

export function formatToMMFGTimeShort(date: Date): string {
  return format(date, 'HHmm')
}

export function unformatExtendedMMFGDateTime(
  date: string,
  _format = ISO8601DateTimeStandard
): Date {
  return unformatHelper(date, _format)
}

export function unformatMMFGDate(date: string, format = 'yyyyMMdd'): Date {
  return unformatHelper(date, format)
}

/**
 * @deprecated since version v1.3.5.02
 */
export function parseDate(formattedDate?: string): Date | null {
  if (formattedDate) {
    return new Date(formattedDate)
  }
  return null
}

/**
 * @description ideally, this should not be required. consider working with Date instances and format them only to present them to the user
 * @deprecated since version v1.3.5.02
 */
export function dateToISODay(date: Date) {
  return format(date, ISO8601DateStandard)
}
/**
 * @description given a Date object with no info about time or default time the function returns a date in iso format with current hours:minutes:seconds (e.g. Date "Mon Dec 04 2023 00:00:00 GMT+0100" returns "2023-12-04 15:03:24")
 */
export function dateToISODayWithTime(date: Date) {
  const today = new Date()
  const hours = String(today.getHours()).padStart(2, '0')
  const minutes = String(today.getMinutes()).padStart(2, '0')
  const seconds = String(today.getSeconds()).padStart(2, '0')
  return (
    format(date, ISO8601DateStandard) +
    ' ' +
    hours +
    ':' +
    minutes +
    ':' +
    seconds
  )
}

/**
 * @description ideally, this should not be required. consider working with Date instances and format them only to present them to the user
 * @deprecated since version v1.3.5.02
 */
export function fromHumanDateStringToDate(humanString: string, format: string) {
  let parsedDate = new Date()
  try {
    if (format) {
      parsedDate = parse(humanString, format, new Date())
    } else {
      // eslint-disable-next-line no-console
      console.error('Error in parsing date - format not defined')
      parsedDate = parse(humanString, 'dd/MM/yyyy', new Date())
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error in parsing date', e)
  }
  return parsedDate
}

function unformatHelper(date: string, formatString: string): Date {
  return parse(date?.replace(/-|:/g, ''), formatString, new Date())
}

export function validateDayOfMonth(str: string) {
  const dayOfMonthRegex = /^(0?[1-9]|[12]\d|3[01])$/

  if (!dayOfMonthRegex.test(str)) {
    return null
  }

  return str.padStart(2, '0')
}

export function validateMonth(str: string) {
  const monthRegex = /^(0?[1-9]|1[0-2])$/

  if (!monthRegex.test(str)) {
    return null
  }
  return str.padStart(2, '0')
}

export function validateYear(str: string) {
  const year = parseInt(str, 10)

  const currentYear = new Date().getFullYear()
  if (isNaN(year) || year < 1900 || year > currentYear) {
    return null
  }

  return str
}

export function dateToISODayTwoDigitYear(date: Date) {
  return format(date, localeFormatTwoDigitYear)
}
