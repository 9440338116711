import { depositsApi } from '@/addons/axios'
import { GetBancheAttributes } from '@/api'
import { ActionContext } from 'vuex'
import { RootState } from '.'

enum Getters {
  LIST = 'banksList',
  BY_ID = 'banksById',
}

enum Actions {
  GET_BANCHE = 'banksList',
  RESET_STATE = 'resetState',
}

export const BanksGetters = {
  LIST: `banks/${Getters.LIST}`,
  BY_ID: `banks/${Getters.BY_ID}`,
}

export const BanksActions = {
  GET_BANCHE: `banks/${Actions.GET_BANCHE}`,
  RESET_STATE: `banks/${Actions.RESET_STATE}`,
}

export interface BankState {
  banks: GetBancheAttributes[]
}

const initState = (): BankState => ({
  banks: [],
})

const bankStore = {
  namespaced: true,
  state: initState,
  mutations: {
    storeBanks: (state: BankState, banks: GetBancheAttributes[]): void => {
      state.banks = banks
    },
    resetState: (state: BankState): void => {
      Object.assign(state, initState())
    },
  },
  actions: {
    [Actions.GET_BANCHE]: async (
      context: ActionContext<BankState, RootState>,
      filterTimestamp?: string
    ): Promise<void> => {
      const filter = (c?: GetBancheAttributes) => {
        if (!c?.codice) {
          return false
        }

        return c.descrizione
      }
      const response = await depositsApi.apiV1BancheGet(filterTimestamp)
      const banks = response.data.data
        ?.filter((c) => filter(c.attributes))
        ?.map((c) => c.attributes)

      context.commit('storeBanks', banks)
    },

    [Actions.RESET_STATE]: (
      context: ActionContext<BankState, RootState>
    ): void => {
      context.commit('resetState')
    },
  },
  getters: {
    [Getters.LIST]: (state: BankState): GetBancheAttributes[] => state.banks,
    [Getters.BY_ID]:
      (codice: string) =>
      (state: BankState): GetBancheAttributes | undefined =>
        state.banks.find((c) => c.codice === codice),
  },
}

export default bankStore
